var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("b-col", { attrs: { md: "12" } }, [
        _c(
          "h1",
          {
            class: [
              `${!_vm.$screen.sm ? "main-page-title" : ""}`,
              { "is-pwa": _vm.$isPwa() },
            ],
          },
          [
            _vm._v(
              "\n\t\t\t" + _vm._s(_vm.FormMSG(10000, "Profile")) + "\n\t\t"
            ),
          ]
        ),
      ]),
      _c(
        "div",
        { class: `${_vm.$screen.width <= 576 ? "my-project" : ""}` },
        [
          _c(
            "b-tabs",
            {
              attrs: {
                "active-nav-item-class": "font-weight-bold",
                "active-tab-class": "font-weight-bold",
              },
              model: {
                value: _vm.activeTabs,
                callback: function ($$v) {
                  _vm.activeTabs = $$v
                },
                expression: "activeTabs",
              },
            },
            [
              _c(
                "b-tab",
                { attrs: { title: _vm.FormMSG(200, "Profile") } },
                [_c("profile-tab")],
                1
              ),
              _c(
                "b-tab",
                { attrs: { lazy: "", title: _vm.FormMSG(300, "Contracts") } },
                [_c("Mycontracts")],
                1
              ),
              !_vm.isFilmSingle && !_vm.isFilmSingleFree && _vm.useDoc
                ? _c(
                    "b-tab",
                    {
                      attrs: { lazy: "", title: _vm.FormMSG(301, "Documents") },
                    },
                    [_c("MyDocuments")],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }