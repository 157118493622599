var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "d-flex flex-column flex-wrap h-auto" }, [
    _c(
      "div",
      {
        staticClass: "d-flex flex-row align-items-center custom-list",
        on: { click: _vm.handleClickListMenu },
      },
      [
        _c("div", { staticClass: "custom-icon" }, [
          _c("div", [_c(_vm.icon, { tag: "component" })], 1),
        ]),
        _c("div", { staticClass: "custom-item" }, [
          _c("h2", { staticClass: "d-flex align-items-center" }, [
            _vm._v(_vm._s(_vm.title)),
          ]),
        ]),
        _c("div", { staticClass: "custom-arrow-right" }, [
          _c("div", [_c(_vm.iconRight, { tag: "component" })], 1),
        ]),
      ]
    ),
    _c("div", { staticClass: "divider custom-divider" }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }