<template>
	<div class="d-flex flex-column flex-wrap h-auto">
		<!-- <div class="list-menu" @click="handleClickListMenu">
			<div style="width: 15%" class="d-flex justify-content-center align-items-center">
				<div class="list-menu-icon">
					<component :is="icon" />
				</div>
			</div>
			<div style="width: 80%; margin-left: 5%">
				<h2>{{ title }}</h2>
				<p style="font-weight: 400">
					{{ description }}
				</p>
			</div>
			<div style="width: 10%">
				<div class="vertical-center float-right" style="right: 10px">
					<component :is="iconRight" />
				</div>
			</div>
		</div> -->
		<div class="d-flex flex-row align-items-center custom-list" @click="handleClickListMenu">
			<div class="custom-icon">
				<div>
					<component :is="icon" />
				</div>
			</div>
			<div class="custom-item">
				<h2 class="d-flex align-items-center">{{ title }}</h2>
			</div>
			<div class="custom-arrow-right">
				<div>
					<component :is="iconRight" />
				</div>
			</div>
		</div>
		<div class="divider custom-divider"></div>
	</div>
</template>

<script>
import * as icons from 'lucide-vue';

export default {
	name: 'ListMenu',

	props: {
		iconLucide: { type: String, required: true },
		title: { type: String, required: true },
		description: { type: String, required: false },
		path: { type: String, required: true }
	},

	computed: {
		icon() {
			return icons[this.iconLucide];
		},
		iconRight() {
			return icons['ChevronRight'];
		}
	},

	methods: {
		handleClickListMenu() {
			this.$emit('list-menu:click', this.path);
		}
	}
};
</script>

<style scoped>
.custom-list {
	height: 40px;
}
.custom-icon {
	display: flex;
	justify-content: start;
	align-items: center;
	width: 40px;
	height: 40px;
	background-color: #f5f7f9;
	border-radius: 50%;
	padding: 8px;
}
.custom-item {
	display: flex;
	justify-content: start;
	align-items: center;
	width: 74%;
	height: 40px;
	padding: 10px 0px 0px 16px;
}

.custom-arrow-right {
	display: flex;
	justify-content: end;
	align-items: center;
	width: 10%;
	height: 40px;
}
.custom-divider {
	margin-top: 0px;
}
</style>
