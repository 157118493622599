var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "form" },
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { sm: "12", md: "3" } },
            [
              _c(
                "b-form-group",
                { attrs: { label: _vm.FormMSG(1228, "Name") } },
                [
                  _c("b-form-input", {
                    attrs: {
                      type: "text",
                      placeholder: _vm.FormMSG(1229, "Vehicle name ..."),
                      state: !_vm.$v.dataToSend.name.$error,
                    },
                    on: { change: _vm.emitChange },
                    model: {
                      value: _vm.$v.dataToSend.name.$model,
                      callback: function ($$v) {
                        _vm.$set(_vm.$v.dataToSend.name, "$model", $$v)
                      },
                      expression: "$v.dataToSend.name.$model",
                    },
                  }),
                  _vm.$v.dataToSend.name.$error
                    ? _c("div", { staticClass: "invalid-feedback" }, [
                        _vm._v(
                          "\n\t\t\t\t\t" +
                            _vm._s(_vm.FormMSG(125, "Name is required")) +
                            "\n\t\t\t\t"
                        ),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { sm: "12", md: "3" } },
            [
              _c(
                "b-form-group",
                { attrs: { label: _vm.FormMSG(1230, "Brand") } },
                [
                  _c("b-form-input", {
                    attrs: {
                      type: "text",
                      placeholder: _vm.FormMSG(1231, "Vehicle brand ..."),
                    },
                    on: { change: _vm.emitChange },
                    model: {
                      value: _vm.dataToSend.brand,
                      callback: function ($$v) {
                        _vm.$set(_vm.dataToSend, "brand", $$v)
                      },
                      expression: "dataToSend.brand",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { sm: "12", md: "3" } },
            [
              _c(
                "b-form-group",
                { attrs: { label: _vm.FormMSG(1232, "Model") } },
                [
                  _c("b-form-input", {
                    attrs: {
                      type: "text",
                      placeholder: _vm.FormMSG(1233, "Vehicle model ..."),
                    },
                    on: { change: _vm.emitChange },
                    model: {
                      value: _vm.dataToSend.model,
                      callback: function ($$v) {
                        _vm.$set(_vm.dataToSend, "model", $$v)
                      },
                      expression: "dataToSend.model",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { sm: "12", md: "3" } },
            [
              _c(
                "b-form-group",
                { attrs: { label: _vm.FormMSG(1234, "Number plate") } },
                [
                  _c("b-form-input", {
                    attrs: {
                      type: "text",
                      placeholder: _vm.FormMSG(1235, "Your number plate ..."),
                    },
                    on: { change: _vm.emitChange },
                    model: {
                      value: _vm.dataToSend.numberPlate,
                      callback: function ($$v) {
                        _vm.$set(_vm.dataToSend, "numberPlate", $$v)
                      },
                      expression: "dataToSend.numberPlate",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { sm: "12", md: "9" } },
            [
              _c("transport-type-selector", {
                ref: "transportTypSelectorVehicule",
                attrs: {
                  "data-additional-detail": _vm.dataToSend.additionalSubType,
                  "data-detail": _vm.dataToSend.subType,
                  "data-type": _vm.dataToSend.type,
                  "hide-boat-type": "",
                  "hide-menues-not-two-wheels": "",
                  "hide-freight-type": "",
                },
                on: {
                  "transportation-type-selector:change":
                    _vm.handleTransportationTypeSelectorChange,
                },
              }),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { sm: "12", md: "3" } },
            [
              _c(
                "b-form-group",
                [
                  _c("label", [
                    _vm._v(
                      _vm._s(_vm.FormMSG(1238, "Kg CO2 per")) +
                        " " +
                        _vm._s(_vm.distanceUnit)
                    ),
                  ]),
                  _c("b-form-input", {
                    attrs: {
                      type: "number",
                      step: "0.01",
                      min: "0",
                      placeholder: "0",
                    },
                    on: { change: _vm.emitChange },
                    model: {
                      value: _vm.dataToSend.kgCoTwoPerKm,
                      callback: function ($$v) {
                        _vm.$set(_vm.dataToSend, "kgCoTwoPerKm", $$v)
                      },
                      expression: "dataToSend.kgCoTwoPerKm",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }