<template>
	<errorContainer :error="erreur">
		<transition name="slide">
			<div v-if="$screen.width <= 576">
				<!-- <h1 :class="[`${!$screen.sm ? 'main-page-title' : ''}`, { 'is-pwa': $isPwa() }]">{{ FormMSG(10, 'My Profile') }}</h1> -->
				<div class="container-mobile animated fadeIn pl-0 pr-0" style="top: 95px">
					<div class="container-myProfile-mobile">
						<b-row>
							<b-col v-if="menuActiveForMobile !== ''" :cols="!isFilmSingle ? 12 : 8" @click="menuActiveForMobile = ''" class="px-0">
								<div class="back-with-title cursor-pointer" :class="{ 'mt-16': $isPwa() }">
									<div class="icon">
										<component :is="getIconLucide('ArrowLeft')" color="#06263E" :size="22" />
									</div>
									<h1>{{ pageMobileTitle }}</h1>
								</div>
							</b-col>
							<b-col cols="4" :offset="menuActiveForMobile === '' ? 4 : 0">
								<div class="float-right">
									<ShareInformation
										v-if="isFilmSingle"
										tag="is-lucide"
										:btn-label="FormMSG(215, 'Share')"
										variant-btn="light"
										:title-modal="FormMSG(170, 'Send your personal informations sheet')"
										send-a-copy
										:msg-success="FormMSG(250, 'Profile successfully sent')"
										style="margin: 16px 16px 16px 0"
									/>
								</div>
							</b-col>
						</b-row>
						<form class="form" @submit="submitForm">
							<div
								v-if="menuActiveForMobile === ''"
								:class="{
									'mt-3': menuActiveForMobile === ''
								}"
							>
								<list-menu
									icon-lucide="User"
									:title="FormMSG(1211, 'Personal Data')"
									:description="FormMSG(1212, 'Update your basic information, address or emergency contact.')"
									path="personal-data"
									@list-menu:click="handleMenuMobileClick"
								/>
								<list-menu
									icon-lucide="ClipboardList"
									:title="FormMSG(4, 'Identification')"
									:description="FormMSG(1214, 'Update your identification numbers, bank information or family composition')"
									path="legal-information"
									@list-menu:click="handleMenuMobileClick"
								/>
								<!--Finance-->
								<list-menu icon-lucide="Wallet" :title="FormMSG(1306, 'Finance')" path="finance" @list-menu:click="handleMenuMobileClick" />
								<!--Address-->
								<list-menu icon-lucide="MapPin" :title="FormMSG(11, 'Address')" path="address" @list-menu:click="handleMenuMobileClick" />

								<!--health-->
								<list-menu icon-lucide="HeartPulse" :title="FormMSG(1252, 'Health')" path="health" @list-menu:click="handleMenuMobileClick" />
								<!--Family-->
								<list-menu icon-lucide="Users" :title="FormMSG(13, 'Family')" path="family" @list-menu:click="handleMenuMobileClick" />
								<!--Personal vehicle-->
								<list-menu
									icon-lucide="Car"
									:title="FormMSG(1226, 'Personal vehicle')"
									path="personal-vehicle"
									@list-menu:click="handleMenuMobileClick"
								/>
								<!--Other-->
								<list-menu icon-lucide="List" :title="FormMSG(16, 'Other')" path="other" @list-menu:click="handleMenuMobileClick" />
								<!--Setting-->
								<list-menu
									icon-lucide="Settings"
									:title="FormMSG(1215, 'Settings')"
									:description="FormMSG(1216, 'Change your password and communication language.')"
									path="settings"
									@list-menu:click="handleMenuMobileClick"
								/>
								<list-menu
									v-if="isDeterProject === true"
									icon-lucide="UserCog"
									:title="FormMSG(131, 'Team session')"
									:description="FormMSG(1257, 'Configure your team session.')"
									path="team-session"
									@list-menu:click="handleMenuMobileClick"
								/>
							</div>
							<!--Address navigate-->
							<div v-if="menuActiveForMobile === 'personal-data'">
								<div class="personal-data">
									<h2>{{ FormMSG(1182, 'Basic Information') }}</h2>
									<b-row>
										<b-col cols="6">
											<b-form-group :label="FormMSG(1240, 'Title')">
												<b-form-select v-model="myProfile.title">
													<b-form-select-option v-for="(title, index) in menuTitle" :key="index" :value="title.value">
														{{ title.text }}
													</b-form-select-option>
												</b-form-select>
											</b-form-group>
										</b-col>
										<b-col cols="6">
											<b-form-group>
												<label for="reference"> {{ this.FormMSG(1, 'Reference') }} </label>
												<b-form-input
													v-model="myProfile.reference"
													type="text"
													id="reference"
													:placeholder="FormMSG(1704, 'Personal number.')"
													@keydown.enter.prevent
													disabled
												></b-form-input>
											</b-form-group>
										</b-col>
									</b-row>
									<b-row>
										<b-col cols="7">
											<b-form-group :label="FormMSG(1041, 'Name')">
												<b-form-input v-model="myProfile.firstName" :placeholder="FormMSG(1003, 'Enter Name')" @keydown.enter.prevent />
											</b-form-group>
											<b-form-group :label="FormMSG(40, 'Last Name')">
												<b-form-input v-model="myProfile.name" :placeholder="FormMSG(1143, 'Enter Last Name')" @keydown.enter.prevent />
											</b-form-group>
											<b-form-group>
												<label for="maidenName">{{ FormMSG(1239, ' Maiden name (if different)') }}</label>
												<b-form-input v-model="myProfile.maidenName" type="text" id="maidenName" @keydown.enter.prevent></b-form-input>
											</b-form-group>
										</b-col>
										<b-col cols="5">
											<div style="height: 100px; width: 100px; margin-left: auto; margin-right: auto">
												<div class="container-img-mobile" style="height: 100%; width: 100%">
													<div v-if="myProfile.picture && myProfile.picture.length > 0" style="height: 100%; width: 100%">
														<img style="width: 96px; height: 96px" :src="getImage(myProfile.picture, 'picture')" />
														<component
															:is="getIconLucide('XCircle')"
															:size="25"
															style="top: -33px; right: 0px"
															:stroke-width="1.75"
															color="#ea4e2c"
															class="cursor-pointer icon-remove-img-profile"
															id="tooltip-remove-picture"
															@click="deleteImg('picture')"
															v-if="myProfile.picture && myProfile.picture.length > 0"
														/>
														<b-tooltip
															target="tooltip-remove-picture"
															triggers="hover"
															v-if="myProfile.picture && myProfile.picture.length > 0"
														>
															{{ FormMSG(255, 'Remove picture') }}
														</b-tooltip>
													</div>
													<div v-else class="avatar">
														<component
															:is="getIconLucide('User')"
															fill="#E2E6EB"
															color="#E2E6EB"
															:size="45"
															class="vertical-center"
														/>
													</div>
													<MobileProfilePicCaptureBtn
														parent-type="user"
														crop-type="avatar"
														:parentId="myProfile.id"
														@click="changeFieldPic('picture')"
														@change="handleImgsTaked"
													/>
												</div>
											</div>
											<div>
												<fieldset v-if="useTask && !isFilmSingle" class="scheduler-border">
													<legend class="scheduler-border">
														{{ FormMSG(525, 'Color') }}
													</legend>
													<b-row>
														<b-col cols="12" class="text-center">
															<verte v-model="myProfile.color" picker="square" model="hex" menuPosition="top" />
														</b-col>
													</b-row>
												</fieldset>
											</div>
										</b-col>
									</b-row>
									<b-form-group :label="FormMSG(42, 'Email')">
										<b-input-group>
											<b-form-input
												:state="myProfile.email && myProfile.email.length > 0"
												:value="myProfile.email"
												type="text"
												id="email"
												:placeholder="FormMSG(1102, 'Enter your email')"
												@keydown.enter.prevent
												disabled
											/>
											<template #append>
												<b-button variant="primary" @click="showChangeMailModal = true">
													<Pencil color="#ffffff" :size="16" />
												</b-button>
											</template>
										</b-input-group>
									</b-form-group>
									<b-form-group :label="FormMSG(44, 'Phone')">
										<b-input-group>
											<b-form-input
												:value="myProfile.phone"
												type="text"
												id="phone"
												@keydown.enter.prevent
												disabled
												:placeholder="FormMSG(45, 'Enter your phone number')"
											/>
											<template #append>
												<b-button variant="primary" @click="showChangePhoneModal = true">
													<Pencil color="#ffffff" :size="16" />
												</b-button>
											</template>
										</b-input-group>
									</b-form-group>
									<b-row>
										<b-col cols="6">
											<b-form-group :label="FormMSG(46, 'Nationality')">
												<b-form-input
													v-model="myProfile.nationality"
													:placeholder="FormMSG(46, 'Nationality')"
													@keydown.enter.prevent
												/>
											</b-form-group>
										</b-col>
										<b-col cols="6">
											<b-form-group :label="FormMSG(1305, 'Language')">
												<b-form-select v-model="myProfile.language" :options="optionsForLanguages" />
											</b-form-group>
										</b-col>
									</b-row>
									<b-row>
										<b-col cols="6">
											<b-form-group :label="FormMSG(48, 'Birthdate')">
												<b-form-input
													:value="displayBirthDate"
													type="date"
													:placeholder="FormMSG(1205, 'DD/MM/YYYY')"
													@input="updateBirthDate"
													@keydown.enter.prevent
												/>
											</b-form-group>
										</b-col>
										<b-col cols="6">
											<b-form-group :label="FormMSG(1241, 'Country of birth')">
												<b-form-input
													v-model="myProfile.birthCountry"
													:placeholder="FormMSG(1315, 'Enter your country of ...')"
													@keydown.enter.prevent
												/>
											</b-form-group>
										</b-col>
									</b-row>
									<b-row>
										<b-col cols="6">
											<b-form-group :label="FormMSG(1152, 'City of Birth')">
												<b-form-input
													v-model="myProfile.placeOfBirth"
													:placeholder="FormMSG(1153, 'Enter City of Birth')"
													@keydown.enter.prevent
												/>
											</b-form-group>
										</b-col>
										<b-col cols="6">
											<b-form-group :label="FormMSG(1154, 'Zip Code (City of Birth)')">
												<b-form-input
													v-model="myProfile.placeOfBirthZip"
													:placeholder="FormMSG(1155, 'Enter Zip Code')"
													@keydown.enter.prevent
												/>
											</b-form-group>
										</b-col>
									</b-row>
									<b-form-group :label="FormMSG(164, 'Gender')" v-slot="{ ariaDescribedby }" class="gender-group">
										<b-form-radio-group v-model="myProfile.gender" :aria-describedby="ariaDescribedby">
											<div style="display: flex">
												<b-form-radio
													v-for="(gender, index) in menuGenders"
													:key="index"
													:value="gender.value"
													v-if="gender.value !== null"
												>
													{{ gender.text }}
												</b-form-radio>
											</div>
										</b-form-radio-group>
									</b-form-group>
									<!--end of personal data-->
								</div>
							</div>
							<div v-if="menuActiveForMobile === 'legal-information'">
								<div class="legal-information">
									<h2>{{ FormMSG(1187, 'Identification Numbers') }}</h2>
									<b-form-group :label="FormMSG(55, 'National Registration Number')">
										<b-form-input
											v-model="myProfile.nationalRegistryNumber"
											:placeholder="FormMSG(56, 'Enter your National Registration Number ')"
											@keydown.enter.prevent
										/>
									</b-form-group>
									<b-form-group :label="FormMSG(57, 'Company Number')">
										<b-form-input
											v-model="myProfile.companyNumber"
											:placeholder="FormMSG(58, 'Enter your Company official Number ')"
											@keydown.enter.prevent
										/>
									</b-form-group>
									<b-form-group>
										<label for="conge_spectacles_id">{{ FormMSG(1242, '"Congés spectacles" ID') }}</label>
										<b-form-input
											v-model="myProfile.congesSpectacle"
											type="text"
											id="conge_spectacles_id"
											:placeholder="FormMSG(1243, 'Enter your conges spectacles id')"
											@keydown.enter.prevent
										/>
									</b-form-group>
									<b-form-group>
										<label for="idNumber">{{ FormMSG(216, 'ID Name') }}</label>
										<b-form-input
											v-model="myProfile.idName"
											type="text"
											id="idNumber"
											:placeholder="FormMSG(217, 'Enter your ID Name ...')"
											@keydown.enter.prevent
										></b-form-input>
									</b-form-group>
									<b-form-group :label="FormMSG(32, 'ID Number')">
										<b-form-input
											v-model="myProfile.idNumber"
											type="text"
											id="idNumber"
											:placeholder="FormMSG(33, 'Enter your ID Number ...')"
											@keydown.enter.prevent
										/>
									</b-form-group>
									<b-form-group :label="FormMSG(166, 'Driver licence')">
										<b-form-input
											v-model="myProfile.driverLicense"
											:placeholder="FormMSG(167, 'Enter your driver license')"
											@keydown.enter.prevent
										/>
									</b-form-group>

									<b-form-group>
										<label>{{ FormMSG(1314, 'Driver licence Number') }}</label>
										<b-form-input
											v-model="myProfile.driverLicenseNumber"
											type="text"
											:placeholder="FormMSG(1316, 'Enter your driver license number')"
											@keydown.enter.prevent
										/>
									</b-form-group>
									<b-form-group>
										<label for="signatureId">{{ this.FormMSG(223, 'My signature') }}</label>
										<b-row class="ml-0">
											<div
												@click="onOpenUrl(getImage(myProfile.signatureId, 'signatureId'))"
												class="d-flex justify-content-center align-items-center cursor-pointer"
												v-if="checkFile(myProfile.signatureId)"
											>
												<b-icon icon="file-earmark-pdf-fill" :font-scale="1.6" />
											</div>
											<b-img
												v-else
												v-bind="imgPropSignatureId"
												rounded="circle"
												alt="Signature ID"
												class="cursor-pointer"
												:src="getImage(myProfile.signatureId, 'signatureId')"
												@click="showImage(myProfile.signatureId)"
											></b-img>
											<div class="d-flex ml-3 justify-content-center align-items-center">
												<MobileProfilePicCaptureBtn
													parent-type="user"
													id="pictureID"
													@click="changeFieldPic('signatureId')"
													:parentId="myProfile.id"
													@change="handleImgsTaked"
												/>
												<button
													class="btn-transparent pl-0 ml-2"
													@click.prevent.stop="deleteImg('signatureId')"
													v-if="myProfile.signatureId && myProfile.signatureId.length > 0"
												>
													<component :is="getIconLucide('Trash2')" :size="24" :stroke-width="1.5" color="#ea4e2c" />
												</button>
											</div>
										</b-row>
									</b-form-group>
									<b-row>
										<b-col>
											<b-form-group>
												<label for="pictureId">{{ FormMSG(218, 'ID picture - Front') }}</label>
												<b-row class="ml-0">
													<div
														@click="onOpenUrl(getImage(myProfile.pictureId, 'pictureId'))"
														class="d-flex justify-content-center align-items-center cursor-pointer"
														v-if="checkFile(myProfile.pictureId)"
													>
														<b-icon icon="file-earmark-pdf-fill" :font-scale="1.6" />
													</div>
													<b-img
														v-else
														v-bind="imgPropPictureId"
														rounded="circle"
														alt="ID picture - front"
														class="cursor-pointer"
														:src="getImage(myProfile.pictureId, 'pictureId')"
														@click="showImage(myProfile.pictureId)"
													/>
													<div class="d-flex ml-3 justify-content-center align-items-center">
														<MobileProfilePicCaptureBtn
															parent-type="user"
															id="pictureID"
															@click="changeFieldPic('pictureId')"
															:parentId="myProfile.id"
															@change="handleImgsTaked"
														/>
														<button
															class="btn-transparent pl-0 ml-2"
															@click.prevent.stop="deleteImg('pictureId')"
															v-if="myProfile.pictureId && myProfile.pictureId.length > 0"
														>
															<component :is="getIconLucide('Trash2')" :size="24" :stroke-width="1.5" color="#ea4e2c" />
														</button>
													</div>
												</b-row>
											</b-form-group>
										</b-col>
										<b-col>
											<b-form-group>
												<label for="pictureIdVerso">{{ FormMSG(219, 'ID picture - Back') }}</label>
												<b-row class="ml-0">
													<div
														@click="onOpenUrl(getImage(myProfile.pictureIdVerso, 'pictureIdVerso'))"
														class="d-flex justify-content-center align-items-center cursor-pointer"
														v-if="checkFile(myProfile.pictureIdVerso)"
													>
														<b-icon icon="file-earmark-pdf-fill" :font-scale="1.6" />
													</div>
													<b-img
														v-else
														v-bind="imgPropPictureIdVerso"
														rounded="circle"
														alt="ID picture - Back"
														class="cursor-pointer"
														:src="getImage(myProfile.pictureIdVerso, 'pictureIdVerso')"
														@click="showImage(myProfile.pictureIdVerso)"
													/>
													<div class="d-flex ml-3 justify-content-center align-items-center">
														<MobileProfilePicCaptureBtn
															parent-type="user"
															id="pictureIdVerso"
															@click="changeFieldPic('pictureIdVerso')"
															:parentId="myProfile.id"
															@change="handleImgsTaked"
														/>
														<button
															class="btn-transparent pl-0 ml-2"
															@click.prevent.stop="deleteImg('pictureIdVerso')"
															v-if="myProfile.pictureIdVerso && myProfile.pictureIdVerso.length > 0"
														>
															<component :is="getIconLucide('Trash2')" :size="24" :stroke-width="1.5" color="#ea4e2c" />
														</button>
													</div>
												</b-row>
											</b-form-group>
										</b-col>
									</b-row>
									<b-row>
										<b-col>
											<b-form-group>
												<label for="pictureDriverLicense">{{ FormMSG(221, 'Driver license picture - Front') }}</label>
												<b-row class="ml-0">
													<div
														@click="onOpenUrl(getImage(myProfile.pictureDriverLicense, 'pictureDriverLicense'))"
														class="d-flex justify-content-center align-items-center cursor-pointer"
														v-if="checkFile(myProfile.pictureDriverLicense)"
													>
														<b-icon icon="file-earmark-pdf-fill" :font-scale="1.6" />
													</div>
													<b-img
														v-else
														v-bind="imgPropPictureDriverLicense"
														rounded
														alt="Driver license picture - Front"
														class="cursor-pointer"
														:src="getImage(myProfile.pictureDriverLicense, 'pictureDriverLicense')"
														@click="showImage(myProfile.pictureDriverLicense)"
													/>
													<div class="d-flex ml-3 justify-content-center align-items-center">
														<MobileProfilePicCaptureBtn
															parent-type="user"
															id="pictureDriverLicense"
															@click="changeFieldPic('pictureDriverLicense')"
															:parentId="myProfile.id"
															@change="handleImgsTaked"
														/>
														<button
															class="btn-transparent pl-0 ml-2"
															@click.prevent.stop="deleteImg('pictureDriverLicense')"
															v-if="myProfile.pictureDriverLicense && myProfile.pictureDriverLicense.length > 0"
														>
															<component :is="getIconLucide('Trash2')" :size="24" :stroke-width="1.5" color="#ea4e2c" />
														</button>
													</div>
												</b-row>
											</b-form-group>
										</b-col>
										<b-col>
											<b-form-group>
												<label for="pictureDriverLicenseVerso">{{ FormMSG(222, 'Driver license picture - Back') }}</label>
												<b-row class="ml-0">
													<div
														@click="onOpenUrl(getImage(myProfile.pictureDriverLicenseVerso, 'pictureDriverLicenseVerso'))"
														class="d-flex justify-content-center align-items-center cursor-pointer"
														v-if="checkFile(myProfile.pictureDriverLicenseVerso)"
													>
														<b-icon icon="file-earmark-pdf-fill" :font-scale="1.6" />
													</div>
													<b-img
														v-else
														v-bind="imgPropPictureIdVerso"
														rounded
														alt="Driver license picture - Back"
														class="cursor-pointer"
														:src="getImage(myProfile.pictureDriverLicenseVerso, 'pictureDriverLicenseVerso')"
														@click="showImage(myProfile.pictureDriverLicenseVerso)"
													/>
													<div class="d-flex ml-3 justify-content-center align-items-center">
														<MobileProfilePicCaptureBtn
															parent-type="user"
															id="pictureDriverLicenseVerso"
															@click="changeFieldPic('pictureDriverLicenseVerso')"
															:parentId="myProfile.id"
															@change="handleImgsTaked"
														/>
														<button
															class="btn-transparent pl-0 ml-2"
															@click.prevent.stop="deleteImg('pictureDriverLicenseVerso')"
															v-if="myProfile.pictureDriverLicenseVerso && myProfile.pictureDriverLicenseVerso.length > 0"
														>
															<component :is="getIconLucide('Trash2')" :size="24" :stroke-width="1.5" color="#ea4e2c" />
														</button>
													</div>
												</b-row>
											</b-form-group>
										</b-col>
									</b-row>
									<div v-if="isPme && myProfile.vehicule">
										<div class="divider" />
										<h2>{{ FormMSG(1227, 'Vehicle') }}</h2>
										<div class="switch-group">
											<b-form-group :label="FormMSG(1228, 'Name')">
												<b-form-input
													v-model="myProfile.vehicule.name"
													type="text"
													:placeholder="FormMSG(1229, 'Vehicle name ...')"
													@keydown.enter.prevent
												/>
											</b-form-group>
											<b-form-group :label="FormMSG(1230, 'Brand')">
												<b-form-input
													v-model="myProfile.vehicule.brand"
													type="text"
													:placeholder="FormMSG(1231, 'Vehicle brand ...')"
													@keydown.enter.prevent
												/>
											</b-form-group>
											<b-form-group :label="FormMSG(1232, 'Model')">
												<b-form-input
													v-model="myProfile.vehicule.model"
													type="text"
													:placeholder="FormMSG(1233, 'Vehicle model ...')"
													@keydown.enter.prevent
												/>
											</b-form-group>
											<b-form-group :label="FormMSG(1234, 'Number plate')">
												<b-form-input
													v-model="myProfile.vehicule.numberPlate"
													type="text"
													:placeholder="FormMSG(1235, 'Your number plate ...')"
													@keydown.enter.prevent
												/>
											</b-form-group>
											<b-form-group :label="FormMSG(1236, 'Type')">
												<b-form-select v-model="myProfile.vehicule.type" :options="vehicleTypes" />
											</b-form-group>
											<b-form-group :label="FormMSG(1237, 'Energy')">
												<b-form-select v-model="myProfile.vehicule.energy" :options="vehicleEnergies" />
											</b-form-group>
											<b-form-group>
												<label>{{ FormMSG(1238, 'Kg CO2 per') }} {{ distanceUnit }}</label>
												<b-form-input
													v-model="myProfile.vehicule.kgCoTwoPerKm"
													type="number"
													step="0.01"
													min="0"
													placeholder="0"
													@keydown.enter.prevent
												/>
											</b-form-group>
										</div>
									</div>
								</div>
							</div>
							<div v-if="menuActiveForMobile === 'finance'">
								<div class="personal-data">
									<h2>{{ FormMSG(1188, 'Bank Information') }}</h2>
									<b-form-group :label="FormMSG(49, 'IBAN')" :class="{ 'is-warning': userBankWarning.iban }">
										<b-form-input
											v-model="myProfile.iban"
											:placeholder="FormMSG(1189, 'Enter IBAN')"
											:class="{ 'is-warning': userBankWarning.iban }"
											@input="handleSupplierIbanChange"
											@keydown.enter.prevent
										/>
										<div v-if="userBankWarning.iban" class="warning-feedback">
											{{ FormMSG(97986, "IBAN seems not valid, please make sure it's corrrect") }}
										</div>
									</b-form-group>
									<b-form-group :label="FormMSG(51, 'BIC')" :class="{ 'is-warning': userBankWarning.bic }">
										<b-form-input
											v-model="myProfile.bic"
											:placeholder="FormMSG(1190, 'Enter BIC')"
											@input="handleSupplierBicChange"
											@keydown.enter.prevent
										/>
										<div v-if="userBankWarning.bic" class="warning-feedback">
											{{ FormMSG(909097986, "BIC seems not valid, please make sure it's corrrect") }}
										</div>
									</b-form-group>
									<b-form-group>
										<label for="bic">{{ this.FormMSG(1245, 'Bank account owner name') }}</label>
										<b-form-input
											v-model="myProfile.bankAccountOwnerName"
											type="text"
											id="bic"
											:placeholder="this.FormMSG(1246, 'Enter your bank account owner name')"
											@keydown.enter.prevent
										></b-form-input>
									</b-form-group>
									<b-form-group>
										<label for="bankDetailDocument">{{ FormMSG(1247, 'Bank detail document') }}</label>
										<b-row class="ml-0">
											<div
												@click="onOpenUrl(getImage(myProfile.bankDetailXid, 'bankDetailXid'))"
												class="d-flex justify-content-center align-items-center cursor-pointer"
												v-if="checkFile(myProfile.bankDetailXid)"
											>
												<b-icon icon="file-earmark-pdf-fill" :font-scale="1.6" />
											</div>
											<b-img
												v-else
												v-bind="imgPropSignatureId"
												rounded
												alt="Bank detail document"
												class="cursor-pointer"
												:src="getImage(myProfile.bankDetailXid, 'bankDetailXid')"
												@click="showImage(myProfile.bankDetailXid)"
												:title="FormMSG(254, 'View Image')"
											></b-img>
											<div class="d-flex ml-3 justify-content-center align-items-center">
												<MobileProfilePicCaptureBtn
													parent-type="user"
													id="pictureRib"
													@click="changeFieldPic('bankDetailXid')"
													:parentId="myProfile.id"
													@change="handleImgsTaked"
												/>
												<button
													class="btn-transparent pl-0 ml-2"
													@click.prevent.stop="deleteImg('bankDetailXid')"
													v-if="myProfile.bankDetailXid && myProfile.bankDetailXid.length > 0"
												>
													<component :is="getIconLucide('Trash2')" :size="24" :stroke-width="1.5" color="#ea4e2c" />
												</button>
											</div>
										</b-row>
									</b-form-group>
									<div class="divider" />
									<h2>{{ FormMSG(260, 'Taxes') }}</h2>
									<b-form-group :label="FormMSG(1310, 'Taxes percentage')">
										<b-input-group>
											<b-form-input
												v-model="myProfile.taxesPercentage"
												type="number"
												step="1"
												min="0"
												max="100"
												@keydown.enter.prevent
												v-limit-100
											/>
											<b-input-group-append>
												<b-input-group-text>
													<component :is="getIconLucide('Percent')" color="#06263E" :size="16" />
												</b-input-group-text>
											</b-input-group-append>
										</b-input-group>
									</b-form-group>
									<b-form-group>
										<label for="bic">{{ this.FormMSG(1248, 'Tax declaration country') }}</label>
										<b-form-input
											v-model="myProfile.taxCountry"
											type="text"
											id="taxCountry"
											:placeholder="this.FormMSG(1249, 'Enter your tax declaration country')"
											@keydown.enter.prevent
										></b-form-input>
									</b-form-group>
								</div>
							</div>
							<div v-if="menuActiveForMobile === 'address'">
								<div class="personal-data">
									<h2>{{ FormMSG(1308, 'Legal Address') }}</h2>
									<b-form-group>
										<label for="country">{{ FormMSG(106, 'Country') }}</label>
										<v-select
											:clearable="false"
											v-model="countryKey"
											:options="countries"
											label="text"
											:reduce="(option) => option.value"
											@input="getCountry"
										/>
									</b-form-group>
									<b-form-group>
										<google-auto-complete
											ref="google-search-address"
											:label="FormMSG(12, 'Internet search')"
											:country="countryCode"
											@placechanged="(item) => handleAutoComplete(item, 'legalAddress')"
										/>
									</b-form-group>
									<b-form-group :label="FormMSG(100, 'Street')">
										<b-form-input
											v-model="myProfile.address.street"
											:placeholder="FormMSG(1156, 'Enter Street Name')"
											@keydown.enter.prevent
										/>
									</b-form-group>
									<b-form-group :label="FormMSG(101, 'Number')">
										<b-form-input v-model="myProfile.address.number" :placeholder="FormMSG(1157, 'Enter Number')" @keydown.enter.prevent />
									</b-form-group>
									<b-form-group :label="FormMSG(1170, 'State/Region')">
										<b-form-input
											v-model="myProfile.address.state"
											:placeholder="FormMSG(1171, 'Enter State/Region')"
											@keydown.enter.prevent
										/>
									</b-form-group>
									<b-form-group :label="FormMSG(103, 'City')">
										<b-form-input v-model="myProfile.address.city" :placeholder="FormMSG(1172, 'Enter City')" @keydown.enter.prevent />
									</b-form-group>
									<b-form-group :label="FormMSG(1167, 'ZIP Code')">
										<b-form-input v-model="myProfile.address.zip" :placeholder="FormMSG(1168, 'Enter ZIP Code')" @keydown.enter.prevent />
									</b-form-group>
									<b-form-group :label="FormMSG(102, 'Box')">
										<b-form-input v-model="myProfile.address.box" :placeholder="FormMSG(1169, 'Enter Box Number')" @keydown.enter.prevent />
									</b-form-group>
								</div>
								<div class="personal-data mb-4">
									<b-collapse v-model="isShowPostalAddress">
										<h2>{{ FormMSG(1312, 'Postal address') }}</h2>
										<b-form-group>
											<label for="country">{{ FormMSG(106, 'Country') }}</label>
											<v-select
												:clearable="false"
												v-model="countryKeyPostalAddress"
												:options="countries"
												label="text"
												:reduce="(option) => option.value"
												@input="getCountryPostalAddress"
											/>
										</b-form-group>
										<b-form-group>
											<google-auto-complete
												ref="google-search-address"
												:label="FormMSG(12, 'Internet search')"
												:country="countryCodePostalAddress"
												@placechanged="(item) => handleAutoComplete(item, 'postalAddress')"
											/>
										</b-form-group>
										<b-form-group>
											<label for="address">{{ this.FormMSG(100, 'Street') }}</label>
											<b-form-input
												v-model="myProfile.postalAddress.street"
												type="text"
												id="street"
												:placeholder="FormMSG(1103, 'Enter your street')"
												@keydown.enter.prevent
											></b-form-input>
										</b-form-group>
										<b-form-group>
											<label for="number">{{ this.FormMSG(101, 'Number') }}</label>
											<b-form-input
												v-model="myProfile.postalAddress.number"
												type="text"
												id="number"
												@keydown.enter.prevent
											></b-form-input>
										</b-form-group>
										<b-form-group>
											<label for="state">{{ FormMSG(105, 'State') }}</label>
											<b-form-input v-model="myProfile.postalAddress.state" type="text" id="state" @keydown.enter.prevent></b-form-input>
										</b-form-group>
										<b-form-group>
											<label for="city">{{ this.FormMSG(103, 'City') }}</label>
											<b-form-input v-model="myProfile.postalAddress.city" type="text" id="city" @keydown.enter.prevent></b-form-input>
										</b-form-group>
										<b-form-group>
											<label for="zip">{{ this.FormMSG(104, 'Postal Code') }}</label>
											<b-form-input v-model="myProfile.postalAddress.zip" type="text" id="zip" @keydown.enter.prevent></b-form-input>
										</b-form-group>
										<b-form-group>
											<label for="box">{{ this.FormMSG(102, 'Box') }}</label>
											<b-form-input v-model="myProfile.postalAddress.box" type="text" id="box" @keydown.enter.prevent></b-form-input>
										</b-form-group>
									</b-collapse>
									<b-row>
										<div class="ml-3">
											<b-button
												size="sm"
												:variant="isShowPostalAddress ? 'outline-danger' : 'outline-secondary'"
												class="d-flex flex-row justify-content-center gap-2 align-items-center w-auto h-auto"
												style="height: 40px; border-radius: 0.5rem; background-color: #fff; cursor: pointer"
												@click="isShowPostalAddress = !isShowPostalAddress"
											>
												<div class="d-flex flex-row align-items-center justify-content-center btn-address" style="color: #06263e">
													<span v-if="isShowPostalAddress">
														<component :is="getLucideIcon(ICONS.X_CLEAR.name)" :color="ICONS.X_CLEAR.color" :size="22" />
														{{ FormMSG(1313, 'Close') }}
													</span>
													<span v-else>
														<component :is="getLucideIcon('PlusCircle')" color="#47C7BF" :size="22" />
														{{ FormMSG(1251, 'Add postal address (if different)') }}
													</span>
												</div>
											</b-button>
										</div>
									</b-row>
								</div>
							</div>
							<div v-if="menuActiveForMobile === 'health'">
								<div class="health">
									<b-form-group :label="FormMSG(54, 'Social Security Number')">
										<b-form-input
											v-model="myProfile.socSecNumber"
											:class="{ 'is-warning': isSocSecNumberWarning }"
											:placeholder="FormMSG(154, 'Enter your Social Security Number')"
											@input="handleSocSecNumberChange"
											@keydown.enter.prevent
										/>
										<div v-if="isSocSecNumberWarning" class="warning-feedback">
											{{ FormMSG(8979997, 'The social security number seems not valid') }}
										</div>
									</b-form-group>
									<b-form-group>
										<label for="socialSecurityCenter">{{ this.FormMSG(1700, 'Social security center') }}</label>
										<b-form-input
											v-model="myProfile.socialSecurityCenter"
											type="text"
											id="socialSecurityCenter"
											:placeholder="this.FormMSG(1258, 'Enter your social security center')"
											@keydown.enter.prevent
										></b-form-input>
									</b-form-group>
									<b-form-group :label="FormMSG(1259, 'Social security center address')">
										<v-address
											:params="{ title: 'Social security center address' }"
											:address-id="myProfile.socialSecurityCenterAddressId"
											@change="handleDynamicAddressChange"
											@delete="handleDynamicAddressDelete"
											@address-maplink-change="handleAddressMapLinkChange"
											@keydown.enter.prevent
										/>
									</b-form-group>
									<b-row>
										<b-col cols="6">
											<b-form-group>
												<label for="socialSecurityCardXid">{{ FormMSG(1255, 'Social security card') }}</label>
												<b-row class="ml-0">
													<div
														@click="onOpenUrl(getImage(myProfile.socialSecurityCardXid, 'socialSecurityCardXid'))"
														class="d-flex justify-content-center align-items-center cursor-pointer"
														v-if="checkFile(myProfile.socialSecurityCardXid)"
													>
														<b-icon icon="file-earmark-pdf-fill" :font-scale="1.6" />
													</div>
													<b-img
														v-else
														v-bind="imgPropSignatureId"
														rounded
														alt="socialSecurityCardXid"
														class="cursor-pointer"
														:src="getImage(myProfile.socialSecurityCardXid, 'socialSecurityCardXid')"
														@click="showImage(myProfile.socialSecurityCardXid)"
														:title="FormMSG(254, 'View Image')"
													></b-img>
													<div class="d-flex ml-3 justify-content-center align-items-center">
														<MobileProfilePicCaptureBtn
															parent-type="user"
															id="pictureRib"
															@click="changeFieldPic('socialSecurityCardXid')"
															:parentId="myProfile.id"
															@change="handleImgsTaked"
														/>
														<button
															class="btn-transparent pl-0 ml-2"
															@click.prevent.stop="deleteImg('socialSecurityCardXid')"
															v-if="myProfile.socialSecurityCardXid && myProfile.socialSecurityCardXid.length > 0"
														>
															<component :is="getIconLucide('Trash2')" :size="24" :stroke-width="1.5" color="#ea4e2c" />
														</button>
													</div>
												</b-row>
											</b-form-group>
										</b-col>
										<b-col cols="6">
											<b-form-group>
												<label for="insuranceCertificate">{{ FormMSG(1256, 'Insurance certificate') }}</label>
												<b-row class="ml-0">
													<div
														@click="onOpenUrl(getImage(myProfile.insuranceCertificateXid, 'insuranceCertificateXid'))"
														class="d-flex justify-content-center align-items-center cursor-pointer"
														v-if="checkFile(myProfile.insuranceCertificateXid)"
													>
														<b-icon icon="file-earmark-pdf-fill" :font-scale="1.6" />
													</div>
													<b-img
														v-else
														v-bind="imgPropSignatureId"
														rounded
														alt="insuranceCertificate"
														class="cursor-pointer"
														:src="getImage(myProfile.insuranceCertificateXid, 'insuranceCertificateXid')"
														@click="showImage(myProfile.insuranceCertificateXid)"
														:title="FormMSG(254, 'View Image')"
													></b-img>
													<div class="d-flex ml-3 justify-content-center align-items-center">
														<MobileProfilePicCaptureBtn
															parent-type="user"
															id="insuranceCertificateXid"
															@click="changeFieldPic('insuranceCertificateXid')"
															:parentId="myProfile.id"
															@change="handleImgsTaked"
														/>
														<button
															class="btn-transparent pl-0 ml-2"
															@click.prevent.stop="deleteImg('insuranceCertificateXid')"
															v-if="myProfile.insuranceCertificateXid && myProfile.insuranceCertificateXid.length > 0"
														>
															<component :is="getIconLucide('Trash2')" :size="24" :stroke-width="1.5" color="#ea4e2c" />
														</button>
													</div>
												</b-row>
											</b-form-group>
										</b-col>
									</b-row>
									<b-form-group>
										<label>{{ FormMSG(1260, 'Last medical visit date') }}</label>
										<b-form-input
											type="date"
											:value="displayDateLastMedicalVisit"
											:placeholder="FormMSG(1295, 'DD/MM/YYYY')"
											@input="(e) => updateDate(e, 'lastMedicalVisit')"
											@keydown.enter.prevent
										/>
									</b-form-group>
									<b-form-group>
										<label>{{ FormMSG(1262, 'Medical visit validity') }}</label>
										<b-form-input
											type="date"
											:value="displayDateMedicalVisitValidity"
											:placeholder="FormMSG(1295, 'DD/MM/YYYY')"
											@input="(e) => updateDate(e, 'medicalVisitValidity')"
											@keydown.enter.prevent
										/>
									</b-form-group>
									<b-form-group>
										<label for="MedicalVisitDocument">{{ FormMSG(1264, 'Medical visit document') }}</label>
										<b-row class="ml-0">
											<div
												@click="onOpenUrl(getImage(myProfile.medicalVisitDocumentXid, 'medicalVisitDocumentXid'))"
												class="d-flex justify-content-center align-items-center cursor-pointer"
												v-if="checkFile(myProfile.medicalVisitDocumentXid)"
											>
												<b-icon icon="file-earmark-pdf-fill" :font-scale="1.6" />
											</div>
											<b-img
												v-else
												v-bind="imgPropSignatureId"
												rounded
												alt="MedicalVisitDocument"
												class="cursor-pointer"
												:src="getImage(myProfile.medicalVisitDocumentXid, 'medicalVisitDocumentXid')"
												@click="showImage(myProfile.medicalVisitDocumentXid)"
												:title="FormMSG(254, 'View Image')"
											></b-img>
											<div class="d-flex ml-3 justify-content-center align-items-center">
												<MobileProfilePicCaptureBtn
													parent-type="user"
													id="medicalVisitDocumentXid"
													@click="changeFieldPic('medicalVisitDocumentXid')"
													:parentId="myProfile.id"
													@change="handleImgsTaked"
												/>
												<button
													class="btn-transparent pl-0 ml-2"
													@click.prevent.stop="deleteImg('medicalVisitDocumentXid')"
													v-if="myProfile.medicalVisitDocumentXid && myProfile.medicalVisitDocumentXid.length > 0"
												>
													<component :is="getIconLucide('Trash2')" :size="24" :stroke-width="1.5" color="#ea4e2c" />
												</button>
											</div>
										</b-row>
									</b-form-group>
									<b-form-group class="text-start">
										<label>{{ FormMSG(1265, 'Worker with a disability') }}</label>
										<b-form-checkbox class="d-flex justify-content-start" size="lg" v-model="myProfile.disabled" switch>
											<span class="text-redired">{{ myProfile.disabled ? FormMSG(1276, 'Yes') : FormMSG(1277, 'No') }}</span>
										</b-form-checkbox>
									</b-form-group>
									<!---->
									<div v-if="myProfile.disabled" class="card-gray">
										<b-form-group v-if="myProfile.disabled">
											<label>{{ FormMSG(1266, 'Disability notification date') }}</label>
											<b-form-input
												type="date"
												:value="displayDateDisabilityNotification"
												:placeholder="FormMSG(1295, 'DD/MM/YYYY')"
												@input="(e) => updateDate(e, 'disabilityNotification')"
												@keydown.enter.prevent
											/>
										</b-form-group>
										<b-form-group v-if="myProfile.disabled">
											<label for="type">{{ this.FormMSG(1268, 'Type') }}</label>
											<b-form-input
												v-model="myProfile.disabilityType"
												type="text"
												id="type"
												:placeholder="this.FormMSG(1269, 'Enter type')"
												@keydown.enter.prevent
											></b-form-input>
										</b-form-group>
										<b-form-group v-if="myProfile.disabled">
											<label for="status">{{ this.FormMSG(1270, 'Status') }}</label>
											<b-form-input
												v-model="myProfile.disabilityStatus"
												type="text"
												id="status"
												:placeholder="this.FormMSG(1271, 'Enter status')"
												@keydown.enter.prevent
											></b-form-input>
										</b-form-group>
										<b-form-group v-if="myProfile.disabled" :label="FormMSG(1272, 'Invalidity')">
											<b-form-select v-model="myProfile.invalidity">
												<b-form-select-option v-for="(gender, index) in menuInvalidity" :key="index" :value="gender.value">
													{{ gender.text }}
												</b-form-select-option>
											</b-form-select>
										</b-form-group>
										<b-form-group v-if="myProfile.disabled" class="text-start">
											<label>{{ FormMSG(1273, 'RQTH') }}</label>
											<b-form-checkbox class="d-flex justify-content-start" size="lg" v-model="myProfile.rqth" :value="true" switch>
												<span class="text-redired">{{ myProfile.rqth ? FormMSG(1276, 'Yes') : FormMSG(1277, 'No') }}</span>
											</b-form-checkbox>
										</b-form-group>
										<b-form-group v-if="myProfile.disabled" :label="FormMSG(1274, 'Invalidity percentage')">
											<b-input-group>
												<b-form-input
													v-model="myProfile.invalidityPourcentage"
													type="number"
													step="1"
													min="0"
													max="100"
													@keydown.enter.prevent
													v-limit-100
												/>
												<b-input-group-append>
													<b-input-group-text style="background-color: #00a09c">
														<component :is="getIconLucide('Percent')" color="#ffffff" :size="16" :stroke-width="3.5" />
													</b-input-group-text>
												</b-input-group-append>
											</b-input-group>
										</b-form-group>
									</div>
									<b-form-group class="text-start">
										<label>{{ FormMSG(25, 'Have Allergies?') }}</label>
										<b-form-checkbox class="d-flex justify-content-start" size="lg" v-model="haveAllergies" switch>
											<span class="text-redired">{{ haveAllergies ? FormMSG(1276, 'Yes') : FormMSG(1277, 'No') }}</span>
										</b-form-checkbox>
									</b-form-group>
									<div v-if="haveAllergies" class="card-gray">
										<b-form-group>
											<label>{{ FormMSG(26, 'Allergies details') }}</label>
											<b-form-textarea
												v-model="myProfile.allergies"
												type="textarea"
												rows="2"
												:placeholder="FormMSG(6, 'Enter allergies details...')"
												autocomplete="off"
												:disabled="haveAllergies === false"
											/>
										</b-form-group>
									</div>

									<div class="mt-4">&nbsp;</div>
								</div>
							</div>
							<div v-if="menuActiveForMobile === 'family'">
								<div class="personal-data">
									<h2>{{ FormMSG(14, 'Family composition') }}</h2>
									<b-form-group :label="FormMSG(53, 'Civil status')" label-for="optionsForCivilStatus">
										<b-form-select
											v-model="myProfile.civilStatus"
											:options="optionsForCivilStatus"
											id="optionsForCivilStatus"
										></b-form-select>
									</b-form-group>

									<div class="card-gray" v-if="myProfile.civilStatus === 3">
										<b-form-group>
											<label>{{ FormMSG(174, 'Spouse first name') }}</label>
											<b-form-input
												v-model="myProfile.spouseFirstName"
												type="text"
												:placeholder="FormMSG(176, 'Enter your Spouse first name ')"
												@keydown.enter.prevent
											/>
										</b-form-group>
										<b-form-group>
											<label>{{ FormMSG(172, 'Spouse name') }}</label>
											<b-form-input
												v-model="myProfile.spouseName"
												type="text"
												:placeholder="FormMSG(173, 'Enter your Spouse name ')"
												@keydown.enter.prevent
											/>
										</b-form-group>
										<b-form-group>
											<label>{{ FormMSG(177, 'Spouse birth date') }}</label>
											<b-form-input
												type="date"
												:value="displaySpouseBirthDate"
												:placeholder="FormMSG(178, 'Enter your Spouse birth date ')"
												@input="updateSpouseBirthDate"
												@keydown.enter.prevent
											/>
										</b-form-group>
										<b-form-group class="text-start">
											<label>{{ FormMSG(181, 'Spouse has salary') }}</label>
											<b-form-checkbox class="d-flex justify-content-start" size="lg" v-model="myProfile.spouseHasSalary" switch>
												<span class="text-redired">{{ myProfile.spouseHasSalary ? FormMSG(1276, 'Yes') : FormMSG(1277, 'No') }}</span>
											</b-form-checkbox>
										</b-form-group>
										<b-form-group>
											<label>{{ FormMSG(179, 'Spouse profession') }}</label>
											<b-form-input
												v-model="myProfile.spouseProfession"
												type="text"
												:placeholder="FormMSG(180, 'Enter your Spouse profession ')"
												@keydown.enter.prevent
											/>
										</b-form-group>
									</div>
									<div class="mt-3">
										<b-form-group class="text-start">
											<label>{{ FormMSG(1192, 'Do you have children ?') }}</label>
											<b-form-checkbox class="d-flex justify-content-start" size="lg" v-model="haveChild" switch>
												<span class="text-redired">{{ haveChild ? FormMSG(1276, 'Yes') : FormMSG(1277, 'No') }}</span>
											</b-form-checkbox>
										</b-form-group>
									</div>
									<div class="card-gray" v-if="haveChild">
										<div class="d-flex flex-row align-items-center">
											<b-col cols="4" class="px-0 d-flex justify-content-start align-items-center">
												<b-button variant="light" class="little-btn mr-2 px-1" @click="decrementValue('numberOfDependentChild')">
													<component :is="getIconLucide('Minus')" color="#06263EA3" :size="16" />
												</b-button>
												<b-form-input :value="myProfile.numberOfDependentChild" class="little-input mt-2" @keydown.enter.prevent />
												<b-button variant="light" class="little-btn ml-2 px-1" @click="incrementValue('numberOfDependentChild')">
													<component :is="getIconLucide('Plus')" color="#06263EA3" :size="16" />
												</b-button>
											</b-col>
											<b-col cols="8" class="d-flex align-items-center" style="margin-top: 0.85rem">
												<p class="m-0 pl-2">{{ FormMSG(183, 'Number of dependent child') }}</p>
											</b-col>
										</div>
										<div class="mt-3 d-flex flex-row align-items-center">
											<b-col cols="4" class="px-0 d-flex justify-content-start align-items-center">
												<b-button variant="light" class="little-btn mr-2 px-1" @click="decrementValue('numberOfDisabledChild')">
													<component :is="getIconLucide('Minus')" color="#06263EA3" :size="16" />
												</b-button>
												<b-form-input :value="myProfile.numberOfDisabledChild" class="little-input mt-2" @keydown.enter.prevent />
												<b-button variant="light" class="little-btn ml-2 px-1" @click="incrementValue('numberOfDisabledChild')">
													<component :is="getIconLucide('Plus')" color="#06263EA3" :size="16" />
												</b-button>
											</b-col>
											<b-col cols="8" class="d-flex align-items-center" style="margin-top: 0.85rem">
												<p class="m-0 pl-2">{{ FormMSG(184, 'Number of disabled child') }}</p>
											</b-col>
										</div>
									</div>
									<div class="mt-3">
										<b-form-group class="text-start">
											<label>{{ FormMSG(1193, 'Do you have other dependents ?') }}</label>
											<b-form-checkbox class="d-flex justify-content-start" size="lg" v-model="haveDependents" switch>
												<span class="text-redired">{{ haveDependents ? FormMSG(1276, 'Yes') : FormMSG(1277, 'No') }}</span>
											</b-form-checkbox>
										</b-form-group>
									</div>
									<div class="card-gray" v-if="haveDependents">
										<div class="d-flex flex-row align-items-center">
											<b-col cols="4" class="px-0 d-flex justify-content-start align-items-center">
												<b-button variant="light" class="little-btn mr-2 px-1" @click="decrementValue('numberOfDependentPerson')">
													<component :is="getIconLucide('Minus')" color="#06263EA3" :size="16" />
												</b-button>
												<b-form-input :value="myProfile.numberOfDependentPerson" class="little-input mt-2" @keydown.enter.prevent />
												<b-button variant="light" class="little-btn ml-2 px-1" @click="incrementValue('numberOfDependentPerson')">
													<component :is="getIconLucide('Plus')" color="#06263EA3" :size="16" />
												</b-button>
											</b-col>
											<b-col cols="8" class="d-flex align-items-center" style="margin-top: 0.85rem">
												<p class="m-0 pl-2">{{ FormMSG(1221, 'Number of dependent persons') }}</p>
											</b-col>
										</div>
										<div class="mt-3 d-flex flex-row align-items-center">
											<b-col cols="4" class="px-0 d-flex justify-content-start align-items-center">
												<b-button variant="light" class="little-btn mr-2 px-1" @click="decrementValue('numberOfDisabledPerson')">
													<component :is="getIconLucide('Minus')" color="#06263EA3" :size="16" />
												</b-button>
												<b-form-input :value="myProfile.numberOfDisabledPerson" class="little-input mt-2" @keydown.enter.prevent />
												<b-button variant="light" class="little-btn ml-2 px-1" @click="incrementValue('numberOfDisabledPerson')">
													<component :is="getIconLucide('Plus')" color="#06263EA3" :size="16" />
												</b-button>
											</b-col>
											<b-col cols="8" class="d-flex align-items-center" style="margin-top: 0.85rem">
												<p class="m-0 pl-2">{{ FormMSG(1222, 'Number of disabled persons') }}</p>
											</b-col>
										</div>
									</div>
								</div>
								<div class="divider mr-4 ml-4"></div>
								<div class="personal-data">
									<h2>{{ FormMSG(1176, 'Emergency Contact') }}</h2>
									<b-form-group :label="FormMSG(1177, 'Contact Name')">
										<b-form-input
											v-model="myProfile.personToContact"
											:placeholder="FormMSG(1178, 'Enter Contact Name')"
											@keydown.enter.prevent
										/>
									</b-form-group>
									<b-form-group :label="FormMSG(1179, 'Contact Phone Number')">
										<b-form-input
											v-model="myProfile.personToContactPhone"
											:placeholder="FormMSG(1180, 'Enter Contact Phone Number')"
											@keydown.enter.prevent
										/>
									</b-form-group>
								</div>
								<div class="mt-4">&nbsp;</div>
							</div>
							<div v-if="menuActiveForMobile === 'personal-vehicle'">
								<div class="personal-data">
									<h2>{{ FormMSG(1226, 'Personal vehicle') }}</h2>
									<vehicule-form :vehicule-data="myProfile.vehicule" @vehicule-form:change="handleVehiculeFormChange" />
									<!-- <b-form-group :label="FormMSG(1228, 'Name')">
										<b-form-input
											v-model="myProfile.vehicule.name"
											type="text"
											:placeholder="FormMSG(1229, 'Vehicle name ...')"
											@keydown.enter.prevent
										/>
									</b-form-group>
									<b-form-group :label="FormMSG(1230, 'Brand')">
										<b-form-input
											v-model="myProfile.vehicule.brand"
											type="text"
											:placeholder="FormMSG(1231, 'Vehicle brand ...')"
											@keydown.enter.prevent
										/>
									</b-form-group>
									<b-form-group :label="FormMSG(1232, 'Model')">
										<b-form-input
											v-model="myProfile.vehicule.model"
											type="text"
											:placeholder="FormMSG(1233, 'Vehicle model ...')"
											@keydown.enter.prevent
										/>
									</b-form-group>
									<b-form-group :label="FormMSG(1234, 'Number plate')">
										<b-form-input
											v-model="myProfile.vehicule.numberPlate"
											type="text"
											:placeholder="FormMSG(1235, 'Your number plate ...')"
											@keydown.enter.prevent
										/>
									</b-form-group>
									<b-form-group :label="FormMSG(1236, 'Type')">										
										<b-form-select v-model="myProfile.vehicule.type" :options="vehicleTypes" />
									</b-form-group>
									<b-form-group :label="FormMSG(1237, 'Energy')">
										<b-form-select v-model="myProfile.vehicule.energy" :options="vehicleEnergies" />
									</b-form-group>
									<b-form-group>
										<label>{{ FormMSG(1238, 'Kg CO2 per') }} {{ distanceUnit }}</label>
										<b-form-input
											v-model="myProfile.vehicule.kgCoTwoPerKm"
											type="number"
											step="0.01"
											min="0"
											placeholder="0"
											@keydown.enter.prevent
										/>
									</b-form-group> -->
								</div>
							</div>
							<div v-if="menuActiveForMobile === 'other'">
								<div class="personal-data">
									<h2>{{ FormMSG(16, 'Other') }}</h2>
									<b-form-group class="text-start">
										<label>{{ FormMSG(18, 'Show my email in crew list') }}</label>
										<b-form-checkbox class="d-flex justify-content-start" size="lg" v-model="myProfile.showMyEmailInCrew" switch>
											<span class="text-redired">{{ myProfile.showMyEmailInCrew ? FormMSG(1276, 'Yes') : FormMSG(1277, 'No') }}</span>
										</b-form-checkbox>
									</b-form-group>
									<b-form-group class="text-start">
										<label>{{ FormMSG(19, 'Show my phone in crew list') }}</label>
										<b-form-checkbox class="d-flex justify-content-start" size="lg" v-model="myProfile.showMyPhoneInCrew" switch>
											<span class="text-redired">{{ myProfile.showMyPhoneInCrew ? FormMSG(1276, 'Yes') : FormMSG(1277, 'No') }}</span>
										</b-form-checkbox>
									</b-form-group>
								</div>
								<div class="divider mr-4 ml-4"></div>
								<div class="personal-data">
									<h2>{{ FormMSG(1280, 'Loyalty cards') }}</h2>
									<b-form-group>
										<template #label>
											<div>
												<span>
													{{ FormMSG(1281, 'SNCF loyalty card') }}
													<span class="text-fin">{{ FormMSG(1282, '(Type + number)') }}</span>
												</span>
											</div>
										</template>
										<b-form-input
											v-model="myProfile.trainLoyaltyCard"
											type="text"
											:placeholder="FormMSG(1283, 'Enter your sncf loyalty card')"
											@keydown.enter.prevent
										/>
									</b-form-group>
									<b-form-group>
										<template #label>
											<div>
												<span>
													{{ FormMSG(1284, 'SNCF discount card') }}
													<span class="text-fin">{{ FormMSG(1282, '(Type + number)') }}</span>
												</span>
											</div>
										</template>
										<b-form-input
											v-model="myProfile.trainDiscountCard"
											type="text"
											:placeholder="FormMSG(1285, 'Enter your sncf discount card')"
											@keydown.enter.prevent
										/>
									</b-form-group>
									<b-form-group>
										<template #label>
											<div>
												<span>
													{{ FormMSG(1286, 'Flying blue card') }}
													<span class="text-fin">{{ FormMSG(1287, '(Number)') }}</span>
												</span>
											</div>
										</template>
										<b-form-input
											v-model="myProfile.flyingLoyaltyCard"
											type="text"
											:placeholder="FormMSG(1288, 'Enter your flying blue card')"
											@keydown.enter.prevent
										/>
									</b-form-group>
									<b-form-group>
										<template #label>
											<div>
												<span>
													{{ FormMSG(1289, 'Other airline card') }}
													<span class="text-fin">{{ FormMSG(1287, '(Number)') }}</span>
												</span>
											</div>
										</template>
										<b-form-input
											v-model="myProfile.otherAirlineCard"
											type="text"
											:placeholder="FormMSG(1288, 'Enter your other airline card')"
											@keydown.enter.prevent
										/>
									</b-form-group>
								</div>
								<div class="divider mr-4 ml-4"></div>
								<div class="personal-data">
									<h2>
										<span>
											{{ FormMSG(1290, 'Foreign worker') }}
											<span class="text-fin">{{ FormMSG(1309, "(section to fill only if you're a foreign worker)") }}</span>
										</span>
									</h2>
									<b-form-group :label="FormMSG(1292, 'Residence permit number')">
										<b-form-input
											v-model="myProfile.residencePermitNumber"
											type="text"
											:placeholder="FormMSG(1293, 'Enter your residence permit number')"
											@keydown.enter.prevent
										/>
									</b-form-group>
									<b-form-group :label="FormMSG(1294, 'Residence permit validity end')">
										<b-form-input
											type="date"
											:value="displayDateResidencePermitEndValidity"
											:placeholder="FormMSG(1295, 'DD/MM/YYYY')"
											@input="(e) => updateDate(e, 'residencePermitEndValidity')"
											@keydown.enter.prevent
										/>
									</b-form-group>
									<b-row>
										<b-col cols="6">
											<b-form-group>
												<label for="residentPermitXid">{{ FormMSG(1296, 'Resident permit') }}</label>
												<b-row class="ml-0">
													<div
														@click="onOpenUrl(getImage(myProfile.residentPermitXid, 'residentPermitXid'))"
														class="d-flex justify-content-center align-items-center cursor-pointer"
														v-if="checkFile(myProfile.residentPermitXid)"
													>
														<b-icon icon="file-earmark-pdf-fill" :font-scale="1.6" />
													</div>
													<b-img
														v-else
														v-bind="imgPropSignatureId"
														rounded
														alt="residentPermitXid"
														class="cursor-pointer"
														:src="getImage(myProfile.residentPermitXid, 'residentPermitXid')"
														@click="showImage(myProfile.residentPermitXid)"
														:title="FormMSG(254, 'View Image')"
													></b-img>
													<div class="d-flex ml-3 justify-content-center align-items-center">
														<MobileProfilePicCaptureBtn
															parent-type="user"
															id="residentPermitXid"
															@click="changeFieldPic('residentPermitXid')"
															:parentId="myProfile.id"
															@change="handleImgsTaked"
														/>
														<button
															class="btn-transparent pl-0 ml-2"
															@click.prevent.stop="deleteImg('residentPermitXid')"
															v-if="myProfile.residentPermitXid && myProfile.residentPermitXid.length > 0"
														>
															<component :is="getIconLucide('Trash2')" :size="24" :stroke-width="1.5" color="#ea4e2c" />
														</button>
													</div>
												</b-row>
											</b-form-group>
										</b-col>
										<b-col cols="6">
											<b-form-group>
												<label for="workCardXid">{{ FormMSG(1297, 'Work card') }}</label>
												<b-row class="ml-0">
													<div
														@click="onOpenUrl(getImage(myProfile.workCardXid, 'workCardXid'))"
														class="d-flex justify-content-center align-items-center cursor-pointer"
														v-if="checkFile(myProfile.workCardXid)"
													>
														<b-icon icon="file-earmark-pdf-fill" :font-scale="1.6" />
													</div>
													<b-img
														v-else
														v-bind="imgPropSignatureId"
														rounded
														alt="workCardXid"
														class="cursor-pointer"
														:src="getImage(myProfile.workCardXid, 'workCardXid')"
														@click="showImage(myProfile.workCardXid)"
														:title="FormMSG(254, 'View Image')"
													></b-img>
													<div class="d-flex ml-3 justify-content-center align-items-center">
														<MobileProfilePicCaptureBtn
															parent-type="user"
															id="workCardXid"
															@click="changeFieldPic('workCardXid')"
															:parentId="myProfile.id"
															@change="handleImgsTaked"
														/>
														<button
															class="btn-transparent pl-0 ml-2"
															@click.prevent.stop="deleteImg('workCardXid')"
															v-if="myProfile.workCardXid && myProfile.workCardXid.length > 0"
														>
															<component :is="getIconLucide('Trash2')" :size="24" :stroke-width="1.5" color="#ea4e2c" />
														</button>
													</div>
												</b-row>
											</b-form-group>
										</b-col>
									</b-row>
								</div>
								<div class="divider mr-4 ml-4"></div>
								<div class="mt-1">&nbsp;</div>
								<div class="personal-data">
									<h2>{{ FormMSG(24, 'Miscellanous') }}</h2>
									<b-form-group :label="FormMSG(1298, 'Pseudonym')">
										<b-form-input
											v-model="myProfile.pseudonym"
											type="text"
											:placeholder="FormMSG(1299, 'Enter your pseudonym')"
											@keydown.enter.prevent
										/>
									</b-form-group>
									<b-form-group :label="FormMSG(1300, 'Generic mention professional association')">
										<b-form-input
											v-model="myProfile.professionalAssociation"
											type="text"
											:placeholder="FormMSG(1301, 'Enter your generic mention ...')"
											@keydown.enter.prevent
										/>
									</b-form-group>
									<div class="divider"></div>
									<div class="mt-3">
										<h2>{{ FormMSG(1705, 'Retirement') }}</h2>
										<b-form-group class="text-start">
											<label>{{ FormMSG(1275, 'Retired ?') }}</label>
											<b-form-checkbox class="d-flex justify-content-start" size="lg" v-model="myProfile.retired" :value="true" switch>
												<span class="text-redired">{{ myProfile.retired ? FormMSG(1276, 'Yes') : FormMSG(1277, 'No') }}</span>
											</b-form-checkbox>
										</b-form-group>
									</div>
									<div v-if="myProfile.retired" class="card-gray">
										<b-form-group>
											<label>{{ FormMSG(1278, 'Retirement date') }}</label>
											<b-form-input
												type="date"
												:value="displayDateRetirementDate"
												:placeholder="FormMSG(1295, 'DD/MM/YYYY')"
												@input="(e) => updateDate(e, 'retirementDate')"
												@keydown.enter.prevent
											/>
										</b-form-group>
									</div>
									<div>
										<div class="divider"></div>
										<div class="mt-1">&nbsp;</div>
										<h2>{{ FormMSG(1706, 'Study') }}</h2>
										<b-form-group :label="FormMSG(262, 'Study level')">
											<b-form-input
												v-model="myProfile.otherStudyLevel"
												type="text"
												:placeholder="FormMSG(266, 'Enter your study level ...')"
												@keydown.enter.prevent
											/>
										</b-form-group>
									</div>
									<div>
										<div class="divider"></div>
										<div class="mt-1">&nbsp;</div>
										<h2>{{ FormMSG(1707, 'Clothes') }}</h2>
										<b-form-group :label="FormMSG(263, 'Size')">
											<b-form-input
												v-model="myProfile.size"
												type="text"
												:placeholder="FormMSG(267, 'Enter your size ...')"
												@keydown.enter.prevent
											/>
										</b-form-group>
										<b-form-group :label="FormMSG(264, 'Pant size')">
											<b-form-input
												v-model="myProfile.pantSize"
												type="text"
												:placeholder="FormMSG(268, 'Enter your pant size ...')"
												@keydown.enter.prevent
											/>
										</b-form-group>
										<b-form-group :label="FormMSG(265, 'Jacket size')">
											<b-form-input
												v-model="myProfile.jacketSize"
												type="text"
												:placeholder="FormMSG(269, 'Enter your jacket size ...')"
												@keydown.enter.prevent
											/>
										</b-form-group>
										<b-form-group :label="FormMSG(27, 'Shoe size')">
											<b-form-input
												v-model="myProfile.shoeSize"
												type="text"
												:placeholder="FormMSG(28, 'Enter your shoe size ...')"
												@keydown.enter.prevent
											/>
										</b-form-group>
										<b-form-group :label="FormMSG(29, 'Teeshirt size')">
											<b-form-input
												v-model="myProfile.tShirtSize"
												type="text"
												:placeholder="FormMSG(30, 'Enter your teeshirt size ...')"
												@keydown.enter.prevent
											/>
										</b-form-group>
									</div>
								</div>
							</div>
							<div v-if="menuActiveForMobile === 'settings'">
								<div class="settings">
									<h2>{{ FormMSG(1198, 'Language') }}</h2>
									<b-form-group :label="FormMSG(1209, 'Select a communication language')">
										<b-form-select v-model="myProfile.language" :options="optionsForLanguages" />
									</b-form-group>
									<div class="divider" />
									<h2 class="mt-3">{{ FormMSG(22, 'Change Password') }}</h2>
									<p class="mt-3">
										{{
											FormMSG(
												107,
												"Make sure it's at least 15 characters OR at least 8 characters including a number and a lowercase letter."
											)
										}}
									</p>
									<b-button
										size="lg"
										variant="outline-primary"
										:disabled="statusSendCodeValidation.global"
										block
										@click="handleChangePassword"
									>
										<span v-if="!statusSendCodeValidation.progress && !statusSendCodeValidation.success">
											{{ FormMSG(22, 'Change Password') }}
										</span>
										<span v-if="statusSendCodeValidation.progress && !statusSendCodeValidation.success">
											<b-spinner label="Loading..." small /> {{ FormMSG(146, 'Send code validation') }}
										</span>
										<span v-if="!statusSendCodeValidation.progress && statusSendCodeValidation.success">
											{{ FormMSG(147, 'Code validation sended') }}
										</span>
									</b-button>
								</div>
							</div>
							<div v-if="menuActiveForMobile === 'team-session' && isDeterProject === true">
								<div class="settings">
									<p class="fw-400">
										{{ FormMSG(814, 'Here the list of sessions and teams') }}
									</p>
									<div>
										<b-table-simple id="deterSessionTeamTable">
											<b-thead head-variant="dark">
												<b-tr>
													<b-th></b-th>
													<b-th>{{ FormMSG(465, 'Team A') }}</b-th>
													<b-th>{{ FormMSG(466, 'Team B') }}</b-th>
												</b-tr>
											</b-thead>
											<b-tbody>
												<b-tr>
													<b-th>
														{{ FormMSG(457, 'Session 1') }}
													</b-th>
													<b-td>
														<b-form-checkbox v-model="myProfile.session1A" size="lg" :value="true" :unchecked-value="false" />
													</b-td>
													<b-td>
														<b-form-checkbox v-model="myProfile.session1B" size="lg" :value="true" :unchecked-value="false" />
													</b-td>
												</b-tr>
												<b-tr>
													<b-th>
														{{ FormMSG(458, 'Session 2') }}
													</b-th>
													<b-td>
														<b-form-checkbox v-model="myProfile.session2A" size="lg" :value="true" :unchecked-value="false" />
													</b-td>
													<b-td>
														<b-form-checkbox v-model="myProfile.session2B" size="lg" :value="true" :unchecked-value="false" />
													</b-td>
												</b-tr>
												<b-tr>
													<b-th>
														{{ FormMSG(459, 'Session 3') }}
													</b-th>
													<b-td>
														<b-form-checkbox v-model="myProfile.session3A" size="lg" :value="true" :unchecked-value="false" />
													</b-td>
													<b-td>
														<b-form-checkbox v-model="myProfile.session3B" size="lg" :value="true" :unchecked-value="false" />
													</b-td>
												</b-tr>
												<b-tr>
													<b-th>
														{{ FormMSG(460, 'Session 4') }}
													</b-th>
													<b-td>
														<b-form-checkbox v-model="myProfile.session4A" size="lg" :value="true" :unchecked-value="false" />
													</b-td>
													<b-td>
														<b-form-checkbox v-model="myProfile.session4B" size="lg" :value="true" :unchecked-value="false" />
													</b-td>
												</b-tr>
												<b-tr>
													<b-th>
														{{ FormMSG(461, 'Session 5') }}
													</b-th>
													<b-td>
														<b-form-checkbox v-model="myProfile.session5A" size="lg" :value="true" :unchecked-value="false" />
													</b-td>
													<b-td>
														<b-form-checkbox v-model="myProfile.session5B" size="lg" :value="true" :unchecked-value="false" />
													</b-td>
												</b-tr>
												<b-tr>
													<b-th>
														{{ FormMSG(462, 'Session 6') }}
													</b-th>
													<b-td>
														<b-form-checkbox v-model="myProfile.session6A" size="lg" :value="true" :unchecked-value="false" />
													</b-td>
													<b-td>
														<b-form-checkbox v-model="myProfile.session6B" size="lg" :value="true" :unchecked-value="false" />
													</b-td>
												</b-tr>
												<b-tr>
													<b-th>
														{{ FormMSG(463, 'Session 7') }}
													</b-th>
													<b-td>
														<b-form-checkbox v-model="myProfile.session7A" size="lg" :value="true" :unchecked-value="false" />
													</b-td>
													<b-td>
														<b-form-checkbox v-model="myProfile.session7B" size="lg" :value="true" :unchecked-value="false" />
													</b-td>
												</b-tr>
												<b-tr>
													<b-th>
														{{ FormMSG(464, 'Session 8') }}
													</b-th>
													<b-td>
														<b-form-checkbox v-model="myProfile.session8A" size="lg" :value="true" :unchecked-value="false" />
													</b-td>
													<b-td>
														<b-form-checkbox v-model="myProfile.session8B" size="lg" :value="true" :unchecked-value="false" />
													</b-td>
												</b-tr>
											</b-tbody>
										</b-table-simple>
									</div>
								</div>
							</div>
							<b-row v-if="menuActiveForMobile !== ''" class="footer-fixed">
								<b-col cols="12">
									<b-button variant="primary" type="submit" block style="font-size: 16px; font-weight: 700">
										{{ FormMSG(1210, 'Save Changes') }}
									</b-button>
								</b-col>
							</b-row>
						</form>
					</div>
					<loading :active.sync="isLoading" :is-full-page="true"></loading>
				</div>
			</div>
			<div v-else>
				<div class="animated fadeIn">
					<b-row>
						<!-- <b-col md="12">
							<h1 :class="[`${!$screen.sm ? 'main-page-title' : ''}`, { 'is-pwa': $isPwa() }]">{{ FormMSG(10, 'My Profile') }}</h1>
						</b-col> -->
						<div class="container-layout">
							<div class="card py-4">
								<div class="p-2" v-if="isDeterProject && isDeterAction">
									<b-alert variant="warning" show dismissible>
										<div class="d-flex align-items-center flex-row" style="gap: 10px">
											<div>
												<component :is="getIconLucide(ICONS.ALERT_TRIANGLE.name)" color="#ffc107" :size="22" />
											</div>
											<div>
												{{ FormMSG(1708, 'Dear') }} {{ userNameActif }}, {{ FormMSG(1709, 'Please add') }}
												<strong>{{ messageContext }}</strong>
												{{ FormMSG(1710, 'information in your profile. Thank you for using TheGreenShot services.') }}
											</div>
										</div>
									</b-alert>
								</div>
								<b-form @submit="submitForm">
									<!-- Personal data -->
									<div class="card-collapsible">
										<div
											class="cursor-pointer header-card-collapsible d-flex flex-row align-items-center"
											@click="handleClickHeader('personnal-data')"
										>
											<div class="title w-90">
												<b-icon icon="person-check-fill" style="color: rgba(6, 38, 62, 0.84)" /><span class="ml-3">{{
													FormMSG(3, 'Personal data')
												}}</span>
											</div>
											<div class="d-flex justify-content-end w-10">
												<component
													v-if="!configAccordion['personnal-data'].isVisible"
													:is="getIconLucide('ChevronDown')"
													color="#06263E"
													:size="22"
												/>
												<component
													v-if="configAccordion['personnal-data'].isVisible"
													:is="getIconLucide('ChevronUp')"
													color="#06263E"
													:size="22"
												/>
											</div>
										</div>
										<b-collapse :visible="configAccordion['personnal-data'].isVisible">
											<div class="content-card-collapsible">
												<b-row>
													<b-col sm="8" md="8" lg="9" xl="9">
														<b-row>
															<b-col sm="12" md="12" lg="4" xl="4">
																<b-form-group>
																	<label for="reference"> {{ this.FormMSG(1, 'Reference') }} </label>
																	<b-form-input
																		v-model="myProfile.reference"
																		type="text"
																		id="reference"
																		:placeholder="FormMSG(2, 'Enter your personal number.')"
																		@keydown.enter.prevent
																		disabled
																	></b-form-input>
																</b-form-group>
															</b-col>
															<b-col sm="12" md="12" lg="4" xl="4">
																<b-form-group :label="FormMSG(42, 'Email')">
																	<b-input-group>
																		<b-form-input
																			:value="myProfile.email"
																			type="text"
																			id="email"
																			:placeholder="FormMSG(1102, 'Enter your email')"
																			style="border-right: none"
																			@keydown.enter.prevent
																			disabled
																			:class="{ 'is-invalid': isSubmitted && $v.myProfile.email.$error }"
																		/>
																		<b-input-group-append>
																			<b-input-group-text
																				style="border-radius: 0; background-color: #d5d6d7"
																				v-if="myProfile.email && myProfile.email.length > 0"
																			>
																				<component :is="getIconLucide('Check')" color="#007A77" :size="16" />
																			</b-input-group-text>
																			<b-input-group-text
																				class="cursor-pointer"
																				@click="showChangeMailModal = true"
																				style="background-color: #00a09c"
																			>
																				<component :is="getIconLucide('Pencil')" color="#FFFFFF" :size="16" />
																			</b-input-group-text>
																		</b-input-group-append>
																		<div v-if="isSubmitted && !$v.myProfile.email.emailValid" class="invalid-feedback">
																			{{ FormMSG(252, 'Please, enter valid email') }}
																		</div>
																	</b-input-group>
																</b-form-group>
															</b-col>
															<b-col sm="12" md="12" lg="4" xl="4">
																<b-form-group :label="FormMSG(44, 'Phone')">
																	<b-input-group>
																		<b-form-input
																			:value="myProfile.phone"
																			type="text"
																			id="phone"
																			:placeholder="FormMSG(45, 'Enter your phone number')"
																			style="border-right: none"
																			@keydown.enter.prevent
																			disabled
																		/>
																		<b-input-group-append>
																			<b-input-group-text style="border-radius: 0; background-color: #d5d6d7">
																				<component :is="getIconLucide('Check')" color="#007A77" :size="16" />
																			</b-input-group-text>
																			<b-input-group-text
																				class="cursor-pointer"
																				@click="showChangePhoneModal = true"
																				style="background-color: #00a09c"
																			>
																				<component :is="getIconLucide('Pencil')" color="#FFFFFF" :size="16" />
																			</b-input-group-text>
																		</b-input-group-append>
																	</b-input-group>
																</b-form-group>
															</b-col>
														</b-row>
														<b-row>
															<b-col sm="12" md="12" lg="3" xl="3">
																<b-form-group>
																	<label for="firstname"> {{ FormMSG(41, 'First Name') }}</label>
																	<b-form-input
																		v-model="myProfile.firstName"
																		type="text"
																		id="firstname"
																		:placeholder="FormMSG(1101, 'Enter your first name.')"
																		:class="{ 'is-invalid': isSubmitted && $v.myProfile.firstName.$error }"
																		@keydown.enter.prevent
																	></b-form-input>
																	<div v-if="isSubmitted && !$v.myProfile.firstName.required" class="invalid-feedback">
																		{{ FormMSG(251, 'Please, length should be at least 2') }}
																	</div>
																</b-form-group>
															</b-col>
															<b-col sm="12" md="12" lg="3" xl="3">
																<b-form-group>
																	<label for="name"> {{ FormMSG(40, 'Last Name') }} </label>
																	<b-form-input
																		v-model="myProfile.name"
																		type="text"
																		id="name"
																		:placeholder="FormMSG(1100, 'Enter your name.')"
																		:class="{ 'is-invalid': isSubmitted && $v.myProfile.name.$error }"
																		@keydown.enter.prevent
																	></b-form-input>
																	<div v-if="isSubmitted && !$v.myProfile.name.required" class="invalid-feedback">
																		{{ FormMSG(251, 'Please, length should be at least 2') }}
																	</div>
																</b-form-group>
															</b-col>
															<b-col sm="12" md="12" lg="3" xl="3">
																<b-form-group>
																	<label for="maidenName">{{ FormMSG(1239, ' Maiden name (if different)') }}</label>
																	<b-form-input
																		v-model="myProfile.maidenName"
																		type="text"
																		id="maidenName"
																		@keydown.enter.prevent
																	></b-form-input>
																</b-form-group>
															</b-col>
															<b-col sm="12" md="12" lg="3" xl="3" class="d-flex justify-content-center">
																<b-form-group :label="FormMSG(525, 'Color')">
																	<div class="w-100 d-flex justify-content-start">
																		<verte v-model="myProfile.color" picker="square" model="hex" menuPosition="top" />
																	</div>
																</b-form-group>
															</b-col>
														</b-row>
														<b-row>
															<b-col sm="12" md="12" lg="3" xl="3">
																<b-form-group>
																	<label for="nationality">{{ FormMSG(46, 'Nationality') }}</label>
																	<b-form-input
																		v-model="myProfile.nationality"
																		type="text"
																		id="nationality"
																		:placeholder="this.FormMSG(47, 'Enter your nationality')"
																		@keydown.enter.prevent
																	></b-form-input>
																</b-form-group>
															</b-col>
															<b-col sm="12" md="12" lg="3" xl="3">
																<b-form-group :label="FormMSG(80, 'Langue')" label-for="optionsForLanguages">
																	<b-form-select
																		v-model="myProfile.language"
																		:options="optionsForLanguages"
																		id="optionsForLanguages"
																	></b-form-select>
																</b-form-group>
															</b-col>
															<b-col sm="12" md="12" lg="3" xl="3">
																<b-form-group :label="FormMSG(164, 'Gender')">
																	<b-form-select v-model="myProfile.gender">
																		<b-form-select-option
																			v-for="(gender, index) in menuGenders"
																			:key="index"
																			:value="gender.value"
																		>
																			{{ gender.text }}
																		</b-form-select-option>
																	</b-form-select>
																</b-form-group>
															</b-col>
															<b-col sm="12" md="12" lg="3" xl="3">
																<b-form-group :label="FormMSG(1240, 'Title')">
																	<b-form-select v-model="myProfile.title">
																		<b-form-select-option
																			v-for="(title, index) in menuTitle"
																			:key="index"
																			:value="title.value"
																		>
																			{{ title.text }}
																		</b-form-select-option>
																	</b-form-select>
																</b-form-group>
															</b-col>
														</b-row>
														<b-row>
															<b-col sm="12" md="12" lg="3" xl="3">
																<b-form-group :label="FormMSG(48, 'Birthdate')" label-for="birthDate">
																	<b-form-input
																		:value="displayBirthDate"
																		@input="updateBirthDate"
																		type="date"
																		id="birthDate"
																		@keydown.enter.prevent
																	/>
																</b-form-group>
															</b-col>
															<b-col sm="12" md="12" lg="3" xl="3">
																<b-form-group :label="FormMSG(159, 'Place of birth')" label-for="placeOfBirth">
																	<b-form-input
																		v-model="myProfile.placeOfBirth"
																		id="placeOfBirth"
																		type="text"
																		:placeholder="FormMSG(161, 'Place of birthstartCapture')"
																		@keydown.enter.prevent
																	/>
																</b-form-group>
															</b-col>
															<b-col sm="12" md="12" lg="3" xl="3">
																<b-form-group :label="FormMSG(160, 'Place of birth ZIP')" label-for="placeOfBirthZip">
																	<b-form-input
																		v-model="myProfile.placeOfBirthZip"
																		id="placeOfBirthZip"
																		type="text"
																		:placeholder="FormMSG(162, 'Place of birth zip')"
																		@keydown.enter.prevent
																	/>
																</b-form-group>
															</b-col>
															<b-col sm="12" md="12" lg="3" xl="3">
																<b-form-group :label="FormMSG(1241, 'Country of birth')">
																	<b-form-input
																		v-model="myProfile.birthCountry"
																		:placeholder="FormMSG(1315, 'Enter your country of ...')"
																		@keydown.enter.prevent
																	/>
																</b-form-group>
															</b-col>
														</b-row>
													</b-col>
													<b-col class="text-center" sm="4" md="4" lg="3" xl="3">
														<div
															class="profile-picture-block"
															style="display: flex; flex-direction: column; align-items: center; justify-content: center"
														>
															<b-form-group v-model="myProfile.picture">
																<div
																	v-if="myProfile.picture && myProfile.picture.length > 0"
																	style="position: relative; height: 100%; width: 100%"
																>
																	<b-img
																		v-bind="imgPropPicture"
																		rounded="circle"
																		alt="My picture"
																		class="cursor-pointer"
																		style="
																			display: flex;
																			justify-content: center;
																			align-items: center;
																			width: 175px;
																			height: 175px;
																			border-radius: 50%;
																			border: 1px solid #cad0d7;
																			background-color: #f5f7f9;
																		"
																		:src="getImage(myProfile.picture, 'picture')"
																		@click="showImage(myProfile.picture)"
																		:title="FormMSG(254, 'View Image')"
																	></b-img>
																	<component
																		:is="getIconLucide('XCircle')"
																		:size="32"
																		:stroke-width="1.75"
																		color="#ea4e2c"
																		class="cursor-pointer icon-remove-img-profile"
																		style="bottom: 85%; right: 10%"
																		id="tooltip-remove-picture"
																		@click="deleteImg('picture')"
																		v-if="myProfile.picture && myProfile.picture.length > 0"
																	/>
																	<b-tooltip
																		target="tooltip-remove-picture"
																		triggers="hover"
																		v-if="myProfile.picture && myProfile.picture.length > 0"
																	>
																		{{ FormMSG(255, 'Remove picture') }}
																	</b-tooltip>
																</div>
																<div
																	v-else
																	class="avatar"
																	style="
																		display: flex;
																		justify-content: center;
																		align-items: center;
																		width: 175px;
																		height: 175px;
																		border-radius: 50%;
																		border: 1px solid #cad0d7;
																		background-color: #f5f7f9;
																	"
																>
																	<component
																		:is="getIconLucide('User')"
																		fill="#E2E6EB"
																		color="#E2E6EB"
																		:size="120"
																		class="vertical-center"
																	/>
																</div>
															</b-form-group>
															<b-form-group>
																<b-button
																	v-if="!$isPwa()"
																	variant="outline-secondary"
																	size="md"
																	@click="startCapture('picture')"
																>
																	<i class="icon-camera"></i>
																	{{ FormMSG(43, 'Change your picture') }}
																</b-button>
																<MobileProfilePicCaptureBtn
																	v-if="$isPwa()"
																	crop-type="avatar"
																	:label-button="FormMSG(43, 'Change your picture')"
																	parent-type="user"
																	:parentId="myProfile.id"
																	@click="startCapture('picture')"
																	@change="handleImgsTaked"
																/>
															</b-form-group>
														</div>
														<ShareInformation
															v-if="isFilmSingle"
															:btn-label="FormMSG(169, 'Share my Information')"
															:title-modal="FormMSG(170, 'Send your personal informations sheet')"
															:msg-success="FormMSG(250, 'Profile successfully sent')"
															send-a-copy
														/>
													</b-col>
												</b-row>
											</div>
										</b-collapse>
									</div>
									<!-- Identification-->
									<div class="card-collapsible mt-3">
										<div
											class="cursor-pointer header-card-collapsible d-flex flex-row align-items-center"
											@click="handleClickHeader('identification')"
										>
											<div class="title w-90">
												<component :is="getLucideIcon('ClipboardList')" color="rgba(6, 38, 62, 0.84)" :size="22" />
												<span class="ml-3">{{ FormMSG(4, 'Identification') }}</span>
											</div>
											<div class="d-flex justify-content-end w-10">
												<component
													v-if="!configAccordion['identification'].isVisible"
													:is="getIconLucide('ChevronDown')"
													color="#06263E"
													:size="22"
												/>
												<component
													v-if="configAccordion['identification'].isVisible"
													:is="getIconLucide('ChevronUp')"
													color="#06263E"
													:size="22"
												/>
											</div>
										</div>
										<b-collapse :visible="configAccordion['identification'].isVisible">
											<div class="sub-title">
												{{ FormMSG(5, 'Identification Numbers') }}
											</div>
											<div class="content-card-collapsible">
												<b-row>
													<b-col sm="12" md="12" lg="3" xl="3">
														<b-form-group :label="FormMSG(55, 'National Registration Number')">
															<b-form-input
																v-model="myProfile.nationalRegistryNumber"
																:placeholder="FormMSG(56, 'Enter your National Registration Number ')"
																@keydown.enter.prevent
															/>
														</b-form-group>
													</b-col>
													<b-col sm="12" md="12" lg="3" xl="3">
														<b-form-group>
															<label for="companyNumber">{{ FormMSG(57, 'Company Number') }}</label>
															<b-form-input
																v-model="myProfile.companyNumber"
																type="text"
																id="companyNumber"
																:placeholder="FormMSG(58, 'Enter your Company official Number ')"
																@keydown.enter.prevent
															></b-form-input>
														</b-form-group>
													</b-col>
													<b-col sm="12" md="12" lg="3" xl="3">
														<b-form-group>
															<label for="conge_spectacles_id">{{ FormMSG(1242, '"Congés spectacles" ID') }}</label>
															<b-form-input
																v-model="myProfile.congesSpectacle"
																type="text"
																id="conge_spectacles_id"
																:placeholder="FormMSG(1243, 'Enter your conges spectacles id')"
																@keydown.enter.prevent
															/>
														</b-form-group>
													</b-col>
													<b-col sm="12" md="12" lg="3" xl="3">
														<b-form-group>
															<label for="signatureId">{{ FormMSG(223, 'My signature') }}</label>
															<b-row class="ml-0">
																<div
																	@click="onOpenUrl(getImage(myProfile.signatureId, 'signatureId'))"
																	class="d-flex justify-content-center align-items-center cursor-pointer"
																	v-if="checkFile(myProfile.signatureId)"
																>
																	<b-icon icon="file-earmark-pdf-fill" font-scale="2" />
																</div>
																<b-img
																	v-else
																	v-bind="imgPropSignatureId"
																	rounded="circle"
																	alt="My signature"
																	class="cursor-pointer"
																	:src="getImage(myProfile.signatureId, 'signatureId')"
																	@click="showImage(myProfile.signatureId)"
																	:title="FormMSG(254, 'View Image')"
																></b-img>
																<b-button variant="outline-secondary" class="ml-2" @click="startCapture('signatureId')">
																	<div class="d-flex justify-content-center align-items-center">
																		<component :is="getIconLucide('Camera')" :size="20" />
																		<div class="pl-2" style="margin-bottom: -1px">{{ FormMSG(220, 'Picture') }}</div>
																	</div>
																</b-button>
																<button
																	class="btn-transparent pl-0 ml-2"
																	@click.prevent.stop="deleteImg('signatureId')"
																	v-if="myProfile.signatureId && myProfile.signatureId.length > 0"
																>
																	<component :is="getIconLucide('Trash2')" :size="24" :stroke-width="1.5" color="#ea4e2c" />
																</button>
															</b-row>
														</b-form-group>
													</b-col>
												</b-row>
												<b-row>
													<b-col sm="12" md="12" lg="3" xl="3">
														<b-form-group>
															<label for="idNumber">{{ FormMSG(216, 'ID Name') }}</label>
															<b-form-input
																v-model="myProfile.idName"
																type="text"
																id="idNumber"
																:placeholder="FormMSG(217, 'Enter your ID Name ...')"
																@keydown.enter.prevent
															></b-form-input>
														</b-form-group>
													</b-col>
													<b-col sm="12" md="12" lg="3" xl="3">
														<b-form-group>
															<label for="idNumber">{{ FormMSG(7, 'ID Number') }}</label>
															<b-form-input
																v-model="myProfile.idNumber"
																type="text"
																id="idNumber"
																:placeholder="FormMSG(8, 'Enter your ID Number ...')"
																@keydown.enter.prevent
															></b-form-input>
														</b-form-group>
													</b-col>
													<b-col sm="12" md="12" lg="3" xl="3">
														<b-form-group>
															<label for="pictureId">{{ FormMSG(218, 'ID picture - Front') }}</label>
															<b-row class="ml-0">
																<div
																	@click="onOpenUrl(getImage(myProfile.pictureId, 'pictureId'))"
																	class="d-flex justify-content-center align-items-center cursor-pointer"
																	v-if="checkFile(myProfile.pictureId)"
																>
																	<b-icon icon="file-earmark-pdf-fill" font-scale="2" />
																</div>
																<b-img
																	v-else
																	v-bind="imgPropPictureId"
																	rounded="circle"
																	alt="ID picture - front"
																	class="cursor-pointer"
																	:src="getImage(myProfile.pictureId, 'pictureId')"
																	@click="showImage(myProfile.pictureId)"
																	:title="FormMSG(254, 'View Image')"
																></b-img>
																<b-button variant="outline-secondary" class="ml-2" @click="startCapture('pictureId')">
																	<div class="d-flex justify-content-center align-items-center">
																		<component :is="getIconLucide('Camera')" :size="20" />
																		<div class="pl-2" style="margin-bottom: -1px">{{ FormMSG(220, 'Picture') }}</div>
																	</div>
																</b-button>
																<button
																	class="btn-transparent pl-0 ml-2"
																	@click.prevent.stop="deleteImg('pictureId')"
																	v-if="myProfile.pictureId && myProfile.pictureId.length > 0"
																>
																	<component :is="getIconLucide('Trash2')" :size="24" :stroke-width="1.5" color="#ea4e2c" />
																</button>
															</b-row>
														</b-form-group>
													</b-col>
													<b-col sm="12" md="12" lg="3" xl="3">
														<b-form-group>
															<label for="pictureIdVerso">{{ FormMSG(219, 'ID picture - Back') }}</label>
															<b-row class="ml-0">
																<div
																	@click="onOpenUrl(getImage(myProfile.pictureIdVerso, 'pictureIdVerso'))"
																	class="d-flex justify-content-center align-items-center cursor-pointer"
																	v-if="checkFile(myProfile.pictureIdVerso)"
																>
																	<b-icon icon="file-earmark-pdf-fill" font-scale="2" />
																</div>
																<b-img
																	v-else
																	v-bind="imgPropPictureIdVerso"
																	rounded="circle"
																	alt="ID picture - back"
																	class="cursor-pointer"
																	:src="getImage(myProfile.pictureIdVerso, 'pictureIdVerso')"
																	@click="showImage(myProfile.pictureIdVerso)"
																	:title="FormMSG(254, 'View Image')"
																></b-img>
																<b-button variant="outline-secondary" class="ml-2" @click="startCapture('pictureIdVerso')">
																	<div class="d-flex justify-content-center align-items-center">
																		<component :is="getIconLucide('Camera')" :size="20" />
																		<div class="pl-2" style="margin-bottom: -1px">{{ FormMSG(220, 'Picture') }}</div>
																	</div>
																</b-button>
																<button
																	class="btn-transparent pl-0 ml-2"
																	@click.prevent.stop="deleteImg('pictureIdVerso')"
																	v-if="myProfile.pictureIdVerso && myProfile.pictureIdVerso.length > 0"
																>
																	<component :is="getIconLucide('Trash2')" :size="24" :stroke-width="1.5" color="#ea4e2c" />
																</button>
															</b-row>
														</b-form-group>
													</b-col>
												</b-row>
												<b-row>
													<b-col sm="12" md="12" lg="3" xl="3">
														<b-form-group>
															<label>{{ FormMSG(166, 'Driver licence') }}</label>
															<b-form-input
																v-model="myProfile.driverLicense"
																type="text"
																:placeholder="FormMSG(167, 'Enter your driver license')"
																@keydown.enter.prevent
															/>
														</b-form-group>
													</b-col>
													<b-col sm="12" md="12" lg="3" xl="3">
														<b-form-group>
															<label>{{ FormMSG(1314, 'Driver licence Number') }}</label>
															<b-form-input
																v-model="myProfile.driverLicenseNumber"
																type="text"
																:placeholder="FormMSG(1316, 'Enter your driver license number')"
																@keydown.enter.prevent
															/>
														</b-form-group>
													</b-col>
													<b-col sm="12" md="12" lg="3" xl="3">
														<b-form-group>
															<label for="pictureDriverLicense">{{ FormMSG(221, 'Driver license picture - Front') }}</label>
															<b-row class="ml-0">
																<div
																	@click="onOpenUrl(getImage(myProfile.pictureDriverLicense, 'pictureDriverLicense'))"
																	class="d-flex justify-content-center align-items-center cursor-pointer"
																	v-if="checkFile(myProfile.pictureDriverLicense)"
																>
																	<b-icon icon="file-earmark-pdf-fill" font-scale="2" />
																</div>
																<b-img
																	v-else
																	v-bind="imgPropPictureDriverLicense"
																	rounded
																	alt="Driver license picture - Front"
																	class="cursor-pointer"
																	:src="getImage(myProfile.pictureDriverLicense, 'pictureDriverLicense')"
																	@click="showImage(myProfile.pictureDriverLicense)"
																	:title="FormMSG(254, 'View Image')"
																></b-img>
																<b-button
																	variant="outline-secondary"
																	class="ml-2"
																	@click="startCapture('pictureDriverLicense')"
																>
																	<div class="d-flex justify-content-center align-items-center">
																		<component :is="getIconLucide('Camera')" :size="20" />
																		<div class="pl-2" style="margin-bottom: -1px">{{ FormMSG(220, 'Picture') }}</div>
																	</div>
																</b-button>
																<button
																	class="btn-transparent pl-0 ml-2"
																	@click.prevent.stop="deleteImg('pictureDriverLicense')"
																	v-if="myProfile.pictureDriverLicense && myProfile.pictureDriverLicense.length > 0"
																>
																	<component :is="getIconLucide('Trash2')" :size="24" :stroke-width="1.5" color="#ea4e2c" />
																</button>
															</b-row>
														</b-form-group>
													</b-col>
													<b-col sm="12" md="12" lg="3" xl="3">
														<b-form-group>
															<label for="idNumber">{{ FormMSG(222, 'Driver license picture - Back') }}</label>
															<b-row class="ml-0">
																<div
																	@click="
																		onOpenUrl(getImage(myProfile.pictureDriverLicenseVerso, 'pictureDriverLicenseVerso'))
																	"
																	class="d-flex justify-content-center align-items-center cursor-pointer"
																	v-if="checkFile(myProfile.pictureDriverLicenseVerso)"
																>
																	<b-icon icon="file-earmark-pdf-fill" font-scale="2" />
																</div>
																<b-img
																	v-else
																	v-bind="imgPropPictureDriverLicenseVerso"
																	rounded
																	alt="Driver license picture - Back"
																	class="cursor-pointer"
																	:src="getImage(myProfile.pictureDriverLicenseVerso, 'pictureDriverLicenseVerso')"
																	@click="showImage(myProfile.pictureDriverLicenseVerso)"
																	:title="FormMSG(254, 'View Image')"
																></b-img>
																<b-button
																	variant="outline-secondary"
																	class="ml-2"
																	@click="startCapture('pictureDriverLicenseVerso')"
																>
																	<div class="d-flex justify-content-center align-items-center">
																		<component :is="getIconLucide('Camera')" :size="20" />
																		<div class="pl-2" style="margin-bottom: -1px">{{ FormMSG(220, 'Picture') }}</div>
																	</div>
																</b-button>
																<button
																	class="btn-transparent pl-0 ml-2"
																	@click.prevent.stop="deleteImg('pictureDriverLicenseVerso')"
																	v-if="myProfile.pictureDriverLicenseVerso && myProfile.pictureDriverLicenseVerso.length > 0"
																>
																	<component :is="getIconLucide('Trash2')" :size="24" :stroke-width="1.5" color="#ea4e2c" />
																</button>
															</b-row>
														</b-form-group>
													</b-col>
												</b-row>
											</div>
										</b-collapse>
									</div>
									<!--Finance-->
									<div class="card-collapsible mt-3">
										<div
											class="cursor-pointer header-card-collapsible d-flex flex-row align-items-center"
											@click="handleClickHeader('finance')"
										>
											<div class="title w-90">
												<component :is="getLucideIcon('Wallet')" color="rgba(6, 38, 62, 0.84)" :size="22" />
												<span class="ml-3">{{ FormMSG(1244, 'Finance') }}</span>
											</div>
											<div class="d-flex justify-content-end w-10">
												<component
													v-if="!configAccordion['finance'].isVisible"
													:is="getIconLucide('ChevronDown')"
													color="#06263E"
													:size="22"
												/>
												<component
													v-if="configAccordion['finance'].isVisible"
													:is="getIconLucide('ChevronUp')"
													color="#06263E"
													:size="22"
												/>
											</div>
										</div>
										<b-collapse :visible="configAccordion['finance'].isVisible">
											<div class="sub-title">
												{{ FormMSG(9, 'Bank information') }}
											</div>
											<div class="content-card-collapsible">
												<b-row>
													<b-col sm="12" md="12" lg="6" xl="6">
														<b-form-group
															label-for="iban"
															:label="FormMSG(49, 'IBAN')"
															:class="{ 'is-warning': userBankWarning.iban }"
														>
															<b-form-input
																v-model="myProfile.iban"
																type="text"
																id="iban"
																:placeholder="FormMSG(50, 'Enter your bank account number')"
																@input="handleSupplierIbanChange"
																@keydown.enter.prevent
															></b-form-input>
															<div v-if="userBankWarning.iban" class="warning-feedback">
																{{ FormMSG(97986, "IBAN seems not valid, please make sure it's corrrect") }}
															</div>
														</b-form-group>
													</b-col>
													<b-col sm="12" md="12" lg="6" xl="6">
														<b-form-group :class="{ 'is-warning': userBankWarning.bic }">
															<label for="bic">{{ FormMSG(51, 'BIC') }}</label>
															<b-form-input
																v-model="myProfile.bic"
																type="text"
																id="bic"
																:placeholder="FormMSG(52, 'Enter your bank BIC')"
																@input="handleSupplierBicChange"
																@keydown.enter.prevent
															></b-form-input>
															<div v-if="userBankWarning.bic" class="warning-feedback">
																{{ FormMSG(909097986, "BIC seems not valid, please make sure it's corrrect") }}
															</div>
														</b-form-group>
													</b-col>
												</b-row>
												<b-row>
													<b-col sm="12" md="12" lg="6" xl="6">
														<b-form-group>
															<label for="bic">{{ this.FormMSG(1245, 'Bank account owner name') }}</label>
															<b-form-input
																v-model="myProfile.bankAccountOwnerName"
																type="text"
																id="bic"
																:placeholder="this.FormMSG(1246, 'Enter your bank account owner name')"
																@keydown.enter.prevent
															></b-form-input>
														</b-form-group>
													</b-col>
													<b-col sm="12" md="12" lg="6" xl="6">
														<b-form-group>
															<label for="bankDetailDocument">{{ FormMSG(1247, 'Bank detail document') }}</label>
															<b-row class="ml-0">
																<div
																	@click="onOpenUrl(getImage(myProfile.bankDetailXid, 'bankDetailXid'))"
																	class="d-flex justify-content-center align-items-center cursor-pointer"
																	v-if="checkFile(myProfile.bankDetailXid)"
																>
																	<b-icon icon="file-earmark-pdf-fill" font-scale="2" />
																</div>
																<b-img
																	v-else
																	v-bind="imgPropSignatureId"
																	v-model="myProfile.bankDetailXid"
																	rounded
																	alt="Bank detail document"
																	class="cursor-pointer"
																	:src="getImage(myProfile.bankDetailXid, 'bankDetailXid')"
																	@click="showImage(myProfile.bankDetailXid)"
																	:title="FormMSG(254, 'View Image')"
																></b-img>
																<b-button variant="outline-secondary" class="ml-2" @click="startCapture('bankDetailXid')">
																	<div class="d-flex justify-content-center align-items-center">
																		<component :is="getIconLucide('Camera')" :size="20" />
																		<div class="pl-2" style="margin-bottom: -1px">{{ FormMSG(220, 'Picture') }}</div>
																	</div>
																</b-button>
																<button
																	class="btn-transparent pl-0 ml-2"
																	@click.prevent.stop="deleteImg('bankDetailXid')"
																	v-if="myProfile.bankDetailXid && myProfile.bankDetailXid.length > 0"
																>
																	<component :is="getIconLucide('Trash2')" :size="24" :stroke-width="1.5" color="#ea4e2c" />
																</button>
																&nbsp;
																<div
																	class="d-flex align-items-center"
																	v-if="isDeterAction && myProfile.bankDetailXid.length === 0"
																>
																	<b-button
																		size="sm"
																		class="btn bg-transparent border-0"
																		v-b-tooltip.hover.bottom.html="FormMSG(1750, 'Bank detail document is not downloaded')"
																	>
																		<component :is="getIconLucide(ICONS.ALERT_TRIANGLE.name)" color="#ffc107" :size="30" />
																	</b-button>
																</div>
															</b-row>
														</b-form-group>
													</b-col>
												</b-row>
											</div>
											<div class="divider"></div>
											<div class="sub-title">
												{{ FormMSG(260, 'Taxes') }}
											</div>
											<div class="content-card-collapsible">
												<b-row>
													<b-col sm="12" md="12" lg="3" xl="3">
														<b-form-group :label="FormMSG(261, 'Taxe')">
															<b-input-group>
																<b-form-input
																	v-model="myProfile.taxesPercentage"
																	type="number"
																	step="1"
																	min="0"
																	max="100"
																	v-limit-100
																	@keydown.enter.prevent
																/>
																<b-input-group-append>
																	<b-input-group-text style="background-color: #00a09c">
																		<component
																			:is="getIconLucide('Percent')"
																			color="#ffffff"
																			:size="16"
																			:stroke-width="3.5"
																		/>
																	</b-input-group-text>
																</b-input-group-append>
															</b-input-group>
														</b-form-group>
													</b-col>
													<b-col sm="12" md="12" lg="3" xl="3">
														<b-form-group>
															<label for="bic">{{ this.FormMSG(1248, 'Tax declaration country') }}</label>
															<b-form-input
																v-model="myProfile.taxCountry"
																type="text"
																id="taxCountry"
																:placeholder="this.FormMSG(1249, 'Enter your tax declaration country')"
																@keydown.enter.prevent
															></b-form-input>
														</b-form-group>
													</b-col>
												</b-row>
											</div>
										</b-collapse>
									</div>
									<!-- Address-->
									<div class="card-collapsible mt-3">
										<div
											class="cursor-pointer header-card-collapsible d-flex flex-row align-items-center"
											@click="handleClickHeader('address')"
										>
											<div class="title w-90">
												<b-icon icon="geo-fill" style="color: rgba(6, 38, 62, 0.84)" /><span class="ml-3">{{
													FormMSG(11, 'Address')
												}}</span>
											</div>
											<div class="d-flex justify-content-end w-10">
												<component
													v-if="!configAccordion['address'].isVisible"
													:is="getIconLucide('ChevronDown')"
													color="#06263E"
													:size="22"
												/>
												<component
													v-if="configAccordion['address'].isVisible"
													:is="getIconLucide('ChevronUp')"
													color="#06263E"
													:size="22"
												/>
											</div>
										</div>
										<b-collapse :visible="configAccordion['address'].isVisible" v-if="myProfile.address">
											<div class="sub-title">
												{{ FormMSG(1250, 'Legal address') }}
											</div>
											<div class="content-card-collapsible">
												<b-row>
													<b-col sm="12" md="12" lg="6" xl="6">
														<b-form-group>
															<label for="country">{{ FormMSG(106, 'Country') }}</label>
															<v-select
																:clearable="false"
																v-model="countryKey"
																:options="countries"
																label="text"
																:reduce="(option) => option.value"
																@input="getCountry"
															/>
														</b-form-group>
													</b-col>
													<b-col sm="12" md="12" lg="6" xl="6">
														<b-form-group>
															<google-auto-complete
																ref="google-search-address"
																:label="FormMSG(12, 'Internet search')"
																:country="countryCode"
																@placechanged="(item) => handleAutoComplete(item, 'legalAddress')"
															/>
														</b-form-group>
													</b-col>
												</b-row>
												<b-row>
													<b-col sm="10" md="10" lg="4" xl="4">
														<b-form-group>
															<label for="address">{{ this.FormMSG(100, 'Street') }}</label>
															<b-form-input
																v-model="myProfile.address.street"
																type="text"
																id="street"
																:placeholder="FormMSG(1103, 'Enter your street')"
																@keydown.enter.prevent
															></b-form-input>
														</b-form-group>
													</b-col>
													<b-col sm="2" md="2" lg="4" xl="4">
														<b-form-group>
															<label for="number">{{ this.FormMSG(101, 'Number') }}</label>
															<b-form-input
																v-model="myProfile.address.number"
																type="text"
																id="number"
																@keydown.enter.prevent
															></b-form-input>
														</b-form-group>
													</b-col>
													<b-col sm="12" md="12" lg="4" xl="4">
														<b-form-group>
															<label for="box">{{ this.FormMSG(102, 'Box') }}</label>
															<b-form-input
																v-model="myProfile.address.box"
																type="text"
																id="box"
																@keydown.enter.prevent
															></b-form-input>
														</b-form-group>
													</b-col>
												</b-row>
												<b-row>
													<b-col sm="12" md="12" lg="4" xl="4">
														<b-form-group>
															<label for="zip">{{ this.FormMSG(104, 'Postal Code') }}</label>
															<b-form-input
																v-model="myProfile.address.zip"
																type="text"
																id="zip"
																@keydown.enter.prevent
															></b-form-input>
														</b-form-group>
													</b-col>
													<b-col sm="12" md="12" lg="4" xl="4">
														<b-form-group>
															<label for="city">{{ this.FormMSG(103, 'City') }}</label>
															<b-form-input
																v-model="myProfile.address.city"
																type="text"
																id="city"
																@keydown.enter.prevent
															></b-form-input>
														</b-form-group>
													</b-col>
													<b-col sm="12" md="12" lg="4" xl="4">
														<b-form-group>
															<label for="state">{{ FormMSG(105, 'State') }}</label>
															<b-form-input
																v-model="myProfile.address.state"
																type="text"
																id="state"
																@keydown.enter.prevent
															></b-form-input>
														</b-form-group>
													</b-col>
												</b-row>
											</div>
											<b-collapse v-model="isShowPostalAddress">
												<div class="sub-title">
													{{ FormMSG(1312, 'Postal address') }}
												</div>
												<div class="content-card-collapsible" v-if="myProfile.postalAddress">
													<b-row>
														<b-col sm="12" md="12" lg="6" xl="6">
															<b-form-group>
																<label for="country">{{ FormMSG(106, 'Country') }}</label>
																<v-select
																	:clearable="false"
																	v-model="countryKeyPostalAddress"
																	:options="countries"
																	label="text"
																	:reduce="(option) => option.value"
																	@input="getCountryPostalAddress"
																/>
															</b-form-group>
														</b-col>
														<b-col sm="12" md="12" lg="6" xl="6">
															<b-form-group>
																<google-auto-complete
																	ref="google-search-address"
																	:label="FormMSG(12, 'Internet search')"
																	:country="countryCodePostalAddress"
																	@placechanged="(item) => handleAutoComplete(item, 'postalAddress')"
																/>
															</b-form-group>
														</b-col>
													</b-row>
													<b-row>
														<b-col sm="10" md="10" lg="4" xl="4">
															<b-form-group>
																<label for="address">{{ this.FormMSG(100, 'Street') }}</label>
																<b-form-input
																	v-model="myProfile.postalAddress.street"
																	type="text"
																	id="street"
																	:placeholder="FormMSG(1103, 'Enter your street')"
																	@keydown.enter.prevent
																></b-form-input>
															</b-form-group>
														</b-col>
														<b-col sm="2" md="2" lg="4" xl="4">
															<b-form-group>
																<label for="number">{{ this.FormMSG(101, 'Number') }}</label>
																<b-form-input
																	v-model="myProfile.postalAddress.number"
																	type="text"
																	id="number"
																	@keydown.enter.prevent
																></b-form-input>
															</b-form-group>
														</b-col>

														<b-col sm="12" md="12" lg="4" xl="4">
															<b-form-group>
																<label for="box">{{ this.FormMSG(102, 'Box') }}</label>
																<b-form-input
																	v-model="myProfile.postalAddress.box"
																	type="text"
																	id="box"
																	@keydown.enter.prevent
																></b-form-input>
															</b-form-group>
														</b-col>
													</b-row>
													<b-row>
														<b-col sm="12" md="12" lg="4" xl="4">
															<b-form-group>
																<label for="zip">{{ this.FormMSG(104, 'Postal Code') }}</label>
																<b-form-input
																	v-model="myProfile.postalAddress.zip"
																	type="text"
																	id="zip"
																	@keydown.enter.prevent
																></b-form-input>
															</b-form-group>
														</b-col>
														<b-col sm="12" md="12" lg="4" xl="4">
															<b-form-group>
																<label for="city">{{ this.FormMSG(103, 'City') }}</label>
																<b-form-input
																	v-model="myProfile.postalAddress.city"
																	type="text"
																	id="city"
																	@keydown.enter.prevent
																></b-form-input>
															</b-form-group>
														</b-col>
														<b-col sm="12" md="12" lg="4" xl="4">
															<b-form-group>
																<label for="state">{{ FormMSG(105, 'State') }}</label>
																<b-form-input
																	v-model="myProfile.postalAddress.state"
																	type="text"
																	id="state"
																	@keydown.enter.prevent
																></b-form-input>
															</b-form-group>
														</b-col>
													</b-row>
												</div>
											</b-collapse>
											<b-row>
												<div style="margin-left: 28px">
													<b-button
														size="sm"
														:variant="isShowPostalAddress ? 'outline-danger' : 'outline-secondary'"
														class="d-flex flex-row justify-content-center gap-2 align-items-center w-auto h-auto"
														style="height: 40px; border-radius: 0.5rem; background-color: #fff; cursor: pointer"
														@click="isShowPostalAddress = !isShowPostalAddress"
													>
														<div
															class="d-flex flex-row align-items-center justify-content-center btn-address"
															style="color: #06263e"
														>
															<span v-if="isShowPostalAddress">
																<component :is="getLucideIcon(ICONS.X_CLEAR.name)" :color="ICONS.X_CLEAR.color" :size="22" />
																{{ FormMSG(1313, 'Close') }}
															</span>
															<span v-else>
																<component :is="getLucideIcon('PlusCircle')" color="#47C7BF" :size="22" />
																{{ FormMSG(1251, 'Add postal address (if different)') }}
															</span>
														</div>
													</b-button>
												</div>
											</b-row>
										</b-collapse>
									</div>
									<!--Health-->
									<div class="card-collapsible mt-3">
										<div
											class="cursor-pointer header-card-collapsible d-flex flex-row align-items-center"
											@click="handleClickHeader('health')"
										>
											<div class="title w-90">
												<component :is="getLucideIcon('HeartPulse')" color="rgba(6, 38, 62, 0.84)" :size="22" />
												<span class="ml-3">{{ FormMSG(1252, 'Health') }}</span>
											</div>
											<div class="d-flex justify-content-end w-10">
												<component
													v-if="!configAccordion['health'].isVisible"
													:is="getIconLucide('ChevronDown')"
													color="#06263E"
													:size="22"
												/>
												<component
													v-if="configAccordion['health'].isVisible"
													:is="getIconLucide('ChevronUp')"
													color="#06263E"
													:size="22"
												/>
											</div>
										</div>
										<b-collapse :visible="configAccordion['health'].isVisible">
											<div class="content-card-collapsible">
												<b-row>
													<b-col sm="12" md="12" lg="4" xl="4">
														<b-form-group :label="FormMSG(54, 'Social Security Number')">
															<b-form-input
																v-model="myProfile.socSecNumber"
																:class="{ 'is-warning': isSocSecNumberWarning }"
																:placeholder="FormMSG(154, 'Enter your Social Security Number')"
																@input="handleSocSecNumberChange"
																@keydown.enter.prevent
															/>
															<div v-if="isSocSecNumberWarning" class="warning-feedback">
																{{ FormMSG(8979997, 'The social security number seems not valid') }}
															</div>
														</b-form-group>
													</b-col>
													<b-col sm="12" md="12" lg="4" xl="4">
														<b-form-group>
															<label for="socialSecurityCardXid">{{ FormMSG(1255, 'Social security card') }}</label>
															<b-row class="ml-0">
																<div
																	@click="onOpenUrl(getImage(myProfile.socialSecurityCardXid, 'socialSecurityCardXid'))"
																	class="d-flex justify-content-center align-items-center cursor-pointer"
																	v-if="checkFile(myProfile.socialSecurityCardXid)"
																>
																	<b-icon icon="file-earmark-pdf-fill" font-scale="2" />
																</div>
																<b-img
																	v-else
																	v-bind="imgPropSignatureId"
																	rounded
																	alt="socialSecurityCardXid"
																	class="cursor-pointer"
																	:src="getImage(myProfile.socialSecurityCardXid, 'socialSecurityCardXid')"
																	@click="showImage(myProfile.socialSecurityCardXid)"
																	:title="FormMSG(254, 'View Image')"
																></b-img>
																<b-button
																	variant="outline-secondary"
																	class="ml-2"
																	@click="startCapture('socialSecurityCardXid')"
																>
																	<div class="d-flex justify-content-center align-items-center">
																		<component :is="getIconLucide('Camera')" :size="20" />
																		<div class="pl-2" style="margin-bottom: -1px">{{ FormMSG(220, 'Picture') }}</div>
																	</div>
																</b-button>
																<button
																	class="btn-transparent pl-0 ml-2"
																	@click.prevent.stop="deleteImg('socialSecurityCardXid')"
																	v-if="myProfile.socialSecurityCardXid && myProfile.socialSecurityCardXid.length > 0"
																>
																	<component :is="getIconLucide('Trash2')" :size="24" :stroke-width="1.5" color="#ea4e2c" />
																</button>
															</b-row>
														</b-form-group>
													</b-col>
													<b-col sm="12" md="12" lg="4" xl="4">
														<b-form-group>
															<label for="inscuranceCertificate">{{ FormMSG(1256, 'Insurance certificate') }}</label>
															<b-row class="ml-0">
																<div
																	@click="onOpenUrl(getImage(myProfile.insuranceCertificateXid, 'insuranceCertificateXid'))"
																	class="d-flex justify-content-center align-items-center cursor-pointer"
																	v-if="checkFile(myProfile.insuranceCertificateXid)"
																>
																	<b-icon icon="file-earmark-pdf-fill" font-scale="2" />
																</div>
																<b-img
																	v-else
																	v-bind="imgPropSignatureId"
																	rounded
																	alt="inscuranceCertificate"
																	class="cursor-pointer"
																	:src="getImage(myProfile.insuranceCertificateXid, 'insuranceCertificateXid')"
																	@click="showImage(myProfile.insuranceCertificateXid)"
																	:title="FormMSG(254, 'View Image')"
																></b-img>
																<b-button
																	variant="outline-secondary"
																	class="ml-2"
																	@click="startCapture('insuranceCertificateXid')"
																>
																	<div class="d-flex justify-content-center align-items-center">
																		<component :is="getIconLucide('Camera')" :size="20" />
																		<div class="pl-2" style="margin-bottom: -1px">{{ FormMSG(220, 'Picture') }}</div>
																	</div>
																</b-button>
																<button
																	class="btn-transparent pl-0 ml-2"
																	@click.prevent.stop="deleteImg('insuranceCertificateXid')"
																	v-if="myProfile.insuranceCertificateXid && myProfile.insuranceCertificateXid.length > 0"
																>
																	<component :is="getIconLucide('Trash2')" :size="24" :stroke-width="1.5" color="#ea4e2c" />
																</button>
															</b-row>
														</b-form-group>
													</b-col>
												</b-row>
												<b-row>
													<b-col sm="12" md="12" lg="4" xl="4">
														<b-form-group>
															<label for="socialSecurityCenter">{{ this.FormMSG(1700, 'Social security center') }}</label>
															<b-form-input
																v-model="myProfile.socialSecurityCenter"
																type="text"
																id="socialSecurityCenter"
																:placeholder="this.FormMSG(1258, 'Enter your social security center')"
																@keydown.enter.prevent
															></b-form-input>
														</b-form-group>
													</b-col>
													<b-col sm="12" md="12" lg="8" xl="8">
														<b-form-group :label="FormMSG(1259, 'Social security center address')">
															<v-address
																:params="{ title: 'Social security center address' }"
																:address-id="myProfile.socialSecurityCenterAddressId"
																@change="handleDynamicAddressChange"
																@delete="handleDynamicAddressDelete"
																@address-maplink-change="handleAddressMapLinkChange"
															/>
														</b-form-group>
													</b-col>
												</b-row>
												<b-row>
													<b-col sm="12" md="12" lg="3" xl="3">
														<b-form-group>
															<label>{{ FormMSG(1260, 'Last medical visit date') }}</label>
															<b-form-input
																type="date"
																:value="displayDateLastMedicalVisit"
																:placeholder="FormMSG(1295, 'DD/MM/YYYY')"
																@input="(e) => updateDate(e, 'lastMedicalVisit')"
																@keydown.enter.prevent
															/>
														</b-form-group>
													</b-col>
													<b-col sm="12" md="12" lg="3" xl="3">
														<b-form-group>
															<label>{{ FormMSG(1262, 'Medical visit validity') }}</label>
															<b-form-input
																type="date"
																:value="displayDateMedicalVisitValidity"
																:placeholder="FormMSG(1295, 'DD/MM/YYYY')"
																@input="(e) => updateDate(e, 'medicalVisitValidity')"
																@keydown.enter.prevent
															/>
														</b-form-group>
													</b-col>
													<b-col sm="12" md="12" lg="3" xl="3">
														<b-form-group>
															<label for="medicalVisitDocument">{{ FormMSG(1264, 'Medical visit document') }}</label>
															<b-row class="ml-0">
																<div
																	@click="onOpenUrl(getImage(myProfile.medicalVisitDocumentXid, 'medicalVisitDocumentXid'))"
																	class="d-flex justify-content-center align-items-center cursor-pointer"
																	v-if="checkFile(myProfile.medicalVisitDocumentXid)"
																>
																	<b-icon icon="file-earmark-pdf-fill" font-scale="2" />
																</div>
																<b-img
																	v-else
																	v-bind="imgPropSignatureId"
																	v-model="myProfile.medicalVisitDocumentXid"
																	rounded
																	alt="medicalVisitDocument"
																	class="cursor-pointer"
																	:src="getImage(myProfile.medicalVisitDocumentXid, 'medicalVisitDocumentXid')"
																	@click="showImage(myProfile.medicalVisitDocumentXid)"
																	:title="FormMSG(254, 'View Image')"
																></b-img>
																<b-button
																	variant="outline-secondary"
																	class="ml-2"
																	@click="startCapture('medicalVisitDocumentXid')"
																>
																	<div class="d-flex justify-content-center align-items-center">
																		<component :is="getIconLucide('Camera')" :size="20" />
																		<div class="pl-2" style="margin-bottom: -1px">{{ FormMSG(220, 'Picture') }}</div>
																	</div>
																</b-button>
																<button
																	class="btn-transparent pl-0 ml-2"
																	@click.prevent.stop="deleteImg('medicalVisitDocumentXid')"
																	v-if="myProfile.medicalVisitDocumentXid && myProfile.medicalVisitDocumentXid.length > 0"
																>
																	<component :is="getIconLucide('Trash2')" :size="24" :stroke-width="1.5" color="#ea4e2c" />
																</button>
																&nbsp;
																<div
																	class="d-flex align-items-center"
																	v-if="isDeterAction && myProfile.medicalVisitDocumentXid.length === 0"
																>
																	<b-button
																		size="sm"
																		class="btn bg-transparent border-0"
																		v-b-tooltip.hover.bottom.html="
																			FormMSG(1751, 'Medical visit document is not downloaded')
																		"
																	>
																		<component :is="getIconLucide(ICONS.ALERT_TRIANGLE.name)" color="#ffc107" :size="30" />
																	</b-button>
																</div>
															</b-row>
														</b-form-group>
													</b-col>
												</b-row>
												<b-row>
													<b-col sm="12" md="12" lg="3" xl="3" class="d-flex justify-content-start">
														<b-form-group class="text-start">
															<label>{{ FormMSG(1265, 'Worker with a disability') }}</label>
															<b-form-checkbox class="d-flex justify-content-start" size="lg" v-model="myProfile.disabled" switch>
																<span class="text-redired">{{
																	myProfile.disabled ? FormMSG(1276, 'Yes') : FormMSG(1277, 'No')
																}}</span>
															</b-form-checkbox>
														</b-form-group>
													</b-col>
													<b-col v-if="myProfile.disabled" sm="12" md="12" lg="3" xl="3">
														<b-form-group>
															<label>{{ FormMSG(1266, 'Disability notification date') }}</label>
															<b-form-input
																type="date"
																:value="displayDateDisabilityNotification"
																:placeholder="FormMSG(1295, 'DD/MM/YYYY')"
																@input="(e) => updateDate(e, 'disabilityNotification')"
																@keydown.enter.prevent
															/>
														</b-form-group>
													</b-col>
													<b-col v-if="myProfile.disabled" sm="12" md="12" lg="3" xl="3">
														<b-form-group>
															<label for="type">{{ this.FormMSG(1268, 'Type') }}</label>
															<b-form-input
																v-model="myProfile.disabilityType"
																type="text"
																id="type"
																:placeholder="this.FormMSG(1269, 'Enter type')"
																@keydown.enter.prevent
															></b-form-input>
														</b-form-group>
													</b-col>
													<b-col v-if="myProfile.disabled" sm="12" md="12" lg="3" xl="3">
														<b-form-group>
															<label for="status">{{ this.FormMSG(1270, 'Status') }}</label>
															<b-form-input
																v-model="myProfile.disabilityStatus"
																type="text"
																id="status"
																:placeholder="this.FormMSG(1271, 'Enter status')"
																@keydown.enter.prevent
															></b-form-input>
														</b-form-group>
													</b-col>
												</b-row>
												<b-row>
													<b-col v-if="myProfile.disabled" sm="12" md="12" lg="3" xl="3">
														<b-form-group :label="FormMSG(1272, 'Invalidity')">
															<b-form-select v-model="myProfile.invalidity">
																<b-form-select-option
																	v-for="(gender, index) in menuInvalidity"
																	:key="index"
																	:value="gender.value"
																>
																	{{ gender.text }}
																</b-form-select-option>
															</b-form-select>
														</b-form-group>
													</b-col>
													<b-col v-if="myProfile.disabled" sm="12" md="12" lg="3" xl="3" class="d-flex align-items-center mt-2">
														<b-form-checkbox v-model="myProfile.rqth" size="lg" :value="true" class="d-flex align-items-center">
															<div class="mt-1">{{ FormMSG(1273, 'RQTH') }}</div>
														</b-form-checkbox>
													</b-col>
													<b-col v-if="myProfile.disabled" sm="12" md="12" lg="3" xl="3">
														<b-form-group :label="FormMSG(1274, 'Invalidity percentage')">
															<b-input-group>
																<b-form-input
																	v-model="myProfile.invalidityPourcentage"
																	type="number"
																	step="1"
																	min="0"
																	max="100"
																	v-limit-100
																	@keydown.enter.prevent
																/>
																<b-input-group-append>
																	<b-input-group-text style="background-color: #00a09c">
																		<component
																			:is="getIconLucide('Percent')"
																			color="#ffffff"
																			:size="16"
																			:stroke-width="3.5"
																		/>
																	</b-input-group-text>
																</b-input-group-append>
															</b-input-group>
														</b-form-group>
													</b-col>
												</b-row>
												<b-row>
													<b-col sm="12" md="12" lg="3" xl="3">
														<b-form-group>
															<label>{{ FormMSG(25, 'Have Allergies?') }}</label>
															<b-form-checkbox size="lg" v-model="haveAllergies" @change="handleChangeHaveAllergies" switch />
														</b-form-group>
													</b-col>
													<b-col sm="12" md="12" lg="3" xl="3">
														<b-form-group>
															<label>{{ FormMSG(26, 'Allergies details') }}</label>
															<b-form-textarea
																v-model="myProfile.allergies"
																type="textarea"
																rows="2"
																:placeholder="FormMSG(6, 'Enter allergies details...')"
																autocomplete="off"
																:disabled="haveAllergies === false"
															/>
														</b-form-group>
													</b-col>
												</b-row>
											</div>
										</b-collapse>
									</div>
									<!-- Family -->
									<div class="card-collapsible mt-3">
										<div
											class="cursor-pointer header-card-collapsible d-flex flex-row align-items-center"
											@click="handleClickHeader('family')"
										>
											<div class="title w-90">
												<b-icon icon="people-fill" style="color: rgba(6, 38, 62, 0.84)" />
												<span class="ml-3">{{ FormMSG(13, 'Family') }}</span>
											</div>
											<div class="d-flex justify-content-end w-10">
												<component
													v-if="!configAccordion['family'].isVisible"
													:is="getIconLucide('ChevronDown')"
													color="#06263E"
													:size="22"
												/>
												<component
													v-if="configAccordion['family'].isVisible"
													:is="getIconLucide('ChevronUp')"
													color="#06263E"
													:size="22"
												/>
											</div>
										</div>
										<b-collapse :visible="configAccordion['family'].isVisible">
											<div class="sub-title">
												{{ FormMSG(14, 'Family composition') }}
											</div>
											<div class="content-card-collapsible">
												<b-row>
													<b-col sm="12" md="12" lg="4" xl="4">
														<b-form-group :label="FormMSG(53, 'Civil status')" label-for="optionsForCivilStatus">
															<b-form-select
																v-model="myProfile.civilStatus"
																:options="optionsForCivilStatus"
																id="optionsForCivilStatus"
															></b-form-select>
														</b-form-group>
													</b-col>
													<b-col v-if="myProfile.civilStatus === 3" sm="12" md="12" lg="4" xl="4">
														<b-form-group>
															<label>{{ FormMSG(174, 'Spouse first name') }}</label>
															<b-form-input
																v-model="myProfile.spouseFirstName"
																type="text"
																:placeholder="FormMSG(176, 'Enter your Spouse first name ')"
																@keydown.enter.prevent
															/>
														</b-form-group>
													</b-col>
													<b-col v-if="myProfile.civilStatus === 3" sm="12" md="12" lg="4" xl="4">
														<b-form-group>
															<label>{{ FormMSG(172, 'Spouse name') }}</label>
															<b-form-input
																v-model="myProfile.spouseName"
																type="text"
																:placeholder="FormMSG(173, 'Enter your Spouse name ')"
																@keydown.enter.prevent
															/>
														</b-form-group>
													</b-col>
												</b-row>
												<b-row v-if="myProfile.civilStatus === 3">
													<b-col sm="12" md="12" lg="4" xl="4">
														<b-form-group>
															<label>{{ FormMSG(177, 'Spouse birth date') }}</label>
															<b-form-input
																type="date"
																:value="displaySpouseBirthDate"
																:placeholder="FormMSG(178, 'Enter your Spouse birth date ')"
																@input="updateSpouseBirthDate"
																@keydown.enter.prevent
															/>
														</b-form-group>
													</b-col>
													<b-col sm="12" md="12" lg="4" xl="4">
														<b-form-group class="text-start">
															<label>{{ FormMSG(181, 'Spouse has salary') }}</label>
															<b-form-checkbox
																class="d-flex justify-content-start"
																size="lg"
																v-model="myProfile.spouseHasSalary"
																switch
															>
																<span class="text-redired">{{
																	myProfile.spouseHasSalary ? FormMSG(1276, 'Yes') : FormMSG(1277, 'No')
																}}</span>
															</b-form-checkbox>
														</b-form-group>
													</b-col>
													<b-col sm="12" md="12" lg="4" xl="4">
														<b-form-group>
															<label>{{ FormMSG(179, 'Spouse profession') }}</label>
															<b-form-input
																v-model="myProfile.spouseProfession"
																type="text"
																:placeholder="FormMSG(180, 'Enter your Spouse profession ')"
																@keydown.enter.prevent
															/>
														</b-form-group>
													</b-col>
												</b-row>
												<b-row>
													<b-col sm="12" md="12" lg="3" xl="3">
														<b-form-group :label="FormMSG(183, 'Number of dependent child')">
															<b-form-input
																v-model="myProfile.numberOfDependentChild"
																type="number"
																placeholder="0"
																@keydown.enter.prevent
															/>
														</b-form-group>
													</b-col>
													<b-col sm="12" md="12" lg="3" xl="3">
														<b-form-group :label="FormMSG(184, 'Number of disabled child')">
															<b-form-input
																v-model="myProfile.numberOfDisabledChild"
																type="number"
																placeholder="0"
																@keydown.enter.prevent
															/>
														</b-form-group>
													</b-col>
													<b-col sm="12" md="12" lg="3" xl="3">
														<b-form-group :label="FormMSG(185, 'Number of dependent person')">
															<b-form-input
																v-model="myProfile.numberOfDependentPerson"
																type="number"
																placeholder="0"
																@keydown.enter.prevent
															/>
														</b-form-group>
													</b-col>
													<b-col sm="12" md="12" lg="3" xl="3">
														<b-form-group :label="FormMSG(186, 'Number of disabled person')">
															<b-form-input
																v-model="myProfile.numberOfDisabledPerson"
																type="number"
																placeholder="0"
																@keydown.enter.prevent
															/>
														</b-form-group>
													</b-col>
												</b-row>
											</div>
											<div class="divider" />
											<div class="sub-title">
												{{ FormMSG(15, 'Emergency contact') }}
											</div>
											<div class="content-card-collapsible">
												<b-row>
													<b-col sm="12" md="12" lg="6" xl="6">
														<b-form-group :label="FormMSG(188, 'Person to contact')">
															<b-form-input
																v-model="myProfile.personToContact"
																type="text"
																:placeholder="FormMSG(189, 'Enter person to contact')"
																@keydown.enter.prevent
															/>
														</b-form-group>
													</b-col>
													<b-col sm="12" md="12" lg="6" xl="6">
														<b-form-group :label="FormMSG(190, 'Person to contact phone')">
															<b-form-input
																v-model="myProfile.personToContactPhone"
																type="text"
																:placeholder="FormMSG(191, 'Enter person to contact phone')"
																@keydown.enter.prevent
															/>
														</b-form-group>
													</b-col>
												</b-row>
											</div>
										</b-collapse>
									</div>
									<!-- Personal vehicle -->
									<!--									<div class="card-collapsible mt-3" v-if="isPme && myProfile.vehicule">-->
									<div class="card-collapsible mt-3" v-if="myProfile.vehicule">
										<div
											class="cursor-pointer header-card-collapsible d-flex flex-row align-items-center"
											@click="handleClickHeader('personal-vehicle')"
										>
											<div class="title w-90">
												<!-- <b-icon icon="car-front-fill" style="color: rgba(6, 38, 62, 0.84)" /> -->
												<!-- <i class="bi bi-car-front"></i> -->
												<span>
													<svg
														xmlns="http://www.w3.org/2000/svg"
														width="1em"
														height="1.15em"
														fill="rgba(6, 38, 62, 0.84)"
														viewBox="0 0 16 16"
													>
														<path
															d="M2.52 3.515A2.5 2.5 0 0 1 4.82 2h6.362c1 0 1.904.596 2.298 1.515l.792 1.848c.075.175.21.319.38.404.5.25.855.715.965 1.262l.335 1.679c.033.161.049.325.049.49v.413c0 .814-.39 1.543-1 1.997V13.5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-1.338c-1.292.048-2.745.088-4 .088s-2.708-.04-4-.088V13.5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-1.892c-.61-.454-1-1.183-1-1.997v-.413a2.5 2.5 0 0 1 .049-.49l.335-1.68c.11-.546.465-1.012.964-1.261a.807.807 0 0 0 .381-.404l.792-1.848ZM3 10a1 1 0 1 0 0-2 1 1 0 0 0 0 2Zm10 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2ZM6 8a1 1 0 0 0 0 2h4a1 1 0 1 0 0-2H6ZM2.906 5.189a.51.51 0 0 0 .497.731c.91-.073 3.35-.17 4.597-.17 1.247 0 3.688.097 4.597.17a.51.51 0 0 0 .497-.731l-.956-1.913A.5.5 0 0 0 11.691 3H4.309a.5.5 0 0 0-.447.276L2.906 5.19Z"
														/>
													</svg>
												</span>
												<span class="ml-3">{{ FormMSG(1226, 'Personal vehicle') }}</span>
											</div>
											<div class="d-flex justify-content-end w-10">
												<component
													v-if="!configAccordion['personal-vehicle'].isVisible"
													:is="getIconLucide('ChevronDown')"
													color="#06263E"
													:size="22"
												/>
												<component
													v-if="configAccordion['personal-vehicle'].isVisible"
													:is="getIconLucide('ChevronUp')"
													color="#06263E"
													:size="22"
												/>
											</div>
										</div>
										<b-collapse :visible="configAccordion['personal-vehicle'].isVisible">
											<div class="content-card-collapsible">
												<vehicule-form :vehicule-data="myProfile.vehicule" @vehicule-form:change="handleVehiculeFormChange" />
											</div>
										</b-collapse>
									</div>
									<!-- Other -->
									<div class="card-collapsible mt-3">
										<div
											class="cursor-pointer header-card-collapsible d-flex flex-row align-items-center"
											@click="handleClickHeader('other')"
										>
											<div class="title w-90">
												<b-icon icon="list" style="color: rgba(6, 38, 62, 0.84)" /><span class="ml-3">{{ FormMSG(16, 'Other') }}</span>
											</div>
											<div class="d-flex justify-content-end w-10">
												<component
													v-if="!configAccordion['other'].isVisible"
													:is="getIconLucide('ChevronDown')"
													color="#06263E"
													:size="22"
												/>
												<component
													v-if="configAccordion['other'].isVisible"
													:is="getIconLucide('ChevronUp')"
													color="#06263E"
													:size="22"
												/>
											</div>
										</div>
										<b-collapse :visible="configAccordion['other'].isVisible">
											<div class="sub-title">
												{{ FormMSG(17, 'Privacy options') }}
											</div>
											<div class="content-card-collapsible">
												<b-row>
													<b-col sm="12" md="12" lg="3" xl="3">
														<b-form-checkbox size="lg" class="pj-cb pb-1" v-model="myProfile.showMyEmailInCrew">{{
															FormMSG(18, 'Show my email in crew list')
														}}</b-form-checkbox>
													</b-col>
													<b-col sm="12" md="12" lg="3" xl="3">
														<b-form-checkbox size="lg" class="pj-cb pb-1" v-model="myProfile.showMyPhoneInCrew">{{
															FormMSG(19, 'Show my phone in crew list')
														}}</b-form-checkbox>
													</b-col>
												</b-row>
											</div>
											<div class="divider"></div>
											<div class="sub-title">
												{{ FormMSG(1280, 'Loyalty cards') }}
											</div>
											<div class="content-card-collapsible">
												<b-row>
													<b-col sm="12" md="12" lg="3" xl="3">
														<b-form-group>
															<template #label>
																<div>
																	<span>
																		{{ FormMSG(1281, 'SNCF loyalty card') }}
																		<span class="text-fin">{{ FormMSG(1282, '(Type + number)') }}</span>
																	</span>
																</div>
															</template>
															<b-form-input
																v-model="myProfile.trainLoyaltyCard"
																type="text"
																:placeholder="FormMSG(1283, 'Enter your sncf loyalty card')"
																@keydown.enter.prevent
															/>
														</b-form-group>
													</b-col>
													<b-col sm="12" md="12" lg="3" xl="3">
														<b-form-group>
															<template #label>
																<div>
																	<span>
																		{{ FormMSG(1284, 'SNCF discount card') }}
																		<span class="text-fin">{{ FormMSG(1282, '(Type + number)') }}</span>
																	</span>
																</div>
															</template>
															<b-form-input
																v-model="myProfile.trainDiscountCard"
																type="text"
																:placeholder="FormMSG(1285, 'Enter your sncf discount card')"
																@keydown.enter.prevent
															/>
														</b-form-group>
													</b-col>
													<b-col sm="12" md="12" lg="3" xl="3">
														<b-form-group>
															<template #label>
																<div>
																	<span>
																		{{ FormMSG(1286, 'Flying blue card') }}
																		<span class="text-fin">{{ FormMSG(1287, '(Number)') }}</span>
																	</span>
																</div>
															</template>
															<b-form-input
																v-model="myProfile.flyingLoyaltyCard"
																type="text"
																:placeholder="FormMSG(1288, 'Enter your flying blue card')"
																@keydown.enter.prevent
															/>
														</b-form-group>
													</b-col>
													<b-col sm="12" md="12" lg="3" xl="3">
														<b-form-group>
															<template #label>
																<div>
																	<span>
																		{{ FormMSG(1289, 'Other airline card') }}
																		<span class="text-fin">{{ FormMSG(1287, '(Number)') }}</span>
																	</span>
																</div>
															</template>
															<b-form-input
																v-model="myProfile.otherAirlineCard"
																type="text"
																:placeholder="FormMSG(1288, 'Enter your other airline card')"
																@keydown.enter.prevent
															/>
														</b-form-group>
													</b-col>
												</b-row>
											</div>
											<div class="divider"></div>
											<div class="sub-title">
												{{ FormMSG(1290, 'Foreign worker')
												}}<span class="text-fin">{{ FormMSG(1291, "(to fill only if you're a foraign worker)") }}</span>
											</div>
											<div class="content-card-collapsible">
												<b-row>
													<b-col sm="12" md="12" lg="3" xl="3">
														<b-form-group :label="FormMSG(1292, 'Residence permit number')">
															<b-form-input
																v-model="myProfile.residencePermitNumber"
																type="text"
																:placeholder="FormMSG(1293, 'Enter your residence permit number')"
																@keydown.enter.prevent
															/>
														</b-form-group>
													</b-col>
													<b-col sm="12" md="12" lg="3" xl="3">
														<b-form-group :label="FormMSG(1294, 'Residence permit validity end')">
															<b-form-input
																type="date"
																:value="displayDateResidencePermitEndValidity"
																:placeholder="FormMSG(1295, 'DD/MM/YYYY')"
																@input="(e) => updateDate(e, 'residencePermitEndValidity')"
																@keydown.enter.prevent
															/>
														</b-form-group>
													</b-col>
													<b-col sm="12" md="12" lg="3" xl="3">
														<b-form-group>
															<label for="residentPermitXid">{{ FormMSG(1296, 'Resident permit') }}</label>
															<b-row class="ml-0">
																<div
																	@click="onOpenUrl(getImage(myProfile.residentPermitXid, 'residentPermitXid'))"
																	class="d-flex justify-content-center align-items-center cursor-pointer"
																	v-if="checkFile(myProfile.residentPermitXid)"
																>
																	<b-icon icon="file-earmark-pdf-fill" font-scale="2" />
																</div>
																<b-img
																	v-else
																	v-bind="imgPropSignatureId"
																	rounded
																	alt="residentPermitXid"
																	class="cursor-pointer"
																	:src="getImage(myProfile.residentPermitXid, 'residentPermitXid')"
																	@click="showImage(myProfile.residentPermitXid)"
																	:title="FormMSG(254, 'View Image')"
																></b-img>
																<b-button variant="outline-secondary" class="ml-2" @click="startCapture('residentPermitXid')">
																	<div class="d-flex justify-content-center align-items-center">
																		<component :is="getIconLucide('Camera')" :size="20" />
																		<div class="pl-2" style="margin-bottom: -1px">{{ FormMSG(220, 'Picture') }}</div>
																	</div>
																</b-button>
																<button
																	class="btn-transparent pl-0 ml-2"
																	@click.prevent.stop="deleteImg('residentPermitXid')"
																	v-if="myProfile.residentPermitXid && myProfile.residentPermitXid.length > 0"
																>
																	<component :is="getIconLucide('Trash2')" :size="24" :stroke-width="1.5" color="#ea4e2c" />
																</button>
															</b-row>
														</b-form-group>
													</b-col>
													<b-col sm="12" md="12" lg="3" xl="3">
														<b-form-group>
															<label for="workCardXid">{{ FormMSG(1297, 'Work card') }}</label>
															<b-row class="ml-0">
																<div
																	@click="onOpenUrl(getImage(myProfile.workCardXid, 'workCardXid'))"
																	class="d-flex justify-content-center align-items-center cursor-pointer"
																	v-if="checkFile(myProfile.workCardXid)"
																>
																	<b-icon icon="file-earmark-pdf-fill" font-scale="2" />
																</div>
																<b-img
																	v-else
																	v-bind="imgPropSignatureId"
																	rounded
																	alt="workCardXid"
																	class="cursor-pointer"
																	:src="getImage(myProfile.workCardXid, 'workCardXid')"
																	@click="showImage(myProfile.workCardXid)"
																	:title="FormMSG(254, 'View Image')"
																></b-img>
																<b-button variant="outline-secondary" class="ml-2" @click="startCapture('workCardXid')">
																	<div class="d-flex justify-content-center align-items-center">
																		<component :is="getIconLucide('Camera')" :size="20" />
																		<div class="pl-2" style="margin-bottom: -1px">{{ FormMSG(220, 'Picture') }}</div>
																	</div>
																</b-button>
																<button
																	class="btn-transparent pl-0 ml-2"
																	@click.prevent.stop="deleteImg('workCardXid')"
																	v-if="myProfile.workCardXid && myProfile.workCardXid.length > 0"
																>
																	<component :is="getIconLucide('Trash2')" :size="24" :stroke-width="1.5" color="#ea4e2c" />
																</button>
															</b-row>
														</b-form-group>
													</b-col>
												</b-row>
											</div>
											<div class="divider"></div>
											<div class="sub-title">
												{{ FormMSG(24, 'Miscellanous') }}
											</div>
											<div class="content-card-collapsible">
												<b-row>
													<b-col sm="12" md="12" lg="3" xl="3">
														<b-form-group :label="FormMSG(1298, 'Pseudonym')">
															<b-form-input
																v-model="myProfile.pseudonym"
																type="text"
																:placeholder="FormMSG(1299, 'Enter your pseudonym')"
																@keydown.enter.prevent
															/>
														</b-form-group>
													</b-col>
													<b-col sm="12" md="12" lg="4" xl="4">
														<b-form-group :label="FormMSG(1300, 'Generic mention professional association')">
															<b-form-input
																v-model="myProfile.professionalAssociation"
																type="text"
																:placeholder="FormMSG(1301, 'Enter your generic mention ...')"
																@keydown.enter.prevent
															/>
														</b-form-group>
													</b-col>
												</b-row>
											</div>
											<div class="divider"></div>
											<div class="sub-title">{{ FormMSG(1705, 'Retirement') }}</div>
											<div class="content-card-collapsible">
												<b-row>
													<b-col sm="12" md="12" lg="3" xl="3" class="d-flex justify-content-start">
														<b-form-group class="text-center">
															<label>{{ FormMSG(1275, 'Retired ?') }}</label>
															<b-form-checkbox
																class="d-flex justify-content-start"
																size="lg"
																v-model="myProfile.retired"
																:value="true"
																switch
															>
																<span class="text-redired">{{
																	myProfile.retired ? FormMSG(1276, 'Yes') : FormMSG(1277, 'No')
																}}</span>
															</b-form-checkbox>
														</b-form-group>
													</b-col>
													<b-col v-if="myProfile.retired" sm="12" md="12" lg="3" xl="3">
														<b-form-group>
															<label>{{ FormMSG(1278, 'Retirement date') }}</label>
															<b-form-input
																type="date"
																:value="displayDateRetirementDate"
																:placeholder="FormMSG(1295, 'DD/MM/YYYY')"
																@input="(e) => updateDate(e, 'retirementDate')"
																@keydown.enter.prevent
															/>
														</b-form-group>
													</b-col>
												</b-row>
											</div>
											<div class="divider"></div>
											<div class="sub-title">{{ FormMSG(1706, 'Study') }}</div>
											<div class="content-card-collapsible">
												<b-row>
													<b-col sm="12" md="12" lg="3" xl="3">
														<b-form-group :label="FormMSG(262, 'Study level')">
															<b-form-input
																v-model="myProfile.otherStudyLevel"
																type="text"
																:placeholder="FormMSG(266, 'Enter your study level ...')"
																@keydown.enter.prevent
															/>
														</b-form-group>
													</b-col>
												</b-row>
											</div>
											<div class="divider"></div>
											<div class="sub-title">{{ FormMSG(1707, 'Clothes') }}</div>
											<div class="content-card-collapsible">
												<b-row>
													<b-col sm="12" md="12" lg="3" xl="3">
														<b-form-group :label="FormMSG(263, 'Size')">
															<b-form-input
																v-model="myProfile.size"
																type="text"
																:placeholder="FormMSG(267, 'Enter your size ...')"
																@keydown.enter.prevent
															/>
														</b-form-group>
													</b-col>
													<b-col sm="12" md="12" lg="3" xl="3">
														<b-form-group :label="FormMSG(264, 'Pant size')">
															<b-form-input
																v-model="myProfile.pantSize"
																type="text"
																:placeholder="FormMSG(268, 'Enter your pant size ...')"
																@keydown.enter.prevent
															/>
														</b-form-group>
													</b-col>
													<b-col sm="12" md="12" lg="3" xl="3">
														<b-form-group :label="FormMSG(265, 'Jacket size')">
															<b-form-input
																v-model="myProfile.jacketSize"
																type="text"
																:placeholder="FormMSG(269, 'Enter your jacket size ...')"
																@keydown.enter.prevent
															/>
														</b-form-group>
													</b-col>
												</b-row>
												<b-row>
													<b-col sm="12" md="12" lg="3" xl="3">
														<b-form-group :label="FormMSG(27, 'Shoe size')">
															<b-form-input
																v-model="myProfile.shoeSize"
																type="text"
																:placeholder="FormMSG(28, 'Enter your shoe size ...')"
																@keydown.enter.prevent
															/>
														</b-form-group>
													</b-col>
													<b-col sm="12" md="12" lg="3" xl="3">
														<b-form-group :label="FormMSG(29, 'Teeshirt size')">
															<b-form-input
																v-model="myProfile.tShirtSize"
																type="text"
																:placeholder="FormMSG(30, 'Enter your teeshirt size ...')"
																@keydown.enter.prevent
															/>
														</b-form-group>
													</b-col>
												</b-row>
											</div>
										</b-collapse>
									</div>
									<!-- Password-->
									<div class="card-collapsible mt-3">
										<div
											class="cursor-pointer header-card-collapsible d-flex flex-row align-items-center"
											@click="handleClickHeader('password')"
										>
											<div class="title w-90">
												<b-icon icon="key-fill" style="color: rgba(6, 38, 62, 0.84)" /><span class="ml-3">{{
													FormMSG(31, 'Password')
												}}</span>
											</div>
											<div class="d-flex justify-content-end w-10">
												<component
													v-if="!configAccordion['password'].isVisible"
													:is="getIconLucide('ChevronDown')"
													color="#06263E"
													:size="22"
												/>
												<component
													v-if="configAccordion['password'].isVisible"
													:is="getIconLucide('ChevronUp')"
													color="#06263E"
													:size="22"
												/>
											</div>
										</div>
										<b-collapse :visible="configAccordion['password'].isVisible">
											<div class="content-card-collapsible">
												<p class="text-muted">
													*
													{{
														FormMSG(
															107,
															"Make sure it's at least 15 characters OR at least 8 characters including a number and a lowercase letter."
														)
													}}
												</p>
												<b-button variant="outline-primary" :disabled="statusSendCodeValidation.global" @click="handleChangePassword">
													<span v-if="!statusSendCodeValidation.progress && !statusSendCodeValidation.success">
														{{ FormMSG(22, 'Change Password') }}
													</span>
													<span v-if="statusSendCodeValidation.progress && !statusSendCodeValidation.success">
														<b-spinner label="Loading..." small /> {{ FormMSG(146, 'Send code validation') }}
													</span>
													<span v-if="!statusSendCodeValidation.progress && statusSendCodeValidation.success">
														{{ FormMSG(147, 'Code validation sended') }}
													</span>
												</b-button>
											</div>
										</b-collapse>
									</div>
									<!-- TEAM SESSION -->
									<div v-if="isDeterProject === true" class="card-collapsible mt-3">
										<div
											class="cursor-pointer header-card-collapsible d-flex flex-row align-items-center"
											@click="handleClickHeader('deter')"
										>
											<div class="title w-90">
												<component :is="getLucideIcon('UserCog')" :size="20" /><span class="ml-3">{{
													FormMSG(131, 'Team session')
												}}</span>
											</div>
											<div class="d-flex justify-content-end w-10">
												<component
													v-if="!configAccordion['deter'].isVisible"
													:is="getIconLucide('ChevronDown')"
													color="#06263E"
													:size="22"
												/>
												<component
													v-if="configAccordion['deter'].isVisible"
													:is="getIconLucide('ChevronUp')"
													color="#06263E"
													:size="22"
												/>
											</div>
										</div>
										<b-collapse :visible="configAccordion['deter'].isVisible">
											<div class="content-card-collapsible">
												<b-table-simple id="deterSessionTeamTable">
													<b-thead head-variant="dark">
														<b-tr>
															<b-th></b-th>
															<b-th>{{ FormMSG(457, 'Session 1') }}</b-th>
															<b-th>{{ FormMSG(458, 'Session 2') }}</b-th>
															<b-th>{{ FormMSG(459, 'Session 3') }}</b-th>
															<b-th>{{ FormMSG(460, 'Session 4') }}</b-th>
															<b-th>{{ FormMSG(461, 'Session 5') }}</b-th>
															<b-th>{{ FormMSG(462, 'Session 6') }}</b-th>
															<b-th>{{ FormMSG(463, 'Session 7') }}</b-th>
															<b-th>{{ FormMSG(464, 'Session 8') }}</b-th>
														</b-tr>
													</b-thead>
													<b-tbody>
														<b-tr>
															<b-th>{{ FormMSG(465, 'Team A') }}</b-th>
															<b-td>
																<b-form-checkbox
																	v-model="myProfile.session1A"
																	size="lg"
																	:value="true"
																	:unchecked-value="false"
																	disabled
																/>
															</b-td>
															<b-td>
																<b-form-checkbox
																	v-model="myProfile.session2A"
																	size="lg"
																	:value="true"
																	:unchecked-value="false"
																	disabled
																/>
															</b-td>
															<b-td>
																<b-form-checkbox
																	v-model="myProfile.session3A"
																	size="lg"
																	:value="true"
																	:unchecked-value="false"
																	disabled
																/>
															</b-td>
															<b-td>
																<b-form-checkbox
																	v-model="myProfile.session4A"
																	size="lg"
																	:value="true"
																	:unchecked-value="false"
																	disabled
																/>
															</b-td>
															<b-td>
																<b-form-checkbox
																	v-model="myProfile.session5A"
																	size="lg"
																	:value="true"
																	:unchecked-value="false"
																	disabled
																/>
															</b-td>
															<b-td>
																<b-form-checkbox
																	v-model="myProfile.session6A"
																	size="lg"
																	:value="true"
																	:unchecked-value="false"
																	disabled
																/>
															</b-td>
															<b-td>
																<b-form-checkbox
																	v-model="myProfile.session7A"
																	size="lg"
																	:value="true"
																	:unchecked-value="false"
																	disabled
																/>
															</b-td>
															<b-td>
																<b-form-checkbox
																	v-model="myProfile.session8A"
																	size="lg"
																	:value="true"
																	:unchecked-value="false"
																	disabled
																/>
															</b-td>
														</b-tr>
														<b-tr>
															<b-th>{{ FormMSG(466, 'Team B') }}</b-th>
															<b-td>
																<b-form-checkbox
																	v-model="myProfile.session1B"
																	size="lg"
																	:value="true"
																	:unchecked-value="false"
																	disabled
																/>
															</b-td>
															<b-td>
																<b-form-checkbox
																	v-model="myProfile.session2B"
																	size="lg"
																	:value="true"
																	:unchecked-value="false"
																	disabled
																/>
															</b-td>
															<b-td>
																<b-form-checkbox
																	v-model="myProfile.session3B"
																	size="lg"
																	:value="true"
																	:unchecked-value="false"
																	disabled
																/>
															</b-td>
															<b-td>
																<b-form-checkbox
																	v-model="myProfile.session4B"
																	size="lg"
																	:value="true"
																	:unchecked-value="false"
																	disabled
																/>
															</b-td>
															<b-td>
																<b-form-checkbox
																	v-model="myProfile.session5B"
																	size="lg"
																	:value="true"
																	:unchecked-value="false"
																	disabled
																/>
															</b-td>
															<b-td>
																<b-form-checkbox
																	v-model="myProfile.session6B"
																	size="lg"
																	:value="true"
																	:unchecked-value="false"
																	disabled
																/>
															</b-td>
															<b-td>
																<b-form-checkbox
																	v-model="myProfile.session7B"
																	size="lg"
																	:value="true"
																	:unchecked-value="false"
																	disabled
																/>
															</b-td>
															<b-td>
																<b-form-checkbox
																	v-model="myProfile.session8B"
																	size="lg"
																	:value="true"
																	:unchecked-value="false"
																	disabled
																/>
															</b-td>
														</b-tr>
													</b-tbody>
												</b-table-simple>
											</div>
										</b-collapse>
									</div>
									<div class="mt-4 px-2 d-flex justify-content-end">
										<b-button class="mr-1" type="submit" variant="primary" style="font-size: 1.25rem; letter-spacing: 0.05rem">{{
											FormMSG(23, 'Save my changes')
										}}</b-button>
									</div>
								</b-form>
							</div>
						</div>
					</b-row>
					<NewCapture
						v-model="capturePict"
						:key="refreshTokenCaptureItem"
						parentType="user"
						:parentId="myProfile.id"
						auto-crop-capture
						cam-mode-first
						only-one-crop-mode
						@change="handleImgsTaked"
					/>
					<b-modal
						header-class="header-class-modal-doc-package"
						:title="FormMSG(8979800, 'Success!')"
						class="modal-success"
						v-model="successModal"
						@ok="successModal = false"
						ok-variant="success"
						ok-only
						cancel-title="close"
					>
						{{ FormMSG(8979799, 'Your information has been saved.') }}
					</b-modal>
					<!-- <loading :active.sync="isLoading" :is-full-page="true"></loading> -->
				</div>
			</div>
		</transition>
		<!-- bg-variant="secondary" text-variant="white" -->
		<b-modal
			header-class="header-class-modal-doc-package"
			ref="my-modal"
			title="Crop Picture"
			size="cropSize"
			v-model="cropPict"
			@ok="endCrop"
			@cancel="endCropCancel"
			ok-variant="success"
			ok-title="Confirm Save"
			cancel-title="Cancel"
		>
			<CropPicture ref="myCropPicture"> </CropPicture>
		</b-modal>
		<change-mail-modal
			:open="showChangeMailModal"
			:profile="myProfile"
			@modal:close="showChangeMailModal = false"
			@change-mail-modal:save="handleMailModalSave"
		/>
		<change-phone-modal
			:open="showChangePhoneModal"
			:profile="myProfile"
			@modal:close="showChangePhoneModal = false"
			@change-phone-modal:save="handlePhoneModalSave"
		/>
		<validation-code-modal
			:open="showValidationCodeModal"
			:email-for-validation="myProfile.email"
			@validation-code-modal:save="handleValidationCodeModalSave"
			@modal:close="handleValidationCodeModalClose"
		/>
		<b-modal
			header-class="header-class-modal-doc-package"
			v-model="openPictureModal"
			:title="FormMSG(253, 'Picture')"
			class="modal-success"
			ok-variant="success"
			ok-only
			@ok="openPictureModal = false"
		>
			<div>
				<b-img :src="imgToShow" fluid-grow />
			</div>
		</b-modal>
	</errorContainer>
</template>

<script>
import gql from 'graphql-tag';
// import { Camera, CameraResultType } from '@capacitor/camera'
import { store } from '@/store';
import { isValidIBANNumber, isBic } from '@/shared/iban-validator';
import { isSecuritySocialNumberValid } from '@/shared/validators/security-solcial.validator';
import Vue from 'vue';
// import Capture from "@/components/ExpenseService/Capture";
import NewCapture from '@/components/CaptureModule/Main';
import languageMessages from '@/mixins/languageMessages';
// Import component
import Loading from 'vue-loading-overlay';
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';
import VueScrollTo from 'vue-scrollto';
import contract from './users/contract';
import CropPicture from '../components/CropPicture';
import CapturePicture from '../components/CapturePicture';
import { myProfile, updateUserProjectReference } from '@/cruds/users.crud';
import ShareInformation from '@/components/InfoSheet/ShareInformation';
import isSingleProjectMixin from '@/mixins/isSingleProject.mixin';
import GlobalMixin from '@/mixins/global.mixin';
import verte from 'verte';
import 'verte/dist/verte.css';
import { isNil, getObjectFromValue, emailValid, noTilde, capitalize } from '~utils';
// import HomeProfileMobile from "@/components/MyProfileMobile/HomeProfileMobile";
import ListMenu from '@/components/MyProfileMobile/ListMenu';
import * as icons from 'lucide-vue';
import { Pencil } from 'lucide-vue';
import ChangeMailModal from '@/components/MyProfileMobile/ChangeMailModal';
import ChangePhoneModal from '@/components/MyProfileMobile/ChangePhoneModal';
import ValidationCodeModal from '@/components/MyProfileMobile/ValidationCodeModal';
import MobileProfilePicCaptureBtn from '@/components/CaptureModule/MobileProfilePicCaptureBtn';
// import { emailValidationCode } from '@/cruds/registration.crud'
// import { ChevronDown, ChevronUp } from "lucide-vue";
import GoogleAutoComplete from '@/components/GoogleAutoComplete';
import { convertIsoToKey } from '@/shared/helpers';
import { updMyProfile } from '@/cruds/profile.crud';
import { validationMixin } from 'vuelidate';
import { required, minLength, requiredIf } from 'vuelidate/lib/validators';
import MainAddressLocation from '@/components/LocationService/MainAddressLocation';
import moment from 'moment';
import DeterMixin from '@/mixins/deter.mixin';
import VehiculeForm from '@/modules/transports/components/VehiculeForm.vue';

Vue.use(VueScrollTo);

const axios = require('axios').create();

export default {
	name: 'Profile',
	components: {
		Loading,
		contract,
		CropPicture,
		CapturePicture,
		// Capture,
		NewCapture,
		ShareInformation,
		verte,
		// HomeProfileMobile,
		ListMenu,
		Pencil,
		ChangeMailModal,
		ChangePhoneModal,
		ValidationCodeModal,
		MobileProfilePicCaptureBtn,
		GoogleAutoComplete,
		'v-address': MainAddressLocation,
		VehiculeForm
	},
	mixins: [languageMessages, isSingleProjectMixin, GlobalMixin, validationMixin, DeterMixin],
	data() {
		return {
			userBankWarning: { iban: false, bic: false },
			imageStyle: {
				width: '175px',
				height: '175px'
			},
			isStartMobilePwaCaptureLoading: false,
			isSocSecNumberWarning: false,
			// Initialize your apollo data
			isShowPostalAddress: false,
			myProfile: {
				id: 0,
				showMyEmailInCrew: true,
				showMyPhoneInCrew: true,
				taxesPercentage: 0,
				pictureRib: '',
				vehicule: {
					name: '',
					brand: '',
					model: '',
					numberPlate: '',
					type: 0,
					energy: null,
					kgCoTwo: '',
					subType: 0,
					additionalSubType: 0
				},
				driverLicenseNumber: '',
				postalAddressID: 0,
				postalAddress: {
					id: null,
					street: '',
					number: '',
					box: '',
					zip: '',
					state: '',
					city: '',
					country: '',
					countryCode: '',
					countryKey: 0,
					countryCodePostalAddress: '',
					countryKeyPostalAddress: 0,
					other: '',
					lat: 0,
					lng: 0
				},
				maidenName: '',
				title: 1,
				birthCountry: '',
				congesSpectacle: '',
				bankAccountOwnerName: '',
				bankDetailXid: '',
				taxCountry: '',
				socialSecurityCardXid: '',
				insuranceCertificateXid: '',
				socialSecurityCenter: '',
				socialSecurityCenterAddressId: 0,
				// to change
				social_security_number: '',
				lastMedicalVisit: '0001-01-01T00:00:00Z',
				medicalVisitValidity: '0001-01-01T00:00:00Z',
				medicalVisitDocumentXid: '',
				// to change
				disabled: false,
				disabilityNotification: '0001-01-01T00:00:00Z',
				disabilityType: '',
				disabilityStatus: '',
				invalidity: 1,
				rqth: false,
				invalidityPourcentage: 0,
				retired: false,
				retirementDate: '0001-01-01T00:00:00Z',
				trainLoyaltyCard: '',
				trainDiscountCard: '',
				flyingLoyaltyCard: '',
				otherAirlineCard: '',
				residencePermitNumber: '',
				residencePermitEndValidity: '0001-01-01T00:00:00Z',
				residentPermitXid: '',
				workCardXid: '',
				pseudonym: '',
				professionalAssociation: '',
				messageContext: '',
				incompleteProlile: false
			},
			erreur: {},
			errors: [],
			successModal: false,
			capturePict: false,
			cropPict: false,
			isLoading: false,
			pdfFile: '',
			disableSaveButton: true,
			refreshTokenCaptureItem: 0,
			accordion: {},
			menuActiveForMobile: '',
			oldValue: {
				numberOfDependentPerson: 0,
				numberOfDisabledPerson: 0,
				numberOfDependentChild: 0,
				numberOfDisabledChild: 0,
				allergies: ''
			},
			haveAllergies: false,
			haveDependents: false,
			haveChild: false,
			showChangeMailModal: false,
			showChangePhoneModal: false,
			showValidationCodeModal: false,
			codeValidation: 0,
			statusSendCodeValidation: {
				global: false,
				progress: false,
				success: false
			},
			isMyProfileLoading: false,
			showInfoCapture: false,
			configAccordion: {
				'personnal-data': {
					isVisible: true
				},
				identification: {
					isVisible: false
				},
				address: {
					isVisible: false
				},
				family: {
					isVisible: false
				},
				other: {
					isVisible: false
				},
				password: {
					isVisible: false
				},
				'personal-vehicle': {
					isVisible: false
				},
				finance: {
					isVisible: false
				},
				health: {
					isVisible: false
				},
				deter: {
					isVisible: false
				}
			},
			countryKey: 16965, // Belgium's countryKey
			countryKeyPostalAddress: 16965,
			countryCode: 'BE',
			countryCodePostalAddress: 'BE',
			isSubmitted: false,
			imgPropPicture: { blank: true, blankColor: '#d5d6d7', class: 'myprofile-picture-preview' },
			imgPropPictureId: { blank: true, blankColor: '#d5d6d7', width: 32, height: 32, class: 'm1' },
			imgPropPictureIdVerso: { blank: true, blankColor: '#d5d6d7', width: 32, height: 32, class: 'm1' },
			imgPropPictureDriverLicense: { blank: true, blankColor: '#d5d6d7', width: 32, height: 32, class: 'm1' },
			imgPropPictureDriverLicenseVerso: { blank: true, blankColor: '#d5d6d7', width: 32, height: 32, class: 'm1' },
			imgPropPictureRib: { blank: true, blankColor: '#d5d6d7', width: 32, height: 32, class: 'm1' },
			imgPropSignatureId: { blank: true, blankColor: '#d5d6d7', width: 32, height: 32, class: 'm1' },
			currentImgField: 'picture',
			openPictureModal: false,
			imgToShow: '',

			// fillSearch: this.myProfile.address || null
			vehiculeData: {}
		};
	},
	directives: {
		'limit-100': {
			bind(el) {
				el.addEventListener('input', (event) => {
					const inputValue = event.target.value;
					const numericValue = parseFloat(inputValue);
					if (!isNaN(numericValue)) {
						const compare = Math.min(Math.max(numericValue, 0), 100);
						if (compare !== numericValue) {
							event.target.value = compare;
							event.target.dispatchEvent(new Event('input'));
						}
					}
				});
			}
		}
	},
	computed: {
		userNameActif() {
			return store.state.myProfile.firstName;
		},
		isDeterAction() {
			if (+this.myProfile.bankDetailXid.length !== 0 && +this.myProfile.medicalVisitDocumentXid.length === 0) {
				this.messageContext = this.FormMSG(1711, 'visit medical document');
				this.incompleteProlile = true;
				return true;
			}
			if (+this.myProfile.bankDetailXid.length === 0 && +this.myProfile.medicalVisitDocumentXid.length !== 0) {
				this.messageContext = this.FormMSG(1712, 'bank detail document');
				this.incompleteProlile = true;
				return true;
			}
			if (+this.myProfile.bankDetailXid.length === 0 && +this.myProfile.medicalVisitDocumentXid.length === 0) {
				this.messageContext = this.FormMSG(1713, 'visit medical document and bank detail document');
				this.incompleteProlile = true;
				return true;
			} else {
				this.messageContext = '';
				this.incompleteProlile = false;
				return false;
			}
		},
		pageMobileTitle() {
			if (this.menuActiveForMobile === 'personal-data') {
				return this.FormMSG(1211, 'Personal Data');
			} else if (this.menuActiveForMobile === 'legal-information') {
				return this.FormMSG(1213, 'Legal Information');
			} else if (this.menuActiveForMobile === 'finance') {
				return this.FormMSG(1306, 'Finance');
			} else if (this.menuActiveForMobile === 'address') {
				return this.FormMSG(11, 'Address');
			} else if (this.menuActiveForMobile === 'health') {
				return this.FormMSG(1252, 'Health');
			} else if (this.menuActiveForMobile === 'family') {
				return this.FormMSG(13, 'Family');
			} else if (this.menuActiveForMobile === 'personal-vehicle') {
				return this.FormMSG(1226, 'Personal vehicle');
			} else if (this.menuActiveForMobile === 'other') {
				return this.FormMSG(16, 'Other');
			} else if (this.menuActiveForMobile === 'settings') {
				return this.FormMSG(1215, 'Settings');
			} else if (this.menuActiveForMobile === 'team-session') {
				return this.FormMSG(131, 'Team session');
			}
		},
		displayBirthDate() {
			const splitBirthDate = this.myProfile.birthDate ? this.myProfile.birthDate.substring(0, 10) : '';
			if (splitBirthDate !== '0001-01-01') {
				return splitBirthDate;
			}
			return '';
		},
		displaySpouseBirthDate() {
			const splitBirthDate = this.myProfile.spouseBirthDate ? this.myProfile.spouseBirthDate.substring(0, 10) : '';
			if (splitBirthDate !== '0001-01-01') {
				return splitBirthDate;
			}
			return '';
		},

		displayDateLastMedicalVisit() {
			const splitDate = this.myProfile.lastMedicalVisit ? this.myProfile.lastMedicalVisit.substring(0, 10) : '';
			if (splitDate !== '0001-01-01') {
				return moment(splitDate).format('YYYY-MM-DD');
			}
			return '';
		},
		displayDateMedicalVisitValidity() {
			const splitDate = this.myProfile.medicalVisitValidity ? this.myProfile.medicalVisitValidity.substring(0, 10) : '';
			if (splitDate !== '0001-01-01') {
				return moment(splitDate).format('YYYY-MM-DD');
			}
			return '';
		},
		displayDateDisabilityNotification() {
			const splitDate = this.myProfile.disabilityNotification ? this.myProfile.disabilityNotification.substring(0, 10) : '';
			if (splitDate !== '0001-01-01') {
				return moment(splitDate).format('YYYY-MM-DD');
			}
			return '';
		},
		displayDateRetirementDate() {
			const splitDate = this.myProfile.retirementDate ? this.myProfile.retirementDate.substring(0, 10) : '';
			if (splitDate !== '0001-01-01') {
				return moment(splitDate).format('YYYY-MM-DD');
			}
			return '';
		},
		displayDateResidencePermitEndValidity() {
			const splitDate = this.myProfile.residencePermitEndValidity ? this.myProfile.residencePermitEndValidity.substring(0, 10) : '';
			if (splitDate !== '0001-01-01') {
				return moment(splitDate).format('YYYY-MM-DD');
			}
			return '';
		},
		menuGenders() {
			const res = this.FormMenu(1002);
			return [{ value: null, text: this.FormMSG(165, 'Please select an option') }, ...res];
		},
		menuTitle() {
			let titles = this.FormMenu(1338);
			return titles;
		},
		menuInvalidity() {
			let invalidity = this.FormMenu(1339);
			return invalidity;
		},
		optionsForCivilStatus() {
			return this.FormMenu(1001);
		},
		optionsForLanguages() {
			return this.FormMenu(1);
		},
		useTask() {
			return store.state.myProject.useTask;
		},
		countries() {
			let countries = this.FormMenu(8);

			countries.sort((a, b) => {
				if (noTilde(a.text).toLowerCase() < noTilde(b.text).toLowerCase()) {
					return -1;
				}
				if (noTilde(a.text).toLowerCase() > noTilde(b.text).toLowerCase()) {
					return 1;
				}

				return 0;
			});

			countries = [
				{
					value: 0,
					text: this.FormMSG(187, 'Select a country...')
				},
				...countries
			];

			return countries;
		},
		countryCodes() {
			return this.FormMenu(9);
		},
		distanceUnit() {
			return store.state.myProfile.distanceUnit;
		},
		isPme() {
			return store.isPme();
		},
		vehicleTypes() {
			let types = this.FormMenu(10003);

			types.sort((a, b) => {
				if (noTilde(a.text).toLowerCase() < noTilde(b.text).toLowerCase()) {
					return -1;
				}
				if (noTilde(a.text).toLowerCase() > noTilde(b.text).toLowerCase()) {
					return 1;
				}

				return 0;
			});

			return types;
		},
		vehicleEnergies() {
			let energies = this.FormMenu(10004);

			energies.sort((a, b) => {
				if (noTilde(a.text).toLowerCase() < noTilde(b.text).toLowerCase()) {
					return -1;
				}
				if (noTilde(a.text).toLowerCase() > noTilde(b.text).toLowerCase()) {
					return 1;
				}

				return 0;
			});

			return energies;
		}
	},
	async created() {
		await this.initProfile();
	},
	mounted() {
		this.$nextTick(async () => {
			this.oldValue.allergies = this.myProfile.allergies;
			this.oldValue.numberOfDependentPerson = this.myProfile.numberOfDependentPerson;
			this.oldValue.numberOfDisabledPerson = this.myProfile.numberOfDisabledPerson;
			this.oldValue.numberOfDependentChild = this.myProfile.numberOfDependentChild;
			this.oldValue.numberOfDisabledChild = this.myProfile.numberOfDisabledChild;
			this.haveAllergies = this.myProfile.allergies !== '';
			this.haveDependents = parseInt(this.myProfile.numberOfDependentPerson) > 0 || parseInt(this.myProfile.numberOfDisabledPerson) > 0;
			this.haveChild = parseInt(this.myProfile.numberOfDependentChild) > 0 || parseInt(this.myProfile.numberOfDisabledChild) > 0;
		});
	},
	watch: {
		'myProfile.picture'(picture) {
			this.$store.dispatch('setAuthUser', { picture });
		}
	},
	methods: {
		handleVehiculeFormChange(payload) {
			this.myProfile.vehicule = {
				...this.myProfile.vehicule,
				...payload
			};
		},
		/**
		 * @param  {!String} iban
		 */
		onOpenUrl(url) {
			return window.open(url);
		},
		checkFile(file) {
			if (file !== undefined) {
				let extension = file.toString().match(/.pdf/gi);
				if (extension !== null && extension.join`` === '.pdf') {
					return true;
				}
				return false;
			}
			return false;
		},
		handleSupplierIbanChange(iban) {
			this.userBankWarning.iban = !isValidIBANNumber(iban);
		},
		/**
		 * @param  {!String} bic
		 */
		handleSupplierBicChange(bic) {
			this.userBankWarning.bic = !isBic(bic);
		},
		handleDynamicAddressChange(payload) {
			this.myProfile.socialSecurityCenterAddressId = +payload.id;
		},
		handleDynamicAddressDelete() {
			this.myProfile.socialSecurityCenterAddressId = 0;
		},
		handleAddressMapLinkChange(payload) {
			console.log({ mapAddress: payload });
		},
		changeFieldPic(field) {
			this.currentImgField = field;
		},
		async initProfile() {
			this.isMyProfileLoading = true;
			const result = await myProfile();
			this.myProfile = result;
			this.initCountry();
			if (this.myProfile.postalAddressID !== 0) {
				this.isShowPostalAddress = true;
			} else {
				this.isShowPostalAddress = false;
			}
			this.isMyProfileLoading = false;
		},
		initCountry() {
			let project = null;

			if (isNil(store.state) || isNil(store.state.myProject)) {
				project = store.state.myProject;
			}

			if (!isNil(this.myProfile) && !isNil(this.myProfile.address) && this.myProfile.address.countryKey > 0) {
				this.countryKey = this.myProfile.address.countryKey;
				this.countryCode = this.myProfile.address.countryCode;
			} else if (!isNil(project) && !isNil(project.legalEntity) && !isNil(project.legalEntity.address) && project.legalEntity.address.countryKey > 0) {
				this.countryKey = project.legalEntity.address.countryKey;
				this.countryCode = project.legalEntity.address.countryCode;
			}
			if (!isNil(this.myProfile.postalAddress) && this.myProfile.postalAddress.countryKey > 0) {
				this.countryKeyPostalAddress = this.myProfile.postalAddress.countryKey;
				this.countryCodePostalAddress = this.myProfile.postalAddress.countryCode;
			}
		},
		handleValidationCodeModalSave() {
			this.$router.push('/password');
		},
		async handleChangePassword() {
			this.statusSendCodeValidation.global = true;
			this.statusSendCodeValidation.progress = true;

			setTimeout(() => {
				this.statusSendCodeValidation.progress = false;
				this.statusSendCodeValidation.success = true;
				this.showValidationCodeModal = true;
			}, 100);
		},
		handleValidationCodeModalClose() {
			this.showValidationCodeModal = false;
			this.codeValidation = '';
			this.statusSendCodeValidation.global = false;
			this.statusSendCodeValidation.progress = false;
			this.statusSendCodeValidation.success = false;
		},
		handlePhoneModalSave(payload) {
			const newPhone = payload.newPhoneE164;
			const mutation = gql`
				mutation UPD_MY_PROFILE($phone: String) {
					UpdMyProfile(newProfile: { phone: $phone })
				}
			`;
			this.$apollo
				.mutate({
					mutation,
					variables: {
						phone: newPhone
					}
				})
				.then((result) => {
					store.state.myProfile.phone = newPhone;
					this.myProfile.phone = newPhone;
					if (this.$screen.width <= 567) {
						this.menuActiveForMobile = '';
						this.createToastForMobile(this.FormMSG(1225, 'Profile updated successfully'), '', '');
					} else {
						this.$toast({
							message: this.FormMSG(1225, 'Profile updated successfully'),
							title: this.FormMSG(368, 'Success'),
							variant: 'success'
						});
					}
				})
				.catch((e) => {
					this.erreur = e;
				});
		},
		handleMailModalSave(payload) {
			const newEmail = payload.newEmail;
			const mutation = gql`
				mutation UPD_MY_PROFILE($email: String) {
					UpdMyProfile(newProfile: { email: $email })
				}
			`;
			this.$apollo
				.mutate({
					mutation,
					variables: {
						email: newEmail
					}
				})
				.then((result) => {
					store.state.myProfile.email = newEmail;
					this.myProfile.email = newEmail;
					if (this.$screen.width <= 567) {
						this.menuActiveForMobile = '';
						this.createToastForMobile(this.FormMSG(1225, 'Profile updated successfully'), '', '');
					} else {
						this.$toast({
							message: this.FormMSG(1225, 'Profile updated successfully'),
							title: this.FormMSG(368, 'Success'),
							variant: 'success'
						});
					}
				})
				.catch((e) => {
					this.erreur = e;
				});
		},
		incrementValue(data) {
			let value = parseInt(this.myProfile[data]);
			this.myProfile[data] = value + 1;
		},
		decrementValue(data) {
			let value = parseInt(this.myProfile[data]);
			if (value > 0) {
				this.myProfile[data] = value - 1;
			}
		},
		handleChangeHaveAllergies(payload) {
			if (!payload) {
				this.myProfile.allergies = '';
			}
		},
		handleChangeHaveDependents(payload) {
			if (!payload) {
				this.myProfile.numberOfDependentPerson = 0;
				this.myProfile.numberOfDisabledPerson = 0;
			}
		},
		handleChangeHaveChild(payload) {
			if (!payload) {
				this.myProfile.numberOfDependentChild = 0;
				this.myProfile.numberOfDisabledChild = 0;
			}
		},
		getIconLucide(name) {
			return icons[name];
		},
		handleMenuMobileClick(payload) {
			this.menuActiveForMobile = payload;
		},
		updateBirthDate($event) {
			if (this.myProfile.birthDate.startsWith('T')) {
				this.myProfile.birthDate = $event + this.myProfile.birthDate;
			} else {
				this.myProfile.birthDate = $event + this.myProfile.birthDate.substring(10);
			}
		},
		updateSpouseBirthDate($event) {
			if (this.myProfile.spouseBirthDate.startsWith('T')) {
				this.myProfile.spouseBirthDate = $event + this.myProfile.spouseBirthDate;
			} else {
				this.myProfile.spouseBirthDate = $event + this.myProfile.spouseBirthDate.substring(10);
			}
		},
		updateDate($event, field) {
			if (this.myProfile[field].startsWith('T')) {
				this.myProfile[field] = $event + this.myProfile[field];
			} else {
				this.myProfile[field] = $event + this.myProfile[field].substring(10);
			}
		},
		enableSaveButton() {
			this.disableSaveButton = false;
		},
		startCapture(fieldName) {
			// initialise global store variable and open dialog to capture and crop
			//console.log("instartcapture");
			store.state.capturedImage = '';
			store.state.imageCropped = '';
			this.disableSaveButton = true;
			this.capturePict = true;
			this.currentImgField = fieldName;
		},
		cancelCapture() {
			store.state.capturedImage = '';
			// stop camera
			this.$refs.CaptCompo.onStop();
			this.capturePict = false;
		},
		endCapture(item) {
			// stop camera
			this.$refs.CaptCompo.onStop();
			// copy store image name into profile data
			this.capturePict = false;
			// start cropping if image was captured
			if (store.state.capturedImage.length > 0) {
				// an image was captured
				// console.log("in End capture");
				store.state.imageToCrop = store.state.capturedImage;
				this.$refs.myCropPicture.bind(); // force the component to link the new image to the display
				this.cropPict = true;
			}
		},
		endCrop(item) {
			// save image to server
			//console.log("this.$refs.myCropPicture.cropped:",this.$refs.myCropPicture.cropped);
			if (store.state.imageCropped.length > 0) {
				this.saveImage(store.state.imageCropped);
			}
			this.cropPict = false;
		},
		endCropCancel(item) {
			store.state.imageCropped = '';
			this.cropPict = false;
		},
		b64toFile(b64Data, filename, contentType) {
			const sliceSize = 512;
			const byteCharacters = atob(b64Data);
			let byteArrays = [];
			for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
				const slice = byteCharacters.slice(offset, offset + sliceSize);
				let byteNumbers = new Array(slice.length);

				for (let i = 0; i < slice.length; i++) {
					byteNumbers[i] = slice.charCodeAt(i);
				}
				const byteArray = new Uint8Array(byteNumbers);
				byteArrays.push(byteArray);
			}

			const file = new File(byteArrays, filename, {
				type: contentType
			});

			return file;
		},
		saveImage(imgData) {
			this.isLoading = true;
			let retStr = '';
			// remove header data:image...
			const base64Data = imgData.replace(/^data:image\/jpeg;base64,/, '');
			// create in memory file
			const fil = this.b64toFile(base64Data, 'test.jpg', 'jpg');
			let formData = new FormData(); // instantiate it
			// suppose you have your file ready
			formData.set('uploadimage', fil);
			const config = {
				headers: {
					Authorization: `Bearer ${store.state.token}`,
					'content-type': 'multipart/form-data'
				}
			};
			const urlStr = process.env.VUE_APP_GQL + '/upload';
			axios
				.post(urlStr, formData, config)
				.then((response) => {
					retStr = response.data;
					// console.log('response.data:', retStr)
					this.myProfile[this.currentImgField] = retStr;
					store.state.imageCropped = '';
					// console.log('response.data:', retStr)
					let variables = {};
					variables[this.currentImgField] = retStr;
					this.updateImg(variables);

					this.isLoading = false;
				})
				.catch((error) => {
					console.log(error);
				})
				.finally(() => (this.isLoading = false));

			return retStr;
		},
		removeImage() {
			this.isLoading = true;
			//console.log("inremoveimage:",this.pdfFile.name);
			let retStr = '';
			let formData = new FormData(); // instantiate it
			formData.append('fileName', this.pdfFile.name);
			const config = {
				headers: {
					Authorization: `Bearer ${store.state.token}`,
					'content-type': 'multipart/form-data'
				}
			};

			const urlStr = process.env.VUE_APP_GQL + '/removedocument';

			axios
				.post(urlStr, formData, config)
				.then((response) => {
					retStr = response.data;
					console.log('response.data:', retStr);
					this.isLoading = false;
				})
				.catch((error) => {
					console.log(error);
				})
				.finally(() => (this.isLoading = false));

			return retStr;
		},
		showToken() {
			console.log(store.state.token);
		},
		checkAuthorized() {
			this.$v.$touch();
			if (this.$v.$invalid) {
				return false;
			}
			return true;
		},
		async submitForm(e) {
			e.preventDefault();
			this.errors = [];
			let isAuthorized = this.checkAuthorized();
			if (isAuthorized) {
				this.isSubmitted = true;
				this.myProfile = {
					...this.myProfile,
					vehicleName: this.myProfile.vehicule.name,
					vehicleBrand: this.myProfile.vehicule.brand,
					vehicleModel: this.myProfile.vehicule.model,
					vehicleNumberPlate: this.myProfile.vehicule.numberPlate,
					vehicleType: +this.myProfile.vehicule.type,
					vehicleEnergy: +this.myProfile.vehicule.energy,
					vehicleKgCoTwo: parseFloat(this.myProfile.vehicule.kgCoTwoPerKm),
					vehicleSubType: parseInt(this.myProfile.vehicule.subType),
					vehicleAdditionalSubType: parseInt(this.myProfile.vehicule.additionalSubType)
				};
				let result = await updMyProfile(this.myProfile)
					.then(async (result) => {
						await updateUserProjectReference({ userId: parseInt(this.myProfile.id, 10), reference: this.myProfile.reference })
							.then((bool) => {
								if (bool) {
									// this.isLoading = false;
									this.successModal = true;
								}
							})
							.catch((e) => {
								console.log({ e });
							});
						if (this.$screen.width <= 567) {
							this.menuActiveForMobile = '';
							this.createToastForMobile(this.FormMSG(1225, 'Profile updated successfully'), '', '');
						}
					})
					.catch((error) => {
						this.isLoading = false;
						this.erreur = error;
					});
				return result;
			} else {
				this.isSubmitted = false;
				let config = {
					'personnal-data': {
						isVisible: true
					},
					identification: {
						isVisible: true
					},
					address: {
						isVisible: true
					},
					family: {
						isVisible: true
					},
					other: {
						isVisible: true
					},
					password: {
						isVisible: true
					},
					'personal-vehicle': {
						isVisible: true
					},
					finance: {
						isVisible: true
					},
					health: {
						isVisible: true
					},
					deter: {
						isVisible: true
					}
				};
				// save data
				this.configAccordion = { ...this.configAccordion, ...config };
				this.$bvToast.toast(this.FormMSG(1317, 'Please fill in all required fields.'), {
					title: this.FormMSG(1318, 'Warning'),
					variant: 'danger',
					solid: true,
					toaster: 'b-toaster-top-center'
				});
			}
		},
		updateImg(variables) {
			const mutation_UpdMyProfilePicture = gql`
				mutation ($${this.currentImgField}: String) {
					UpdMyProfile(newProfile: { ${this.currentImgField}: $${this.currentImgField} })
				}
			`;
			this.errors = [];
			this.isLoading = true;
			this.$apollo
				.mutate({
					mutation: mutation_UpdMyProfilePicture,
					variables
				}) // picture: this.myProfile.picture
				.then((result) => {
					this.isLoading = false;
					this.successModal = true;
				})
				.catch((error) => {
					this.isLoading = false;
					this.erreur = error;
				});
		},
		submitFile() {
			// console.log("submit file:", this.pdfFile);
			let formData = new FormData();
			formData.append('uploadimage', this.pdfFile);
			formData.append('fileName', this.pdfFile.name);
			formData.append('fileType', this.pdfFile.type);
			const urlStr = process.env.VUE_APP_GQL + '/upload';

			axios
				.post(urlStr, formData, {
					headers: {
						Authorization: `Bearer ${store.state.token}`,
						'Content-Type': 'multipart/form-data'
					}
				})
				.then((result) => {
					//console.log("success result:", result);
				})
				.catch(function () {
					console.log('FAILURE!!');
				});
		},
		/**
		 * @param {Array} imgs
		 */
		handleImgsTaked(imgs) {
			const retStr = imgs[0];
			this.myProfile[this.currentImgField] = retStr;
			store.state.imageCropped = '';
			let variables = {};
			variables[this.currentImgField] = retStr;
			this.updateImg(variables);
			this.isLoading = false;
			this.refreshTokenCaptureItem = this.refreshTokenCaptureItem + 1;
		},
		handleClickHeader(key) {
			this.configAccordion[key].isVisible = !this.configAccordion[key].isVisible;
			// console.log({data});
		},
		getImage(picture, fieldName = '') {
			if (!isNil(picture) && picture.length > 0) {
				if (fieldName === 'picture') {
					this.imgPropPicture.blank = false;
				} else if (fieldName === 'pictureId') {
					this.imgPropPictureId.blank = false;
				} else if (fieldName === 'pictureIdVerso') {
					this.imgPropPictureIdVerso.blank = false;
				} else if (fieldName === 'pictureDriverLicense') {
					this.imgPropPictureDriverLicense.blank = false;
				} else if (fieldName === 'pictureDriverLicenseVerso') {
					this.imgPropPictureDriverLicenseVerso.blank = false;
				} else if (fieldName === 'pictureRib') {
					this.imgPropPictureRib.blank = false;
				} else if (fieldName === 'signatureId') {
					this.imgPropSignatureId.blank = false;
				}
			}

			return process.env.VUE_APP_GQL + '/images/' + picture;
		},
		handleAutoComplete(addressData, field) {
			if (field === 'legalAddress') {
				this.myProfile.address = {
					...this.myProfile.address,
					street: addressData.route || addressData.place.name,
					city: addressData.locality,
					number: addressData.street_number,
					state: addressData.administrative_area_level_1,
					zip: addressData.postal_code,
					country: addressData.country,
					countryCode: addressData.country_code,
					countryKey: convertIsoToKey(addressData.country_code),
					lat: addressData.latitude,
					lng: addressData.longitude
				};
			}
			if (field === 'postalAddress') {
				this.myProfile.postalAddress = {
					...this.myProfile.postalAddress,
					street: addressData.route || addressData.place.name,
					city: addressData.locality,
					number: addressData.street_number,
					state: addressData.administrative_area_level_1,
					zip: addressData.postal_code,
					country: addressData.country,
					countryCode: addressData.country_code,
					countryKey: convertIsoToKey(addressData.country_code),
					lat: addressData.latitude,
					lng: addressData.longitude
				};
			}
		},
		getCountry() {
			const country = getObjectFromValue(this.countryCodes, 'value', this.countryKey);
			this.countryCode = country ? country.text.toLowerCase() : '';
			this.$refs['google-search-address'].clearSelected();
			this.myProfile.address = {};
		},
		getCountryPostalAddress() {
			const country = getObjectFromValue(this.countryCodes, 'value', this.countryKeyPostalAddress);
			this.countryCodePostalAddress = country ? country.text.toLowerCase() : '';
			this.$refs['google-search-address'].clearSelected();
			this.myProfile.postalAddress = {};
		},
		showImage(picture) {
			if (isNil(picture) || (!isNil(picture) && picture.length === 0)) return;
			this.imgToShow = `${process.env.VUE_APP_GQL}/images/${picture}`;
			this.openPictureModal = true;
		},
		deleteImg(fieldName) {
			this.$bvModal
				.msgBoxConfirm(this.FormMSG(256, 'Are you sure to remove this picture ?'), {
					title: this.FormMSG(257, 'Confirm'),
					size: 'sm',
					buttonSize: 'sm',
					okVariant: 'danger',
					okTitle: this.FormMSG(258, 'YES'),
					cancelTitle: this.FormMSG(259, 'NO'),
					footerClass: 'p-2',
					hideHeaderClose: false,
					centered: false
				})
				.then(async (v) => {
					if (!v) return;

					let variables = {};
					variables[fieldName] = '';
					this.currentImgField = fieldName;
					this.updateImg(variables);
					this.myProfile[fieldName] = '';
					// this[`imgProp${capitalize(fieldName)}`].blank = true;
					if (fieldName === 'picture') {
						this.imgPropPicture.blank = true;
					} else if (fieldName === 'pictureId') {
						this.imgPropPictureId.blank = true;
					} else if (fieldName === 'pictureIdVerso') {
						this.imgPropPictureIdVerso.blank = true;
					} else if (fieldName === 'pictureDriverLicense') {
						this.imgPropPictureDriverLicense.blank = true;
					} else if (fieldName === 'pictureDriverLicenseVerso') {
						this.imgPropPictureDriverLicenseVerso.blank = true;
					} else if (fieldName === 'signatureId') {
						this.imgPropSignatureId.blank = true;
					}
				})
				.catch((err) => console.log(err));
		},

		handleSocSecNumberChange(value) {
			if (isNil(value) || value.trim().length === 0) return;
			this.isSocSecNumberWarning = !isSecuritySocialNumberValid(value);
		},
		isDate(valeur) {
			return valeur instanceof Date;
		}
	},
	validations: {
		myProfile: {
			firstName: {
				required,
				min: minLength(2)
			},
			name: {
				required,
				min: minLength(2)
			},
			email: {
				required,
				emailValid
			}
			// socSecNumber: {
			// 	required: requiredIf(function () {
			// 		return this.myProfile.socSecNumber && this.myProfile.socSecNumber.trim().length > 0;
			// 	}),
			// 	validSocialSecurityNumber(value) {
			// 		if (!value || value.trim().length === 0) return true;
			// 		return isSecuritySocialNumberValid(value);
			// 	}
			// }
		}
	}
};
</script>

<style>
.modal .modal-captSize {
	max-width: 600px;
	width: 500px;
	max-height: 1500px;
	height: 1500px;
}

.modal .modal-cropSize {
	max-width: 600px;
	width: 500px;
}

.modal-header .close {
	display: none;
}

.myprofile-picture-preview {
	margin-top: 0.8rem;
	width: 175px;
	height: 175px;
	object-fit: cover;
	border-radius: 50%;
}

.app-body {
	overflow-x: unset;
}

.header-fixed .app-header {
	z-index: 1021;
}

.sidenav-sticky {
	top: 68px;
}

.custom-file-input {
	cursor: pointer;
}
.text-redired {
	font-size: 1rem;
	text-transform: initial;
}
.text-fin {
	font-weight: 10;
	font-size: 12px;
}
.btn-address {
	cursor: pointer;
	height: 28px;
	gap: 10px;
}
</style>
