<template>
	<div class="form">
		<b-row>
			<b-col sm="12" md="3">
				<b-form-group :label="FormMSG(1228, 'Name')">
					<b-form-input
						v-model="$v.dataToSend.name.$model"
						type="text"
						:placeholder="FormMSG(1229, 'Vehicle name ...')"
						:state="!$v.dataToSend.name.$error"
						@change="emitChange"
					/>
					<div v-if="$v.dataToSend.name.$error" class="invalid-feedback">
						{{ FormMSG(125, 'Name is required') }}
					</div>
				</b-form-group>
			</b-col>
			<b-col sm="12" md="3">
				<b-form-group :label="FormMSG(1230, 'Brand')">
					<b-form-input v-model="dataToSend.brand" type="text" :placeholder="FormMSG(1231, 'Vehicle brand ...')" @change="emitChange" />
				</b-form-group>
			</b-col>
			<b-col sm="12" md="3">
				<b-form-group :label="FormMSG(1232, 'Model')">
					<b-form-input v-model="dataToSend.model" type="text" :placeholder="FormMSG(1233, 'Vehicle model ...')" @change="emitChange" />
				</b-form-group>
			</b-col>
			<b-col sm="12" md="3">
				<b-form-group :label="FormMSG(1234, 'Number plate')">
					<b-form-input v-model="dataToSend.numberPlate" type="text" :placeholder="FormMSG(1235, 'Your number plate ...')" @change="emitChange" />
				</b-form-group>
			</b-col>
		</b-row>
		<b-row>
			<b-col sm="12" md="9">
				<transport-type-selector
					ref="transportTypSelectorVehicule"
					:data-additional-detail="dataToSend.additionalSubType"
					:data-detail="dataToSend.subType"
					:data-type="dataToSend.type"
					hide-boat-type
					hide-menues-not-two-wheels
					hide-freight-type
					@transportation-type-selector:change="handleTransportationTypeSelectorChange"
				/>
			</b-col>
			<b-col sm="12" md="3">
				<b-form-group>
					<label>{{ FormMSG(1238, 'Kg CO2 per') }} {{ distanceUnit }}</label>
					<b-form-input v-model="dataToSend.kgCoTwoPerKm" type="number" step="0.01" min="0" placeholder="0" @change="emitChange" />
				</b-form-group>
			</b-col>
		</b-row>
	</div>
</template>

<script>
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';
import _ from 'lodash';
import { store } from '@/store';
import { noTilde } from '~utils';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import TransportTypeSelector from '@/modules/transports/components/TransportTypeSelector.vue';

const INIT_VEHICULE_DATA = {
	name: null,
	brand: null,
	model: null,
	numberPlate: null,
	type: 0,
	subType: 0,
	additionalSubType: 0,
	energy: null,
	reference: null,
	kgCoTwoPerKm: 0
};
export default {
	name: 'VehiculeForm',

	mixins: [languageMessages, globalMixin, validationMixin],

	props: {
		vehiculeData: { type: Object, default: () => ({}), required: false }
	},

	components: {
		TransportTypeSelector
	},

	data() {
		return {
			dataToSend: _.cloneDeep(INIT_VEHICULE_DATA)
		};
	},

	validations: {
		dataToSend: {
			name: { required }
		}
	},

	watch: {
		vehiculeData: {
			handler(newVal) {
				if (JSON.stringify(newVal) !== '{}' && !_.isNil(newVal)) {
					this.dataToSend = {
						name: newVal.name,
						brand: newVal.brand,
						model: newVal.model,
						numberPlate: newVal.numberPlate,
						type: newVal.type,
						subType: newVal.subType,
						additionalSubType: newVal.additionalSubType,
						energy: newVal.energy,
						reference: newVal.reference,
						kgCoTwoPerKm: newVal.kgCoTwoPerKm
					};
				}
			},
			immediate: true,
			deep: true
		}
	},

	computed: {
		vehicleEnergies() {
			let energies = this.FormMenu(10004);

			energies.sort((a, b) => {
				if (noTilde(a.text).toLowerCase() < noTilde(b.text).toLowerCase()) {
					return -1;
				}
				if (noTilde(a.text).toLowerCase() > noTilde(b.text).toLowerCase()) {
					return 1;
				}

				return 0;
			});

			return energies;
		},
		vehicleTypes() {
			let types = this.FormMenu(905);

			types = types.filter((option) => [1, 3, 4, 6, 11, 12, 14, 15, 16, 17].includes(option.value));

			types = types.sort(function compare(a, b) {
				if (a.text.toLowerCase() < b.text.toLowerCase()) return -1;
				if (a.text.toLowerCase() > b.text.toLowerCase()) return 1;
				return 0;
			});

			return types;
		},
		distanceUnit() {
			return store.state.myProfile.distanceUnit;
		}
	},

	methods: {
		isInvalid() {
			this.$v.$touch();

			const resultIsInvalidTypeTransport = this.$refs['transportTypSelectorVehicule'].isInvalid();

			return this.$v.$invalid === true || resultIsInvalidTypeTransport === true;
		},
		handleTransportationTypeSelectorChange({ type, detail, additionalDetail }) {
			this.dataToSend.type = type;
			this.dataToSend.subType = detail;
			this.dataToSend.additionalSubType = additionalDetail;

			this.emitChange();
		},
		emitChange() {
			this.$emit('vehicule-form:change', this.dataToSend);
		}
	}
};
</script>
