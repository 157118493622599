<template>
	<div>
		<b-col md="12">
			<h1 :class="[`${!$screen.sm ? 'main-page-title' : ''}`, { 'is-pwa': $isPwa() }]">
				{{ FormMSG(10000, 'Profile') }}
			</h1>
		</b-col>
		<div :class="`${$screen.width <= 576 ? 'my-project' : ''}`">
			<b-tabs v-model="activeTabs" active-nav-item-class="font-weight-bold" active-tab-class="font-weight-bold">
				<b-tab :title="FormMSG(200, 'Profile')">
					<profile-tab />
				</b-tab>
				<b-tab lazy :title="FormMSG(300, 'Contracts')">
					<Mycontracts />
				</b-tab>
				<b-tab lazy v-if="!isFilmSingle && !isFilmSingleFree && useDoc" :title="FormMSG(301, 'Documents')">
					<MyDocuments />
				</b-tab>
			</b-tabs>
		</div>
	</div>
</template>
<script>
import languageMessages from '@/mixins/languageMessages';
import isSingleProjectMixin from '@/mixins/isSingleProject.mixin';
import GlobalMixin from '@/mixins/global.mixin';
import Mycontracts from './mycontracts.vue';
import profileTab from './profileScreenToTab';
import MyDocuments from '../views/documents/mydocuments.vue';
import { store } from '@/store';
export default {
	name: 'Profile',
	mixins: [languageMessages, isSingleProjectMixin, GlobalMixin],
	components: { Mycontracts, profileTab, MyDocuments },
	data() {
		return {
			activeTabs: 0
		};
	},
	computed: {
		useDoc() {
			return store.state.myProject.useDocument;
		}
	},
	watch: {
		'$route.query.activeTabContract': {
			handler(newVal) {
				if (newVal) {
					this.activeTabs = 1;
				}
			},
			immediate: true,
			deep: true
		},
		'$route.query.forDocs': {
			handler(newVal) {
				if (newVal) {
					this.activeTabs = 2;
				}
			},
			immediate: true,
			deep: true
		}
	}
};
</script>
<style></style>
