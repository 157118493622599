import gql from 'graphql-tag';
import { apolloClient as apollo } from '@/vue-apollo';

export const updMyProfile = async ({
	firstName,
	name,
	email,
	phone,
	iban,
	bic,
	language,
	socSecNumber,
	civilStatus,
	nationalRegistryNumber,
	nationality,
	birthDate,
	companyNumber,
	picture,
	placeOfBirth,
	placeOfBirthZip,
	gender,
	driverLicense,
	allergies,
	spouseName,
	spouseFirstName,
	spouseBirthDate,
	spouseProfession,
	spouseHasSalary,
	numberOfDependentChild,
	numberOfDisabledChild,
	numberOfDependentPerson,
	numberOfDisabledPerson,
	personToContact,
	personToContactPhone,
	disabled,
	color,
	idNumber,
	shoeSize,
	tShirtSize,
	showMyEmailInCrew,
	showMyPhoneInCrew,
	address,
	idName,
	taxesPercentage,
	otherStudyLevel,
	size,
	pantSize,
	jacketSize,
	vehicleName,
	vehicleBrand,
	vehicleModel,
	vehicleNumberPlate,
	vehicleType,
	vehicleEnergy,
	vehicleKgCoTwo,
	vehicleSubType,
	vehicleAdditionalSubType,
	pictureRib,
	maidenName,
	title,
	birthCountry,
	congesSpectacle,
	bankAccountOwnerName,
	bankDetailXid,
	taxCountry,
	socialSecurityCardXid,
	insuranceCertificateXid,
	socialSecurityCenter,
	socialSecurityCenterAddressId,
	lastMedicalVisit,
	medicalVisitValidity,
	medicalVisitDocumentXid,
	disabilityNotification,
	disabilityType,
	disabilityStatus,
	invalidity,
	rqth,
	invalidityPourcentage,
	retired,
	retirementDate,
	trainLoyaltyCard,
	trainDiscountCard,
	flyingLoyaltyCard,
	otherAirlineCard,
	residencePermitNumber,
	residencePermitEndValidity,
	residentPermitXid,
	workCardXid,
	pseudonym,
	professionalAssociation,
	postalAddressID,
	postalAddress,
	driverLicenseNumber,
	session1A,
	session1B,
	session2A,
	session2B,
	session3A,
	session3B,
	session4A,
	session4B,
	session5A,
	session5B,
	session6A,
	session6B,
	session7A,
	session7B,
	session8A,
	session8B
}) => {
	const mutation = gql`
		mutation UPD_MY_PROFILE(
			$firstName: String
			$name: String
			$email: String!
			$phone: String!
			$iban: String!
			$bic: String!
			$socSecNumber: String!
			$civilStatus: Int!
			$language: Int!
			$nationality: String!
			$birthDate: String!
			$companyNumber: String!
			$nationalRegistryNumber: String!
			$picture: String!
			$street: String
			$number: String
			$zip: String
			$city: String
			$box: String
			$state: String
			$country: String
			$placeOfBirth: String
			$placeOfBirthZip: String
			$gender: Int
			$driverLicense: String
			$allergies: String
			$spouseName: String
			$spouseFirstName: String
			$spouseBirthDate: String
			$spouseProfession: String
			$spouseHasSalary: Boolean
			$numberOfDependentChild: Int
			$numberOfDisabledChild: Int
			$numberOfDependentPerson: Int
			$numberOfDisabledPerson: Int
			$personToContact: String
			$personToContactPhone: String
			$disabled: Boolean
			$color: String
			$idNumber: String
			$shoeSize: String
			$tShirtSize: String
			$showMyEmailInCrew: Boolean
			$showMyPhoneInCrew: Boolean
			$countryCode: String
			$countryKey: Int
			$lat: Float
			$lng: Float
			$idName: String
			$taxesPercentage: Int
			$otherStudyLevel: String
			$size: String
			$pantSize: String
			$jacketSize: String
			$vehicleName: String
			$vehicleBrand: String
			$vehicleModel: String
			$vehicleNumberPlate: String
			$vehicleType: Int
			$vehicleSubType: Int
			$vehicleAdditionalSubType: Int
			$vehicleEnergy: Int
			$vehicleKgCoTwo: Float
			$pictureRib: String
			$maidenName: String
			$birthCountry: String
			$title: Int
			$congesSpectacle: String
			$bankAccountOwnerName: String
			$bankDetailXid: String
			$taxCountry: String
			$socialSecurityCardXid: String
			$insuranceCertificateXid: String
			$socialSecurityCenter: String
			$socialSecurityCenterAddressId: Int
			$lastMedicalVisit: String
			$medicalVisitValidity: String
			$medicalVisitDocumentXid: String
			$disabilityNotification: String
			$disabilityType: String
			$disabilityStatus: String
			$invalidity: Int
			$rqth: Boolean
			$invalidityPourcentage: Int
			$retired: Boolean
			$retirementDate: String
			$trainLoyaltyCard: String
			$trainDiscountCard: String
			$flyingLoyaltyCard: String
			$otherAirlineCard: String
			$residencePermitNumber: String
			$residencePermitEndValidity: String
			$residentPermitXid: String
			$workCardXid: String
			$pseudonym: String
			$professionalAssociation: String
			$postalAddressID: Int
			$postalAddress: AddressInput
			$driverLicenseNumber: String
			$session1A: Boolean
			$session1B: Boolean
			$session2A: Boolean
			$session2B: Boolean
			$session3A: Boolean
			$session3B: Boolean
			$session4A: Boolean
			$session4B: Boolean
			$session5A: Boolean
			$session5B: Boolean
			$session6A: Boolean
			$session6B: Boolean
			$session7A: Boolean
			$session7B: Boolean
			$session8A: Boolean
			$session8B: Boolean
		) {
			UpdMyProfile(
				newProfile: {
					firstName: $firstName
					name: $name
					email: $email
					phone: $phone
					iban: $iban
					bic: $bic
					language: $language
					socSecNumber: $socSecNumber
					civilStatus: $civilStatus
					nationalRegistryNumber: $nationalRegistryNumber
					nationality: $nationality
					birthDate: $birthDate
					companyNumber: $companyNumber
					picture: $picture
					placeOfBirth: $placeOfBirth
					placeOfBirthZip: $placeOfBirthZip
					gender: $gender
					driverLicense: $driverLicense
					allergies: $allergies
					spouseName: $spouseName
					spouseFirstName: $spouseFirstName
					spouseBirthDate: $spouseBirthDate
					spouseProfession: $spouseProfession
					spouseHasSalary: $spouseHasSalary
					numberOfDependentChild: $numberOfDependentChild
					numberOfDisabledChild: $numberOfDisabledChild
					numberOfDependentPerson: $numberOfDependentPerson
					numberOfDisabledPerson: $numberOfDisabledPerson
					personToContact: $personToContact
					personToContactPhone: $personToContactPhone
					disabled: $disabled
					color: $color
					address: {
						street: $street
						number: $number
						zip: $zip
						city: $city
						box: $box
						state: $state
						country: $country
						countryCode: $countryCode
						countryKey: $countryKey
						lat: $lat
						lng: $lng
					}
					postalAddressID: $postalAddressID
					postalAddress: $postalAddress
					idNumber: $idNumber
					shoeSize: $shoeSize
					tShirtSize: $tShirtSize
					showMyEmailInCrew: $showMyEmailInCrew
					showMyPhoneInCrew: $showMyPhoneInCrew
					idName: $idName
					taxesPercentage: $taxesPercentage
					otherStudyLevel: $otherStudyLevel
					size: $size
					pantSize: $pantSize
					jacketSize: $jacketSize
					vehicule: {
						name: $vehicleName
						model: $vehicleModel
						brand: $vehicleBrand
						numberPlate: $vehicleNumberPlate
						type: $vehicleType
						subType: $vehicleSubType
						additionalSubType: $vehicleAdditionalSubType
						energy: $vehicleEnergy
						kgCoTwoPerKm: $vehicleKgCoTwo
					}
					pictureRib: $pictureRib
					maidenName: $maidenName
					birthCountry: $birthCountry
					title: $title
					congesSpectacle: $congesSpectacle
					bankAccountOwnerName: $bankAccountOwnerName
					bankDetailXid: $bankDetailXid
					taxCountry: $taxCountry
					socialSecurityCardXid: $socialSecurityCardXid
					insuranceCertificateXid: $insuranceCertificateXid
					socialSecurityCenter: $socialSecurityCenter
					socialSecurityCenterAddressId: $socialSecurityCenterAddressId
					lastMedicalVisit: $lastMedicalVisit
					medicalVisitValidity: $medicalVisitValidity
					medicalVisitDocumentXid: $medicalVisitDocumentXid
					disabilityNotification: $disabilityNotification
					disabilityType: $disabilityType
					disabilityStatus: $disabilityStatus
					invalidity: $invalidity
					rqth: $rqth
					invalidityPourcentage: $invalidityPourcentage
					retired: $retired
					retirementDate: $retirementDate
					trainLoyaltyCard: $trainLoyaltyCard
					trainDiscountCard: $trainDiscountCard
					flyingLoyaltyCard: $flyingLoyaltyCard
					otherAirlineCard: $otherAirlineCard
					residencePermitNumber: $residencePermitNumber
					residencePermitEndValidity: $residencePermitEndValidity
					residentPermitXid: $residentPermitXid
					workCardXid: $workCardXid
					pseudonym: $pseudonym
					professionalAssociation: $professionalAssociation
					driverLicenseNumber: $driverLicenseNumber
					session1A: $session1A
					session1B: $session1B
					session2A: $session2A
					session2B: $session2B
					session3A: $session3A
					session3B: $session3B
					session4A: $session4A
					session4B: $session4B
					session5A: $session5A
					session5B: $session5B
					session6A: $session6A
					session6B: $session6B
					session7A: $session7A
					session7B: $session7B
					session8A: $session8A
					session8B: $session8B
				}
			)
		}
	`;
	const {
		data: { UpdMyProfile }
	} = await apollo.mutate({
		mutation,
		variables: {
			firstName,
			name,
			email,
			phone,
			iban,
			bic,
			language: parseInt(language, 10),
			socSecNumber,
			civilStatus: parseInt(civilStatus, 10),
			nationalRegistryNumber,
			nationality,
			birthDate,
			companyNumber,
			picture,
			placeOfBirth,
			placeOfBirthZip,
			gender: parseInt(gender, 10),
			driverLicense,
			allergies,
			spouseName,
			spouseFirstName,
			spouseBirthDate,
			spouseProfession,
			spouseHasSalary,
			numberOfDependentChild: parseInt(numberOfDependentChild, 10),
			numberOfDisabledChild: parseInt(numberOfDisabledChild, 10),
			numberOfDependentPerson: parseInt(numberOfDependentPerson, 10),
			numberOfDisabledPerson: parseInt(numberOfDisabledPerson, 10),
			personToContact,
			personToContactPhone,
			disabled,
			color,
			idNumber,
			shoeSize,
			tShirtSize,
			showMyEmailInCrew,
			showMyPhoneInCrew,
			street: address.street,
			number: address.number,
			zip: address.zip,
			city: address.city,
			box: address.box,
			state: address.state,
			country: address.country,
			countryCode: address.countryCode,
			countryKey: parseInt(address.countryKey, 10),
			lat: parseFloat(address.lat),
			lng: parseFloat(address.lng),
			idName,
			taxesPercentage: parseInt(taxesPercentage, 10),
			otherStudyLevel,
			size,
			pantSize,
			jacketSize,
			vehicleName,
			vehicleBrand,
			vehicleModel,
			vehicleNumberPlate,
			vehicleType,
			vehicleSubType,
			vehicleAdditionalSubType,
			vehicleEnergy,
			vehicleKgCoTwo,
			pictureRib,
			maidenName,
			birthCountry,
			title,
			congesSpectacle,
			bankAccountOwnerName,
			bankDetailXid,
			taxCountry,
			socialSecurityCardXid,
			insuranceCertificateXid,
			socialSecurityCenter,
			socialSecurityCenterAddressId,
			lastMedicalVisit,
			medicalVisitValidity,
			medicalVisitDocumentXid,
			disabilityNotification,
			disabilityType,
			disabilityStatus,
			invalidity,
			rqth,
			invalidityPourcentage: +invalidityPourcentage,
			retired,
			retirementDate,
			trainLoyaltyCard,
			trainDiscountCard,
			flyingLoyaltyCard,
			otherAirlineCard,
			residencePermitNumber,
			residencePermitEndValidity,
			residentPermitXid,
			workCardXid,
			pseudonym,
			professionalAssociation,
			postalAddressID,
			postalAddress,
			driverLicenseNumber,
			session1A,
			session1B,
			session2A,
			session2B,
			session3A,
			session3B,
			session4A,
			session4B,
			session5A,
			session5B,
			session6A,
			session6B,
			session7A,
			session7B,
			session8A,
			session8B
		},
		fetchPolicy: 'no-cache'
	});

	return UpdMyProfile;
};
